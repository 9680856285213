import React from 'react'
import LogoImg from './assets/img/logo-dark.png'
import LogoImgWhite from './assets/img/logo-vadefarma.png'
import PropTypes from 'prop-types'
/**
 * @author ehernandez
 * @returns {React.Component}
 */
const Logo = ({ mode, width, onClick }) => {
  return (
    <div onClick={onClick} className="logo" style={{ maxWidth: width }}>
      {mode !== 'white'
        ? (
        <img src={LogoImg} alt="logo" />
          )
        : (
        <img src={LogoImgWhite} alt="logo" />
          )}
    </div>
  )
}

Logo.propTypes = {
  mode: PropTypes.string,
  width: PropTypes.number,
  onClick: PropTypes.func
}

Logo.defaultProps = {
  onClick: () => null
}

export default Logo
