import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import Modal from '../../../../components/Modal'
import Page from '../../../../components/Page'
import Paragraphs from '../../../../components/Paragraphs'
import { useLanguage } from '../../../../infrastructure/context/LangContext/LangContext'
import useFlashMessage from '../../../../infrastructure/hooks/useFlashMessage'
import { usePharmacy } from '../../../../infrastructure/hooks/usePharmacy/usePharmacy'
import useGetUsersInfo from '../../../../infrastructure/hooks/useUsersManagement'
import { actionSetUserSuccess } from '../../../../infrastructure/redux/actions/user'
import {
  actionCloseTab,
  actionSetEditUserField,
  actionSetEdititingUserField,
  actionSetName,
  actionSetTabIndex,
  actionSetUserData
} from '../../../../infrastructure/redux/actions/userTab'
import UserDetail from '../../components/UserDetail'
import UsersAggridList from '../../components/UsersAggridList'

/**
 *
 * @param {*} props
 * @returns
 */
const UserScreen = () => {
  const { t } = useTranslation()
  const { user: currentUser } = useSelector((state) => state.userReducer)
  const { usersTabs, tabIndex } = useSelector((state) => state.userTabReducer)
  const [userData, setUserData] = useState({})
  const [currentTab, setCurrentTab] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [isClickClose, setIsClickClose] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [changesUserField, setChangesUserField] = useState([])
  const [isSuperAdmin, setIsSuperAdmin] = useState(
    currentUser?.roles?.findIndex((role) => role === 'SuperAdmin') > -1
  )
  const { onChangeLanguage } = useLanguage()
  const { showMessage } = useFlashMessage()
  const { updatePharmacy } = usePharmacy()
  const { updateUser } = useGetUsersInfo()
  const dispatch = useDispatch()

  /**
   * Handles the save operation for a specific user.
   * @async
   * @function handleSave
   * @param {number} id - The ID of the user.
   * @returns {Promise<void>}
   */
  const handleSave = async (id) => {
    // Check if the currentTab's userId matches the given id
    if (currentTab?.userId !== id) {
      return // Exit the function if not a match
    }

    // Set the editing state for the user to false
    handleIsEditing(id, false)

    // Prepare the newData object to store the updated user data
    const newData = {
      user: {
        id
      },
      pharmacy: {},
      admin: isSuperAdmin
    }

    // Check if there are any changes to user fields
    if (!!changesUserField && changesUserField?.length === 0) {
      setIsSaving(false)
      return // Exit the function if no changes to save
    }

    const nifRegex = '^([A-Z,a-z]([0-9]{7})([0-9A-Ja]$))|(^[0-9]{8}[A-Z,a-z]$)'
    const nif = changesUserField.find((change) => change.field === 'nif')?.value
    if (nif && !nif.match(nifRegex)) {
      setChangesUserField([])
      dispatch(actionSetEditUserField([]))
      setIsSaving(false)
      showMessage({
        type: 'alert',
        message: t('Documento de identidad no válido')
      })
      return
    }

    // Iterate through each change in changesUserField array
    changesUserField?.forEach((change) => {
      const { item, field, value, originalValue, isPharmacy } = change
      const data = isPharmacy ? 'pharmacy' : 'user'

      // If the value is different from the original value, update the newData object
      if (value !== originalValue) {
        newData[data][field] = item?.id || value
        // If it's a pharmacy field and newData.pharmacy.id is not set, assign userData.pharmacyId
        if (isPharmacy && !newData.pharmacy.id) {
          newData.pharmacyId = userData.pharmacyId
        }
      }
    })

    // Update user fields on the server and retrieve the updated personal and contact data
    const { userPersonalData, userContact } = await updateUserFields(newData)

    // Update pharmacy fields on the server and retrieve the updated pharmacy data
    const { userPharmacyData } = await updatePharmacyFields(newData)

    // Update the user data locally with the retrieved data
    handleSetUserData(id, {
      ...userData,
      userPersonalData,
      userContact,
      userPharmacyData
    })

    // Check if there is an existing change for the language field and the current user is the same as the updated user

    if (currentUser.id === id) {
      const existingFieldLang = changesUserField?.find(
        (change) => change.field === 'language_id'
      )

      if (existingFieldLang?.item) {
        // Call the onChangeLanguage function with the updated language code
        onChangeLanguage(existingFieldLang.item.code)
      }

      const filterNameSurnames = changesUserField?.filter(
        (changes) => changes.field === 'name' || changes.field === 'surnames'
      )

      let updatedUser = currentUser
      filterNameSurnames.forEach((changes) => {
        updatedUser = {
          ...updatedUser,
          [changes.field]: changes.value
        }
      })
      dispatch(actionSetUserSuccess(updatedUser))
    }

    // Show a success message to indicate the successful update
    showMessage({
      type: 'success',
      message: t('Cliente actualizado correctamente')
    })

    // Clear the changesUserField array
    setChangesUserField([])

    // Dispatch an action to update the edit user field state
    dispatch(actionSetEditUserField([]))

    // Set the isSaving state to false
    setIsSaving(false)
  }

  /**
   * Updates the user fields with the provided data.
   * @async
   * @function updateUserFields
   * @param {Object} newData - The new data to update user fields.
   * @returns {Promise<Object>} - Updated user personal data and user contact.
   */
  const updateUserFields = async (newData) => {
    let userPersonalData = userData.userPersonalData
    let userContact = userData.userContact
    const newDataUser = {
      ...newData.user,
      admin: newData.admin
    }

    if (Object.keys(newData.user).length > 1) {
      const { success, data, error } = await updateUser(newDataUser)
      if (!success) {
        showMessage({
          type: 'alert',
          message: t('El cliente no se ha podido actualizar')
        })
        setIsSaving(false)
        return
      }

      userPersonalData = userPersonalData?.map((obj) => {
        const existingField = changesUserField?.find(
          ({ label }) => label === obj.label
        )

        if (existingField) {
          return {
            ...obj,
            value: existingField.value
          }
        }
        return obj
      })

      const changeName = changesUserField?.find(
        ({ label }) => label === 'Nombre' || label === 'Apellidos'
      )
      if (changeName) {
        const newName = userPersonalData
          .filter((obj) => obj.label === 'Nombre' || obj.label === 'Apellidos')
          .map((obj) => obj.value)
          .join(' ')

        dispatch(actionSetName(newData.user.id, newName))
      }
    }

    userContact = userContact?.map((obj) => {
      const existingField = changesUserField?.find(
        ({ label }) => label === obj.label
      )

      if (existingField) {
        return {
          ...obj,
          value: existingField.value
        }
      }

      return obj
    })

    return { userPersonalData, userContact }
  }

  /**
   * Updates the pharmacy fields with the provided data.
   * @async
   * @function updatePharmacyFields
   * @param {Object} newData - The new data to update pharmacy fields.
   * @returns {Promise<Object>} - Updated user pharmacy data.
   */
  const updatePharmacyFields = async (newData) => {
    let userPharmacyData = userData.userPharmacyData

    if (Object.keys(newData.pharmacy).length > 0) {
      // Delete any null value
      Object.keys(newData.pharmacy).forEach((key) => {
        if (newData.pharmacy[key] === null) {
          delete newData.pharmacy[key]
        }
      })

      // If no keys, return
      if (Object.keys(newData.pharmacy).length > 0) {
        let newDataPharmacy = {
          ...newData.pharmacy,
          id: newData.pharmacyId,
          admin: newData.admin
        }

        if (!newData.pharmacy.province_id) {
          newDataPharmacy = {
            ...newDataPharmacy,
            province_id: userData.pharmacyProvinceId
          }
        }

        const { success, data, error } = await updatePharmacy(newDataPharmacy)
        if (!success) {
          showMessage({
            type: 'alert',
            message: t('El cliente no se ha podido actualizar')
          })
          setIsSaving(false)
          return { userPharmacyData }
        }
      }
    }

    userPharmacyData = userPharmacyData?.map((obj) => {
      const existingField = changesUserField?.find(
        ({ databaseField }) => databaseField === obj.databaseField
      )

      if (existingField) {
        return {
          ...obj,
          value: existingField.value ?? existingField.antValue
        }
      }

      return obj
    })

    return { userPharmacyData }
  }

  const handleIsEditing = (id, edit) => {
    dispatch(actionSetEdititingUserField(id, edit))
  }

  const handleSetUserData = (id, data) => {
    dispatch(actionSetUserData(id, data))
  }

  const onAccept = async () => {
    await handleSave(currentTab?.userId)
    setIsClickClose(false)
    dispatch(actionCloseTab(currentTab?.userId))
  }

  const onCancel = () => {
    setIsSaving(false)
    setIsClickClose(false)

    handleIsEditing(currentTab?.userId, false)

    dispatch(actionSetEditUserField([]))
    dispatch(actionCloseTab(currentTab?.userId))
  }

  const onRequestClose = () => {
    setIsSaving(false)
    setIsClickClose(false)
  }

  /**
   * React Hook that handles the effect when the `tabIndex` or `usersTabs` change.
   * It updates the current tab, user data, editing state, and changes user fields.
   * @function useEffect
   * @param {number} tabIndex - The index of the active tab.
   * @param {Array} usersTabs - The array of user tabs.
   */
  useEffect(() => {
    if (tabIndex === 0) {
      return
    }

    const activeTab = usersTabs[tabIndex]
    setCurrentTab(tabIndex > 0 ? activeTab : {})
    setUserData(activeTab?.userData ?? {})
    setIsEditing(activeTab?.isEditing ?? false)
    setChangesUserField(activeTab?.changesUserField ?? [])
  }, [tabIndex, usersTabs])

  useEffect(() => {
    dispatch(actionSetTabIndex(usersTabs.length - 1))
  }, [usersTabs.length])

  return (
    <Page.Container>
      <Page.Header title={t('Usuarios')}></Page.Header>
      <Page.Body>
        <Tabs
          selectedIndex={tabIndex || 0}
          onSelect={(index) => dispatch(actionSetTabIndex(index))}
          defaultFocus
          // forceRenderTabPanel
        >
          <TabList>
            {usersTabs?.length > 0 &&
              usersTabs?.map((tab, index) => (
                <Tab key={index}>
                  <div
                    className={`tw-flex tw-gap-6 tw-justify-between ${
                      index !== tabIndex && 'tw-opacity-30'
                    }`}
                  >
                    {t(tab.name)}
                    {index > 0 && (
                      <div
                        className={
                          'tw-rounded-full tw-px-1 tw-text-center tw-bg-black tw-text-white tw-text-xs tw-self-center tw-cursor-pointer tw-font-bold'
                        }
                        onClick={() => {
                          isEditing && changesUserField?.length > 0
                            ? setIsClickClose(true)
                            : dispatch(actionCloseTab(tab?.userId)) &&
                              handleIsEditing(tab?.userId, false)
                        }}
                      >
                        X
                      </div>
                    )}
                  </div>
                </Tab>
              ))}
          </TabList>
          {usersTabs?.length > 0 &&
            usersTabs?.map((tab, index) =>
              index === 0 ? (
                <TabPanel forceRender key={index}>
                  <div className={'tw-h-full'}>
                    <UsersAggridList />
                  </div>
                </TabPanel>
              ) : (
                <TabPanel forceRender key={index}>
                  <div className={'tw-h-full'}>
                    <UserDetail
                      id={tab?.userId}
                      isAdmin={
                        currentUser?.role?.name === 'Admin' ||
                        currentUser?.role.name === 'SuperAdmin'
                      }
                      isSuperAdmin={isSuperAdmin}
                      isClickClose={isClickClose}
                      isSaving={isSaving}
                      setIsSaving={setIsSaving}
                      isEditing={isEditing}
                      setIsEditing={(edit) =>
                        handleIsEditing(tab?.userId, edit)
                      }
                      changesUserField={currentTab?.changesUserField}
                      handleSave={handleSave}
                      userData={userData}
                      setUserData={(data) =>
                        handleSetUserData(tab?.userId, data)
                      }
                    />
                  </div>
                </TabPanel>
              )
            )}
        </Tabs>
        {isClickClose && isEditing && (
          <Modal
            title={t('Aviso')}
            onRequestClose={onRequestClose}
            onAccept={onAccept}
            onCancel={onCancel}
            textAccept={t('Guardar')}
            cancelText={t('Cancelar')}
            size='md'
            loading={isSaving}
          >
            <Paragraphs size={'sm'} weight={'bold'} className='tw-p-4'>
              {t('Hay cambios sin guardar, ¿Desea guardar los cambios?')}
            </Paragraphs>
          </Modal>
        )}
      </Page.Body>
    </Page.Container>
  )
}

UserScreen.propTypes = {}

export default UserScreen
