export const useFlashMessage = () => {
  const showMessage = ({ message, type }) => {
    const event = new CustomEvent('flashmessage', {
      detail: {
        message,
        type
      }
    })
    document.getElementById('flash-message')?.dispatchEvent(event)
  }

  return { showMessage }
}
