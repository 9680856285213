const downloadXLSX = async ({
  setDownloading,
  mutationFunction,
  addToURL,
  filename
}) => {
  setDownloading(true)
  const { success, error, data } = await mutationFunction({
    method: 'GET',
    responseType: 'blob',
    addToURL: addToURL ?? ''
  })
  setTimeout(() => {
    setDownloading(false)
  }, 500)
  let url = ''
  if (success) {
    url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${filename}.xlsx`)
    document.body.appendChild(link)
    link.click()
    return { success }
  }
  return { error }
}
export default downloadXLSX
