export const dbTables = {
  users: 'users',
  pharmacy: 'pharmacy',
  unnefar: 'unnefars',
  metadata: 'metadata',
  cooperative: 'cooperative',
  client_type: 'client_type'
}

export const btnStatus = {
  generando: 'Generando...',
  descargando: 'Descargando...',
  exportar: 'Exportar a Excel'
}
