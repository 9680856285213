export const emailInputs = [
  {
    type: 'email',
    width: '64',
    id: 'email_contact',
    required: true,
    label: 'Email contacto'
  },
  {
    type: 'email',
    width: '64',
    id: 'email_order',
    required: true,
    label: 'Email pedidos'
  },
  {
    type: 'email',
    width: '64',
    id: 'email_billing',
    required: true,
    label: 'Email facturación'
  }
]
