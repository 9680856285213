import React, { useLayoutEffect, useRef, useState, useEffect } from 'react'

import useUser from '../../hooks/useUser'
import { Outlet } from 'react-router-dom'
import Layout from '../../components/Layout'
import {
  actionSetUserSessionExpired,
  actionUserLogout
} from '../../redux/actions/user'
import { useDispatch, useSelector } from 'react-redux'
import useMutation from '../../hooks/useMutation'
import usePagesRoles from '../../../modules/Auth/hooks/usePagesRoles'

/**
 * This component is the main context of the application, it checks if the token is still valid,
 * if the user has the required roles to access the page and if the user has no roles, we log out
 * @returns {React.Component}
 */
const AuthContext = () => {
  const { token, getInfoUser } = useUser()
  const { hasPermissionsRoute } = usePagesRoles()
  const { user, userSessionExpired } = useSelector((state) => state.userReducer)
  const [tokenIsValid] = useMutation('user/token-info')
  const [checkTokenInterval, setCheckTokenInterval] = useState(null)
  const [stop, setStop] = useState(false)
  const dispatch = useDispatch()
  const byPassMessage = useRef(false)

  /**
   * This function check if the token is still valid
   * @returns {void}
   * @async
   */
  const checkIfTokenIsStillValid = async () => {
    if (
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/'
    ) {
      const { success } = await tokenIsValid({ method: 'get' })
      if (!success) {
        setStop(true)
        dispatch(actionSetUserSessionExpired(byPassMessage.current))
      }
      byPassMessage.current = true
    }
  }

  /**
   * This function check if the token is still valid when the tab is focused
   * in a interval of 300 seconds
   * @returns {void}
   *
   */
  const checkTokenWhenTabIsFocused = () => {
    if (stop) return
    clearTimeout(checkTokenInterval)
    if (!window.document.hidden && Boolean(token)) {
      checkIfTokenIsStillValid()
      setCheckTokenInterval(
        setTimeout(() => {
          checkTokenWhenTabIsFocused(true)
        }, 30000)
      )
    }
  }

  /**
   * Add the event listener to run the function checkTokenWhenTabIsFocused or remove it
   * @returns {void}
   */
  useEffect(() => {
    if (token && !stop) {
      window.document.addEventListener(
        'visibilitychange',
        checkTokenWhenTabIsFocused
      )
    }
    checkTokenWhenTabIsFocused()
    return () => {
      window.document.removeEventListener(
        'visibilitychange',
        checkTokenWhenTabIsFocused
      )
    }
  }, [token])

  /**
   * Checks if the token is valid when the component is mounted
   */
  useLayoutEffect(() => {
    if (token && !userSessionExpired) {
      getInfoUser().then((response) => {
        if (!response.success) {
          dispatch(actionSetUserSessionExpired(false))
          dispatch(actionUserLogout())

          window.location.href = '/login'
        }
      })
    }
    // if the token is not valid, we log out, avoid modal message
    if (token && userSessionExpired) {
      dispatch(actionSetUserSessionExpired(false))
      dispatch(actionUserLogout())
      window.location.href = '/login'
    }
  }, [])
  useEffect(() => {
    const route = hasPermissionsRoute(user.roles)

    if (route !== '' && route !== window.location.pathname) {
      window.location.href = route
    }
  }, [user])

  if (!token) {
    dispatch(actionSetUserSessionExpired(false))
    dispatch(actionUserLogout())
    window.location.href = '/login'
  }

  /**
   * If the user is not an admin or has no roles, we log out
   */
  if (user && user.role === null) {
    dispatch(actionUserLogout())
    return null
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

AuthContext.propTypes = {}

export default AuthContext
