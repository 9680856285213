import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import Button from '../Button'
import ReactDOM from 'react-dom'
import { ModalContainer } from './styles'
import useListenerClose from '../../infrastructure/hooks/useListenerClose'
import useWindowSize from '../../infrastructure/hooks/useWindowSize'
import { useTranslation } from 'react-i18next'

const HEADER_HEIGHT = 40
const FOOTER_HEIGHT = 72
const MARGIN = 30
const Modal = ({
  title,
  children,
  size,
  onRequestClose,
  customFooter,
  id,
  onAccept,
  onCancel,
  loading,
  textAccept,
  hideCancelButton,
  hideAcceptButton
}) => {
  const refBody = useRef(null)
  const [ref] = useListenerClose(onRequestClose)
  const { t } = useTranslation()
  const { height } = useWindowSize()

  const [hBody, setBody] = useState(0)

  useEffect(() => {
    const { height: h } = refBody.current.getBoundingClientRect()
    if (h > height - HEADER_HEIGHT - FOOTER_HEIGHT - MARGIN) {
      setBody(height - HEADER_HEIGHT - FOOTER_HEIGHT - MARGIN)
    }
  }, [height])

  const content = (
    <div
      className='modal tw-justify-center tw-flex tw-fixed tw-top-0 tw-left-0 tw-w-screen tw-items-start'
      id='modal'
    >
      <div className='tw-fixed tw-bg-black tw-bg-opacity-10 tw-h-screen tw-w-screen tw-cursor-pointer' />
      <ModalContainer
        id={id}
        size={size}
        ref={ref}
        className='modal-content tw-mt-10 tw-bg-white tw-relative tw-z-50 tw-w-full tw-m-auto tw-max-h-[90%] tw-shadow-lg'
      >
        <div
          style={{ height: HEADER_HEIGHT }}
          className='modal-header tw-flex tw-bg-gray-100 tw-items-center tw-justify-between'
        >
          <Paragraphs className='tw-text-black tw-pl-3'>{title}</Paragraphs>
          <div
            data-cy='close-modal'
            className='tw-pointer tw-px-2 tw-h-full tw-flex tw-items-center tw-justify-center'
            onClick={onRequestClose}
          >
            <span
              className='icon-ic_common_close tw-text-black tw-cursor-pointer'
              style={{ fontSize: 30 }}
            />
          </div>
        </div>
        <div
          ref={refBody}
          className='modal-body tw-bg-white'
          style={{ maxHeight: hBody || 'auto' }}
        >
          {children}
        </div>
        <div className='modal-footer tw-flex tw-justify-end tw-gap-4 tw-bg-white'>
          {customFooter ? (
            customFooter()
          ) : (
            <div
              style={{ height: FOOTER_HEIGHT }}
              className='tw-flex tw-items-center tw-justify-end tw-p-4 tw-gap-x-2 tw-w-full'
            >
              {!hideCancelButton && (
                <Button
                  disabled={loading}
                  label={t('Cancelar')}
                  uppercase
                  onClick={onCancel ?? onRequestClose}
                />
              )}
              {!hideAcceptButton && (
                <Button
                  disabled={loading}
                  label={t(textAccept || 'Aceptar')}
                  uppercase
                  onClick={onAccept}
                />
              )}
            </div>
          )}
        </div>
      </ModalContainer>
    </div>
  )
  const modalWrapperId = `modal-wrapper-${id}`

  return ReactDOM.createPortal(
    content,
    document.getElementById('modal-wrapper')
  )
}

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  onRequestClose: PropTypes.func,
  customFooter: PropTypes.func,
  id: PropTypes.string,
  textAccept: PropTypes.string,
  hideCancelButton: PropTypes.bool,
  hideAcceptButton: PropTypes.bool
}

export default Modal
