import React from 'react'
import PropTypes from 'prop-types'
import useParagraphs from './hooks/useParagraphs'

/**
 * Paragraphs component.
 * @author @jstarsky
 * @param {*} props
 * @return {JSX.Element}
 */
const Paragraphs = ({
  id,
  children,
  className,
  fontFamily,
  size,
  transform,
  weight,
  italic
}) => {
  const { internalClassName } = useParagraphs({
    id,
    fontFamily,
    size,
    transform,
    weight,
    italic,
    extendClassname: className
  })
  return <div className={`${internalClassName} paragraphs`}>{children}</div>
}

Paragraphs.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  fontFamily: PropTypes.oneOf(['atkinson', 'gotham']),
  size: PropTypes.oneOf(['xxxs', 'xxs', 'xs', 'sm', 'base', 'lg', 'xl', '2xl']),
  transform: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize']),
  weight: PropTypes.oneOf(['light', 'medium', 'bold', 'book', 'normal']),
  italic: PropTypes.bool
}

Paragraphs.defaultProps = {
  id: 'paragraphs',
  fontFamily: 'atkinson',
  weight: 'normal',
  size: 'sm'
}

export default Paragraphs
