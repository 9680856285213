import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import PropTypes from 'prop-types'
import { useTranslationsCRUD } from '../../../../infrastructure/hooks/useTranslationsCRUD/useTranslationsCRUD'
import { formatFilters } from '../../../../infrastructure/utils/helperFunctions'
import useReports from '../../../../infrastructure/hooks/useReports'
import {
  defaultColDef,
  columnDefs
} from '../../../../infrastructure/hooks/useReports/constants/constants'
import Button from '../../../../components/Button'
import useUsersManagement from '../../../../infrastructure/hooks/useUsersManagement'

const UnnefarListAggrid = ({ t }) => {
  const { getUnnefar, getUnnefarExcel, loading } = useReports()
  const { translate } = useTranslationsCRUD()
  const { getUnnefarCooperatives } = useUsersManagement()

  /**
   * This function is used to download the excel file
   * @returns {void}
   */
  const handleDownload = async () => await getUnnefarExcel()

  /**
   * Grid options for ag-grid
   * @type {object}
   * @property {string} overlayNoRowsTemplate - Template to show when there is no data
   * @property {object} columnDefs - Columns to show in the grid
   * @property {object} defaultColDef - Default column definition
   * @property {number} rowHeight - Height of the rows
   */
  const gridOptions = {
    overlayNoRowsTemplate: t('Sin datos'),
    columnDefs: translate(columnDefs),
    defaultColDef: defaultColDef,
    rowHeight: 40,
    localeText: {
      groupContracted: t('Click para expandir'),
      rowGroupColumnsEmptyMessage: t('Arrastre columnas para agrupar'),
      loadingOoo: t('Cargando...'),
      copy: t('Copiar'),
      copyWithHeaders: t('Copiar con encabezados')
    },
    onFirstDataRendered: function (params) {
      const cooperativaColumn = params.columnApi.getColumn('nombreCooperativa')
      const colId = cooperativaColumn.getColDef().field
      const filterPopup = params.api.getFilterInstance(colId).getGui()
      filterPopup.classList.add(`column-filter-${colId}`)

      getUnnefarCooperatives().then(({ data }) => {
        const newFilterParams = {
          ...cooperativaColumn.getColDef().filterParams,
          values: data.map(({ nombreCooperativa }) => nombreCooperativa),
          defaultToNothingSelected: true,
          buttons: ['reset']
        }
        cooperativaColumn.getColDef().filterParams = newFilterParams

        // Utiliza setFilterValues para actualizar los valores del filtro
        params.api
          .getFilterInstance(colId)
          .setFilterValues(newFilterParams.values)
      })
    }
  }

  const onGridReady = (params) => {
    const updateData = (data) => {
      let idSequence = 1
      data?.forEach(function (item) {
        item.id = idSequence++
      })
      const datasource = createDataSource(data)
      params.api.setServerSideDatasource(datasource)
    }

    updateData()
  }

  /**
   * This function is used to create the datasource for the ag-grid in order to use server side pagination and sorting
   * @param {any} server
   * @returns {object} datasource
   */
  const createDataSource = (server) => {
    return {
      getRows: (params) => {
        let filters = null
        let order = null
        if (params.request.filterModel) {
          filters = formatFilters(params.request.filterModel)
        }

        if (params.request.sortModel.length > 0) {
          order = {
            order_field: params.request.sortModel[0].colId,
            order_direction: params.request.sortModel[0].sort
          }
        }

        let page = 1
        if (params.request.startRow > 0) {
          page = params.request.startRow / 25 + 1
        }

        getUnnefar(
          {
            per_page: params.request.endRow - params.request.startRow,
            page: page
          },
          Object.keys(filters).length > 0
            ? {
                filter: { ...filters }
              }
            : null,
          order
        ).then(({ data, pagination }) => {
          if (data.length === 0) {
            params.failCallback()
            params.api.showNoRowsOverlay()
          }

          params.success({
            rowData: data,
            rowCount: pagination.total
          })
        })
      }
    }
  }

  const getContextMenuItems = () => {
    return [
      'copy',
      'copyWithHeaders',
      'separator',
      {
        name: t('Exportar a Excel'),
        action: () => {
          handleDownload()
        },
        icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>'
      }
    ]
  }

  return (
    <div className='ag-theme-alpine' style={{ height: 'calc(100vh - 250px)' }}>
      <div className='tw-mb-2'>
        <Button
          label={t('descargar excel')}
          uppercase
          mode='secondary'
          onClick={handleDownload}
          disabled={loading}
        />
      </div>
      <AgGridReact
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowModelType='serverSide'
        paginationPageSize={25}
        cacheBlockSize={25}
        pagination={true}
        serverSideSortingAlwaysResets={true}
        blockLoadDebounceMillis={500}
        maxConcurrentDatasourceRequests={2}
        serverSideStoreType='partial'
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

UnnefarListAggrid.propTypes = {
  t: PropTypes.func.isRequired
}

export default UnnefarListAggrid
