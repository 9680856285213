import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import { useTranslation } from 'react-i18next'
import ProductImage from '../ProductImage'
import { useProducts } from '../../infrastructure/hooks/useProducts/useProducts'

const ProductDetails = ({ data }) => {
  const { t } = useTranslation()
  const { getProductsInfo } = useProducts()
  const [products, setProducts] = useState([])

  useEffect(() => {
    if (data) {
      const lineas = data.lineas
      if (!lineas) return
      const ids = lineas.map((product) => product.codigoArticulo).join(',')
      fetchProducts(ids)
    }
  }, [data])

  const fetchProducts = async (ids) => {
    try {
      const response = await getProductsInfo(ids)
      const { data: productsInfo } = response
      if (productsInfo) {
        setProducts(productsInfo)
      }
    } catch (error) {
      console.error(error)
    }
  }

  if (!products.length) {
    return null
  }

  return (
    <div className='order-details tw-flex tw-w-full tw-flex-col tw-bg-white tw-p-6 tw-overflow-auto'>
      <Paragraphs
        weight='bold'
        size='lg'
        className='tw-flex tw-h-[50px] tw-flex-row tw-items-center'
      >
        {t('Detalles de pedido')}
      </Paragraphs>
      <div className=' tw-flex tw-h-[30px] tw-flex-row tw-pt-2'>
        <div className='tw-w-[120px]'></div>
        <div className='tw-w-[80%]'>
          <Paragraphs size='xs' weight='bold'>
            Producto
          </Paragraphs>
        </div>
        <div className='tw-w-[10%] tw-text-right'>
          <Paragraphs size='xs' weight='bold'>
            Cantidades pedidas
          </Paragraphs>
        </div>
      </div>
      <div className='tw-flex tw-flex-col tw-pt-2'>
        {products.map((item, index) => {
          return (
            <div
              key={item.cn}
              className={`tw-flex tw-h-[100px] tw-flex-row tw-pt-3 ${
                index < products.length - 1 &&
                ' tw-border-b tw-border-slate-200'
              }`}
            >
              <div className=' tw-flex tw-w-[120px] tw-flex-row tw-self-center tw-pr-4 tw-align-middle'>
                <ProductImage src={item.images[0]} />
              </div>
              <div className=' tw-w-[80%] tw-self-center'>
                <div className=' tw-flex tw-flex-col'>
                  <Paragraphs size='xs' uppercase className=' tw-truncate'>
                    {item.name}
                  </Paragraphs>
                  <Paragraphs
                    weight='bold'
                    size='xxs'
                    className='tw-flex tw-flex-row'
                  >
                    CN:&nbsp;
                    <Paragraphs size='xxs'>{item.cn}</Paragraphs>
                  </Paragraphs>
                </div>
              </div>
              <div className=' tw-w-[10%] tw-self-center tw-text-right'>
                <Paragraphs size='xs'>
                  {data?.lineas[index].cantidad}
                </Paragraphs>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

ProductDetails.propTypes = {
  data: PropTypes.object.isRequired
}

export { ProductDetails }
