export const INPUTS = [
  {
    id: 'password',
    type: 'password',
    label: 'Nueva contraseña',
    name: 'password',
    required: true,

    minLength: {
      value: 10,
      message: 'La contraseña debe tener al menos 10 caracteres'
    },
    description:
      'La contraseña debe tener al menos 10 caracteres, una mayúscula, una minúscula, un número y un caracter especial'
  },
  {
    id: 'password_confirmation',
    type: 'password',
    label: 'Repite la contraseña',
    name: 'password_confirmation',
    minLength: {
      value: 10,
      message: 'La contraseña debe tener al menos 10 caracteres'
    },
    validate: 'password_confirmation',
    required: true
  }
]
