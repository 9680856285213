import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../../../components/Paragraphs'
import moment from 'moment'
import 'moment/locale/es'
import InformationTooltip from '../InformationTooltip'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'

const UserField = (props) => {
  const {
    label,
    value,
    index,
    isPharmacyInfo,
    tooltip,
    verify,
    hide,
    isSuperAdmin,
    setOpenModalVerifyEmail
  } = props
  const { t } = useTranslation()

  const checkStatus = () => {
    const baseClass = ' tw-text-white tw-px-3 tw-rounded-full tw-text-center'
    if (value === 'pending' || value === 'ready') {
      return 'tw-bg-orange-300' + baseClass
    }
    if (value === 'approved' || value === 'completed') {
      return 'tw-bg-green' + baseClass
    }
    if (value === 'not_approved') {
      return 'tw-bg-red-500' + baseClass
    }
    if (value === null) return 'tw-text-gray-300'
  }
  moment.locale('es')

  if (hide) return null

  if (label === 'Email de acceso') {
    return (
      <div className='tw-flex-1 tw-flex tw-gap-2 tw-border-b tw-border-b-1 tw-border-gray-100'>
        <Paragraphs size='sm'>{t(label)}:</Paragraphs>
        <div
          className='tw-flex tw-justify-between tw-w-fit tw-gap-1 tw-items-center'
          onClick={() => {
            if (verify) return
            if (isSuperAdmin) {
              setOpenModalVerifyEmail(true)
            }
          }}
        >
          <Paragraphs
            size='sm'
            className={`tw-w-fit ${
              !verify && isSuperAdmin ? 'tw-text-red-500 tw-cursor-pointer' : ''
            }`}
            weight='bold'
          >
            {tooltip ? (
              <button
                className={`tw-w-fit tw-font-bold ${
                  !verify ? 'tw-text-red-500' : ''
                }`}
              >
                {value}
              </button>
            ) : (
              value || t('Sin datos')
            )}
          </Paragraphs>
          {!verify && (
            <>
              <a data-tip data-for='email_tooltip'>
                <Paragraphs
                  className='tw-border-1 tw-mb-0.5 tw-shadow-black tw-shadow-sm tw-border-black tw-border tw-rounded-full tw-px-1.5 tw-cursor-pointer tw-self-center'
                  weight='bold'
                  size='xxs'
                >
                  i
                </Paragraphs>
              </a>
              <ReactTooltip
                id='email_tooltip'
                className='tw-w-64'
                type='error'
                effect='solid'
              >
                <span>
                  {t(
                    'El email de acceso no ha sido verificado. El usuario no podrá acceder a la plataforma hasta que no verifique su email.'
                  )}
                </span>
              </ReactTooltip>
            </>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className='tw-flex-1 tw-flex tw-gap-2 tw-border-b tw-border-b-1 tw-border-gray-100'>
      <Paragraphs size='sm'>{t(label)}:</Paragraphs>
      <div className='tw-flex tw-justify-between tw-w-fit tw-gap-1 tw-items-center'>
        <Paragraphs
          size='sm'
          className={`tw-w-fit ${checkStatus()}`}
          weight='bold'
        >
          {typeof value === 'string' &&
          !value.match(/^\d+$/) &&
          label !== 'Email de acceso'
            ? moment(value, moment.ISO_8601, true).isValid()
              ? moment(value).format('MMM DD, YYYY')
              : value
            : typeof value === 'boolean'
            ? value
              ? 'Si'
              : 'No'
            : value || t('Sin datos')}
        </Paragraphs>
        {label === 'Estado' && value && (
          <InformationTooltip
            value={value}
            isPharmacyInfo={isPharmacyInfo}
            index={index}
          />
        )}
      </div>
    </div>
  )
}

UserField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isPharmacyInfo: PropTypes.bool.isRequired,
  tooltip: PropTypes.string,
  verify: PropTypes.bool,
  hide: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  setOpenModalVerifyEmail: PropTypes.func
}

export default UserField
