import React from 'react'
import PropTypes from 'prop-types'

/**
 * Component to show the active slide in ag-grid
 * renders a checkbox
 * @param {object} props - props ag-grid
 * @returns
 */
export const ActiveSlideAggrid = (props) => {
  return (
    <label
      htmlFor='toogleA'
      className='tw-relative tw-cursor-pointer tw-flex tw-h-full tw-items-center'
    >
      <div className='tw-relative'>
        <input
          id='toogleA'
          type='checkbox'
          className='sr-only'
          readOnly
          defaultChecked={props.data.active}
          disabled
        />
        <div className='tw-w-8 tw-h-2 tw-bg-gray-400 tw-rounded-full tw-shadow-inner'></div>
        <div className='dot tw-absolute tw-w-4 tw-h-4 tw-bg-white tw-rounded-full tw-shadow tw--top-1 tw-transition'></div>
      </div>
    </label>
  )
}

ActiveSlideAggrid.propTypes = {
  data: PropTypes.object
}

ActiveSlideAggrid.displayName = 'ActiveSlideAggrid'
