import moment from 'moment'
/**
 * This function is used to format the filter objects in a way that the backend can understand
 * @param {Array} filters Aggrid filters object array
 * @returns  {Object} Object with the filters formatted
 */
export const formatFilters = (filters) => {
  return Object.keys(filters).reduce((acc, key) => {
    let filter = null
    filter = filters[key]
    if (key.includes('__')) {
      const [parent, child, grandChild] = key.split('__')

      if (!acc[parent]) {
        acc[parent] = {}
      }
      if (!acc[parent][child] && child) {
        acc[parent][child] = {}
      }
      if (!acc[parent][child][grandChild] && grandChild) {
        acc[parent][child][grandChild] = {}
      }

      if (grandChild && filter.filter) {
        acc[parent][child][grandChild] = filter.filter
      } else if (typeof filter.values === 'boolean') {
        if (grandChild) {
          acc[parent][child][grandChild] = {
            operation: '=',
            value: filter.values
          }
        } else {
          acc[parent][child] = {
            operation: '=',
            value: filter.values
          }
        }
      } else if (grandChild) {
        acc[parent][child][grandChild] =
          Array.isArray(filter.values) && filter.values.length === 1
            ? filter.values[0]
            : filter.values
      } else {
        if (Array.isArray(filter.values) && filter.values.length === 1) {
          acc[parent][child] = filter.values[0]
        } else if (Array.isArray(filter.values)) {
          acc[parent][child] = filter.values
        } else {
          acc[parent][child] = filters[key].filter
        }
      }
    } else {
      if (filter.filterType === 'set') {
        const parseValues = filter.values.map((value) =>
          Number(value) ? Number(value) : value
        )

        return {
          ...acc,
          [key]: parseValues
        }
      } else if (filter.filterType === 'date') {
        if (filter.condition1 || filter.condition2) {
          return {
            ...acc,
            [key]: {
              ...(filter.condition1 && {
                condition1: {
                  ...filter.condition1,
                  dateFrom: moment(filter.condition1.dateFrom).format(
                    'YYYY-MM-DD'
                  ),
                  dateTo: moment(filter.condition1.dateTo).format('YYYY-MM-DD')
                }
              }),
              ...(filter.condition2 && {
                condition2: {
                  ...filter.condition2,
                  dateFrom: moment(filter.condition2.dateFrom).format(
                    'YYYY-MM-DD'
                  ),
                  dateTo: moment(filter.condition2.dateTo).format('YYYY-MM-DD')
                }
              }),
              operator: filter.operator
            }
          }
        }

        if (filter.type === 'equals') {
          return {
            ...acc,
            [key]: moment(filter.dateFrom).format('YYYY-MM-DD')
          }
        } else {
          return {
            ...acc,
            [key]: {
              condition1: {
                ...filter,
                dateFrom: moment(filter.dateFrom).format('YYYY-MM-DD'),
                ...(filter.dateTo && {
                  dateTo: moment(filter.dateTo).format('YYYY-MM-DD')
                })
              }
            }
          }
        }
      } else {
        return {
          ...acc,
          [key]: filter.filter
        }
      }
    }
    return acc
  }, {})
}

/**
 * Filters the data using the specified group keys and row group columns.
 * @param {Array} groupKeys - The group keys.
 * @param {Array} rowGroupCols - The row group columns.
 * @returns {Object} - The filtered data in a single object.
 */
export const filterDataByGroupKeys = (groupKeys, rowGroupCols) => {
  // Generate the filtered data based on the groupKeys
  const filteredData = groupKeys.map((groupKey, index) => {
    const { field } = rowGroupCols[index]
    const fields = field.split('__').reverse()
    // Generate the nested object using reduce
    const nestedObject = fields.reduce((acc, curr, idx) => {
      if (idx === 0) {
        // is a date?
        if (moment(groupKey, 'YYYY-MM-DD', true).isValid()) {
          return {
            [curr]: moment(groupKey).format('YYYY-MM-DD')
          }
        } else {
          return {
            [curr]: {
              operation: '=',
              value: groupKey
            }
          }
        }
      }
      return { [curr]: acc }
    }, {})

    return nestedObject
  })

  // Combine all generated objects into a single object
  return Object.assign({}, ...filteredData)
}

export const formatGroups = (rowGroupCols) => {
  let groups = []
  rowGroupCols.forEach((rowGroup) => {
    const { field } = rowGroup
    groups = [...groups, field.replaceAll('__', '.')]
  })
  return groups
}

/**
 * This function is used to format the translations object array in a way that the aggrid can understand
 *
 * @param {Object[]} translations Objects array with translations
 * @returns {Array} Array of objects with the translations formatted
 */
export const getFormattedTranslations = (translations) => {
  // format translations
  const translationKeyCodeValue = []

  Object.keys(translations).forEach((key, index) => {
    const translation = translations[key]
    const ca = translation.find((item) => item.code === 'ca')
    const es = translation.find((item) => item.code === 'es')
    const ga = translation.find((item) => item.code === 'ga')
    const eu = translation.find((item) => item.code === 'eu')

    translationKeyCodeValue.push({
      id: index,
      key: key,
      ca_value: ca?.value,
      ca_id: ca?.id,
      es_value: es?.value,
      es_id: es?.id,
      ga_value: ga?.value,
      ga_id: ga?.id,
      eu_value: eu?.value,
      eu_id: eu?.id
    })
  })

  translationKeyCodeValue.sort((a, b) => (a.key > b.key ? 1 : -1))

  return translationKeyCodeValue
}
