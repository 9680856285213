import {
  SET_TOKEN_SUCCESS,
  SET_USER_SUCCESS,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  SET_USER_FAVORITES,
  SET_USER_SESSION_EXPIRED
} from '../constants/user'

const initialState = {
  user: null,
  token: null,
  isLoading: false,
  isError: false,
  userSessionExpired: false
}

/**
 * @author ehernandez
 * @param {object} state state of the application
 * @param {object} action action to be executed
 * @returns {object}
 */
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: action.user,
        token: action.token
      }

    case SET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: action.user
      }

    case USER_LOGOUT:
      return initialState

    case SET_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.token,
        user: action.user
      }

    case SET_USER_FAVORITES:
      return {
        ...state,
        favorites: action.favorites,
        totalFavorites: action.totalFavorites
      }

    case SET_USER_SESSION_EXPIRED:
      return {
        ...state,
        userSessionExpired: action.userSessionExpired
      }

    default:
      return state
  }
}

export default userReducer
