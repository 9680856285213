import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import Paragraphs from '../../../../components/Paragraphs'
import TextInput from '../../../../components/TextInput'
import useFlashMessage from '../../../../infrastructure/hooks/useFlashMessage'
import { usePharmacy } from '../../../../infrastructure/hooks/usePharmacy/usePharmacy'
import { emailInputs } from './constants/emails'

const ChangeEmailModal = ({
  onRequestClose,
  personalData,
  loading,
  id,
  isAdmin,
  setUserData,
  updateUser
}) => {
  const { t } = useTranslation()
  const { userPersonalData, userContact, userPharmacyData, pharmacyId } =
    personalData

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm()

  const [oldEmail, setOldEmail] = useState('')

  const { updateMetadata } = usePharmacy()
  const { showMessage } = useFlashMessage()

  const validateEmail = (value) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailRegex.test(value) || 'Email no válido'
  }

  /**
   * Executes a function when the `userPersonalData` dependency changes.
   * @function useEffect
   * @param {Function} effect - The effect function to execute.
   * @param {Array} dependencies - An array of dependencies that triggers the effect when changed.
   * @returns {void}
   */
  useEffect(() => {
    if (userPersonalData) {
      /**
       * Sets the `oldEmail` state based on the `userPersonalData`.
       * @function setOldEmail
       * @returns {void}
       */
      setOldEmail(
        userPersonalData.find((data) => data?.databaseField === 'email')
          ?.value || ''
      )
    }
  }, [userPersonalData])

  /**
   * This function is used to submit the form and update the user data
   * @param {formState} e
   * @returns
   */
  const onSubmit = async (e) => {
    let allSuccess = false
    let errors = null
    const newUserData = {
      id: id,
      admin: isAdmin,
      ...e
    }

    if (newUserData.email === oldEmail) {
      delete newUserData.email
    } else {
      const { success, data, error } = await updateUser(newUserData)
      if (success && data) {
        allSuccess = true
      } else {
        errors = error
      }
    }

    const pharmarcyEmails = {}
    Object.keys(e).forEach((key) => {
      if (key.includes('email_')) {
        pharmarcyEmails[key] = e[key]
      }
    })

    if (Object.keys(pharmarcyEmails).length > 0) {
      // update pharmacy emails
      const { success, data, error } = await updateMetadata({
        id: pharmacyId,
        admin: isAdmin,
        ...pharmarcyEmails
      })

      if (success && data) {
        allSuccess = true
      } else {
        allSuccess = false
        errors = error
      }
    }

    if (allSuccess) {
      userContact.forEach((contact) => {
        Object.keys(pharmarcyEmails).forEach((key) => {
          if (contact.databaseField.endsWith(key)) {
            contact.value = pharmarcyEmails[key]
          }
        })
      })
      if (newUserData.email) {
        userPersonalData.forEach((data) => {
          if (data.databaseField === 'email') {
            data.value = newUserData.email
          }
        })
      }

      setUserData({
        ...personalData,
        userPersonalData,
        userContact
      })

      showMessage({
        type: 'success',
        message: t('El email ha cambiado correctamente')
      })
      onRequestClose()
      return
    }

    if (errors) {
      showMessage({
        type: 'alert',
        message: t(errors?.errors?.email[0] || 'Error al cambiar el email')
      })
    }
  }
  /**
   * This constant is used to check if the pharmacy is validated
   * @returns {boolean}
   */
  const isPharmacyValidated = userPharmacyData?.find(
    (data) => data?.label === 'Estado'
  )?.value

  return (
    <Modal
      onRequestClose={onRequestClose}
      textAccept={t('Guardar')}
      title={t('Cambio de email')}
      onAccept={() => {
        document.getElementById('email-form').requestSubmit()
      }}
      size='md'
      loading={loading}
    >
      <div className='tw-p-3'>
        <div className='tw-flex tw-gap-2'>
          <Paragraphs size='lg'>
            {t('Vas a cambiar el email del usuario')}:
          </Paragraphs>
          <Paragraphs size='lg' weight='bold'>
            {`${
              userPersonalData?.find((data) => data?.label === 'Nombre')
                ?.value +
              ' ' +
              userPersonalData?.find((data) => data?.label === 'Apellidos')
                ?.value
            } (id: ${id})`}
          </Paragraphs>
        </div>

        <form
          id='email-form'
          onSubmit={handleSubmit(onSubmit)}
          className='tw-flex tw-pt-5 tw-h-full tw-flex-wrap'
        >
          <div className='tw-flex tw-flex-row tw-w-full'>
            <TextInput
              type='text'
              width='64'
              id='email'
              required
              label={t('email')}
              register={register('email', {
                required: {
                  value: true,
                  message: `${t('email')} ${t('es requerido')}`
                },
                value: userPersonalData?.find(
                  (data) => data?.databaseField === 'email'
                )?.value,
                validate: validateEmail
              })}
              error={t(errors.email?.message)}
            />
          </div>
          {isPharmacyValidated ? (
            <>
              <div className='tw-flex tw-flex-row tw-w-full my-4'>
                <Paragraphs size='lg'>
                  {t('Vas a cambiar los email de contacto de la farmácia')}:
                </Paragraphs>
              </div>
              {emailInputs.map((input, i) => (
                <div
                  className='tw-flex tw-flex-col tw-w-1/2 tw-items-start tw-h-full'
                  key={`${input.id}-${i}`}
                >
                  <TextInput
                    {...input}
                    label={t(input.label)}
                    register={register(input.id, {
                      required: {
                        value: true,
                        message: `${t(input.label)} ${t('es requerido')}`
                      },
                      value: userContact?.find(
                        (data) => data?.label === input.label
                      )?.value,
                      validate: validateEmail
                    })}
                    error={t(errors.email?.message)}
                  />
                </div>
              ))}
            </>
          ) : null}
        </form>
      </div>
    </Modal>
  )
}

export default ChangeEmailModal

ChangeEmailModal.propTypes = {
  personalData: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  setUserData: PropTypes.func.isRequired
}
