import axios from 'axios'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionSetUserSessionExpired } from '../../redux/actions/user'
import { BASE } from '../../constants/configurations'

const URL_BASE = BASE
/**
 * Hook for create fetch api rest
 *
 *
 * @author   ehernandez
 * @param    {String}   endpoint  endpoint to call
 */
export const useMutation = (endpoint) => {
  let loading = false
  const setLoading = (value) => {
    loading = value
  }

  const { token, userSessionExpired } = useSelector(
    (state) => state.userReducer
  )
  const dispatch = useDispatch()

  /**
   * Custom fetch mutation for axios
   *
   *
   * @author   ehernandez
   * @param    {Object} data
   * @param    {String}   data.method   method api
   * @param    {String}   data.data     info to send to body
   * @param    {String}   data.addToURL
   */
  const fetch = async ({
    method = 'post',
    data,
    params,
    addToURL = '',
    responseType = 'json'
  }) => {
    setLoading(true)
    let URL = URL_BASE

    if (
      endpoint === 'user/info' ||
      endpoint === 'cooperatives' ||
      endpoint === 'provinces' ||
      endpoint === 'languages' ||
      endpoint === 'user/token-info' ||
      endpoint === 'locations' ||
      endpoint === 'metadata' ||
      endpoint === 'products/query'
    ) {
      URL = URL_BASE.replace('/backoffice', '')
    }

    if (endpoint === 'translations' && method === 'get' && addToURL !== '') {
      URL = URL_BASE.replace('/backoffice', '')
    }

    const config = {
      method: method,
      url: URL + endpoint + addToURL,
      responseType
    }

    if (data) {
      config.data = data
    }
    if (params) {
      config.params = params
    }

    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return await axios(config)
      .then((response) => {
        setLoading(false)
        if (response.status !== 200) {
          return {
            success: false,
            error: response,
            status: response.status
          }
        }
        return {
          success: true,
          data: response.data,
          status: response.status
        }
      })
      .catch((err) => {
        setLoading(false)
        if (err && err.response) {
          const { status } = err?.response
          if (status === 401) {
            if (endpoint !== 'login' && !userSessionExpired) {
              dispatch(actionSetUserSessionExpired(true))
            }

            return {
              success: false,
              status
            }
          } else {
            return {
              success: false,
              error: err?.response?.data,
              status
            }
          }
        }

        return {
          success: false,
          error: err?.response?.data,
          status: 500
        }
      })
  }

  return [fetch, { loading }]
}
