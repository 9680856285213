import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../../../components/Paragraphs'
import 'moment/locale/es'
import useGetUsersInfo from '../../../../infrastructure/hooks/useUsersManagement'
import useUser from '../../../../infrastructure/hooks/useUser'
import ChangeRolesModal from '../ChangeRolesModal'
import useFlashMessage from '../../../../infrastructure/hooks/useFlashMessage'
import Modal from '../../../../components/Modal'
import ChangeCooperativeModal from '../ChangeCooperativeModal/ChangeCooperativeModal'
import ChangeAppClientTypeModal from '../ChangeAppClientTypeModal'
import { useTranslation } from 'react-i18next'

const UserAdminField = ({
  index,
  label,
  value,
  roles,
  editable = false,
  assingRole,
  getUserRolesList,
  currentUserId,
  userData,
  setUserData,
  isSuperAdmin,
  isEditing
}) => {
  const { t } = useTranslation()
  const [edit, setEdit] = useState(false)
  const [openModalDeleteUser, setOpenModalDeleteUser] = useState(false)
  const [openModalChangeUserAdmin, setOpenModalChangeUserAdmin] =
    useState(false)
  const { userDelete, userRestore, updateUser } = useGetUsersInfo()
  const { user: loggedInUser } = useUser()
  const { showMessage } = useFlashMessage()
  const [loading, setLoading] = useState(false)
  const isAdmin =
    userData?.userAdminData.find((obj) => obj.label === 'Cargar datos')
      ?.value || false
  const isActive = userData?.userAdminData.find(
    (obj) => obj.label === 'Activo'
  )?.value

  /**
   * This function is used to handle the edit of the admin field and call the function to update the user. It sets the user data in the state and shows a message
   * @async
   * @param {string} label - the label of the field
   * @param {boolean} value - the value of the field
   * @returns {void}
   */
  const handleEditAdmin = async (label, value) => {
    switch (label) {
      case 'Cargar datos':
        if (!isActive) {
          return showMessage({
            type: 'alert',
            message: t(
              'No se puede otorgar permisos de cargar datos a un usuario dado de baja'
            )
          })
        }
        setOpenModalChangeUserAdmin(currentUserId)
        break
      case 'Activo':
        // if (isAdmin) {
        //   setEdit(false)
        //   return showMessage({
        //     type: 'alert',
        //     message: t('No se puede dar de baja un usuario administrador')
        //   })
        // }
        setOpenModalDeleteUser(currentUserId)
        break
      default:
        break
    }
  }

  /**
   * This function is used to handle the edit of the active field and call the function to update the user.
   ** It sets the user data in the state and shows a message
   * @async
   * @returns {void}
   */
  const handleDeleteUser = async () => {
    setLoading(true)
    if (edit.value === true) {
      const { success, data } = await userDelete(currentUserId)
      if (success && data) {
        showMessage({
          type: 'success',
          message: t('El usuario ha sido dado de baja')
        })
        setUserData(data)
        setOpenModalDeleteUser(false)
        setEdit(false)
        setLoading(false)
      } else {
        showMessage({
          type: 'alert',
          message: t('No se ha podido dar de baja el usuario')
        })
      }
    } else {
      const { success, data, error } = await userRestore(currentUserId)
      if (success && data) {
        showMessage({
          type: 'success',
          message: t('El usuario ha sido dado de alta')
        })
        setUserData(data)
        setOpenModalDeleteUser(false)
        setEdit(false)
        setLoading(false)
      } else {
        showMessage({
          type: 'alert',
          message: t('No se ha podido dar de alta el usuario')
        })
      }
    }
  }

  /**
   * This function is used to handle the edit of the admin role and call the function to update the user.
   * It sets the user data in the state and shows a message
   * @async
   * @returns {void}
   */
  const handleUpdateUser = async () => {
    setLoading(true)
    const newUserData = {
      ...userData.personalData,
      admin: !edit.value,
      id: currentUserId
    }
    const { success, data } = await updateUser(newUserData)
    getResponse(success, data)
    setLoading(false)
  }

  /**
   * This function is used to handle the response of the update user function
   * @async
   * @param {boolean} success - the success of the update
   * @param {object} data - the data of the update
   * @returns {void}
   * @see handleUpdateUser
   */
  const getResponse = async (success, data) => {
    if (success && data) {
      let userAdminData = userData.userAdminData
      userAdminData = userAdminData.map((obj) => {
        if (obj.label === 'Cargar datos') {
          return {
            ...obj,
            value: !edit.value
          }
        }
        return obj
      })

      setUserData({
        ...userData,
        userAdminData
      })

      setEdit(false)
      showMessage({
        type: 'success',
        message: t('Se han actualizado los permisos de cargar datos')
      })
      setOpenModalChangeUserAdmin(false)
    } else {
      setEdit(false)
      showMessage({
        type: 'alert',
        message: t('No se ha podido otorgar permisos de cargar datos')
      })
      setOpenModalChangeUserAdmin(false)
    }
  }

  const checkUserLoggedPermissions = () => {
    return (
      roles &&
      loggedInUser &&
      loggedInUser.roles &&
      roles.some((role) => loggedInUser.roles.includes(role))
    )
  }

  const checkUserLoggedPermissionsEditTypeClient = () => {
    return (
      loggedInUser &&
      loggedInUser.bo_cooperative &&
      loggedInUser.bo_cooperative.id === 1
    )
  }

  return (
    checkUserLoggedPermissions() && (
      <div
        className='tw-flex-1 tw-flex tw-gap-2 tw-border-b tw-border-b-1 tw-border-gray-100'
        key={index}
      >
        <div
          className={
            'tw-flex-1 tw-flex tw-gap-2 tw-border-b tw-border-b-1 tw-border-gray-100'
          }
        >
          <Paragraphs size={'sm'}>{label}:</Paragraphs>
          <div
            className={
              'tw-flex tw-justify-between tw-w-fit tw-gap-1 tw-items-center'
            }
            style={{ maxWidth: '150px' }}
          >
            <Paragraphs
              size={'sm'}
              className={`tw-w-fit ${value !== '' ? '' : 'tw-text-gray-300'}`}
              weight={'bold'}
            >
              {typeof value === 'boolean'
                ? value
                  ? 'Si'
                  : 'No'
                : value || t('Sin datos')}
            </Paragraphs>
          </div>
        </div>
        {label === 'Tipo de cliente' &&
          (editable = checkUserLoggedPermissionsEditTypeClient())}
        {editable && !isEditing && (
          <div
            className={`tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-ml-4 tw-mb-1 ${
              checkUserLoggedPermissions() ? '' : 'tw-cursor-not-allowed'
            }`}
            onClick={(e) => {
              if (!checkUserLoggedPermissions()) return
              e.stopPropagation()
              setEdit({ label, value })
              if (label !== 'Roles') {
                handleEditAdmin(label, !value)
              } else {
                if (!isActive) {
                  setEdit(false)
                  return showMessage({
                    type: 'alert',
                    message: t(
                      'No se puede otorgar permisos de cargar datos a un usuario dado de baja'
                    )
                  })
                }
              }
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='18'
              height='18'
              viewBox='0 0 24 24'
              className={edit ? 'tw-hidden' : ''}
            >
              <path d='M18.363 8.464l1.433 1.431-12.67 12.669-7.125 1.436 1.439-7.127 12.665-12.668 1.431 1.431-12.255 12.224-.726 3.584 3.584-.723 12.224-12.257zm-.056-8.464l-2.815 2.817 5.691 5.692 2.817-2.821-5.693-5.688zm-12.318 18.718l11.313-11.316-.705-.707-11.313 11.314.705.709z' />
            </svg>
          </div>
        )}
        {edit && edit.label === 'Roles' && (
          <ChangeRolesModal
            onRequestClose={() => setEdit(false)}
            assingRole={assingRole}
            getUserRolesList={getUserRolesList}
            currentUserId={currentUserId}
            userData={userData}
            setUserData={setUserData}
          />
        )}
        {openModalDeleteUser && (
          <Modal
            title={edit.value === true ? t('Dar de baja') : t('Dar de alta')}
            onRequestClose={() => {
              setOpenModalDeleteUser(false)
              setEdit(false)
            }}
            onAccept={handleDeleteUser}
            textAccept='Confirmar'
            cancelText='Cancelar'
            size='md'
            loading={loading}
          >
            <Paragraphs size={'sm'} weight={'bold'} className='tw-p-4'>
              {edit.value === true
                ? t('¿Está seguro que desea dar de baja al usuario?')
                : t('¿Está seguro que desea dar de alta al usuario?')}
            </Paragraphs>
          </Modal>
        )}
        {openModalChangeUserAdmin && (
          <Modal
            title={t('Cambiar permisos de cargar datos')}
            onRequestClose={() => {
              setOpenModalChangeUserAdmin(false)
              setEdit(false)
            }}
            onAccept={handleUpdateUser}
            textAccept={t('Confirmar')}
            cancelText={t('Cancelar')}
            size='md'
            loading={loading}
          >
            <Paragraphs size={'sm'} weight={'bold'} className='tw-p-4'>
              {edit.value === true
                ? t(
                    '¿Está seguro que desea revocar los permisos de cargar datos?'
                  )
                : t(
                    '¿Está seguro que desea otorgar los permisos de cargar datos?'
                  )}
            </Paragraphs>
          </Modal>
        )}

        {edit && edit.label === 'Cooperativa BO' && (
          <ChangeCooperativeModal
            onRequestClose={() => setEdit(false)}
            updateUser={updateUser}
            currentUserId={currentUserId}
            userData={userData}
            setUserData={setUserData}
            isSuperAdmin={isSuperAdmin}
          />
        )}

        {edit && edit.label === 'Tipo de cliente BO' && (
          <ChangeAppClientTypeModal
            onRequestClose={() => setEdit(false)}
            currentUserId={currentUserId}
            updateUser={updateUser}
            userData={userData}
            setUserData={setUserData}
            isSuperAdmin={isSuperAdmin}
          />
        )}
      </div>
    )
  )
}

UserAdminField.propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  editable: PropTypes.bool,
  assingRole: PropTypes.func,
  getUserRolesList: PropTypes.func,
  currentUserId: PropTypes.number,
  userData: PropTypes.object,
  setUserData: PropTypes.func,
  roles: PropTypes.array,
  isSuperAdmin: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool
}

export default UserAdminField
