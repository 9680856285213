import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../../../../components/Button/Button'
import Paragraphs from '../../../../components/Paragraphs'
import TextInput from '../../../../components/TextInput/TextInput'
import { INPUTS } from '../../constants/inputs'
import { useLogin } from '../../hooks/useLogin/useLogin'
import { useTheme } from '../../../../infrastructure/context/ThemeContext/ThemeContext'
import useUser from '../../../../infrastructure/hooks/useUser'
import { actionSetUserSessionExpired } from '../../../../infrastructure/redux/actions/user'
import { useDispatch } from 'react-redux'
/**
 * page component for the login screen
 * @author ehernandez
 * @returns {React.Component}
 */
const LoginScreen = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { theme } = useTheme()
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm()

  const { onLogin, loading } = useLogin()
  const { token } = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(actionSetUserSessionExpired(false))
  }, [])

  /**
   * form submit for login form
   * @author   ehernandez
   * @param    {Object} user element
   * @param    {String}   user.email user
   * @param    {String}   user.password password
   */
  const onSubmit = async (user) => {
    const { success } = await onLogin(user)
    if (success) {
      navigate('/')
    } else {
      setError('email', {
        type: 'error',
        message: t('Credenciales invalidas')
      })
    }
  }

  if (token) {
    navigate('/')
  }

  return (
    <div
      id='login-screen'
      className='login-screen tw-flex tw-h-screen tw-w-screen overflow-hidden'
    >
      <div className='tw-flex-1 tw-p-10'>
        <div className='login-screen__form__header'>
          <img src={theme.logoDark} alt='logo' className='tw-max-h-12' />
        </div>
        <div className='login-screen__form__body  tw-h-full tw-flex tw-items-center tw-justify-center tw-w-full tw-p-10'>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='tw-w-full tw-max-w-[350px]'
          >
            <Paragraphs className='tw-pb-4' weight='bold' size='2xl'>
              {t('Bienvenido al backoffice')}
            </Paragraphs>
            <Paragraphs className='tw-pb-4'>
              {t(
                'Accede con tus credenciales de Vadefarma a todas las herramientas.'
              )}
            </Paragraphs>
            <div className='login-screen__form__body__inputs tw-mb-2'>
              {INPUTS.login.map((input) => (
                <TextInput
                  error={errors?.[input.name]?.message}
                  id={input.id}
                  key={input.id}
                  register={register(input.name, {
                    required: t('Campo requerido')
                  })}
                  name={input.name}
                  label={t(input.label)}
                  type={input.type}
                />
              ))}
            </div>
            <Button
              disabled={loading}
              type='submit'
              label={t('Acceder')}
              uppercase
              mode='primary'
              size='full'
            />
          </form>
        </div>
      </div>
      <div className='tw-flex-1 tw-overflow-hidden tw-flex tw-items-center tw-justify-center tw-relative'>
        <div className='tw-absolute tw-inset-0 tw-pin-b tw-pin-r tw-pin-l tw-pin-t tw-w-full tw-h-full tw-bg-primary tw-bg-opacity-50' />
        <img
          src={require('../../assets/img/background.jpg')}
          className='tw-max-w-[1000px]'
          alt='login-background'
        />
      </div>
    </div>
  )
}

export default LoginScreen
