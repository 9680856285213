import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useTranslation } from 'react-i18next'
import BannerAggridList from '../../components/BannerAggridList/BannerAggridList'
import Page from '../../../../components/Page'
import 'react-tabs/style/react-tabs.css'
import BannerEdit from '../BannerEdit'
import PreviewView from '../../components/PreviewView'
import useBanners from '../../../../infrastructure/hooks/useBanners'
import Select from 'react-select'
import { bannersAssign, bannersTypes } from '../../constants/constants'
const iconList =
  require('../../../../infrastructure/assets/icons/list.svg').default
const iconDrag =
  require('../../../../infrastructure/assets/icons/drag.svg').default

const BannerScreen = () => {
  const { getBannerLayouts, getPharmacyLayout, getBannerList, updateLayout } =
    useBanners()
  const { t } = useTranslation()
  const [tabIndex, setTabIndex] = useState(0)
  const [pageTabs, setPageTabs] = useState([])
  const [previewView, setPreviewView] = useState(true)
  const [layouts, setLayouts] = useState([])
  const [selectedLayout, setSelectedLayout] = useState(null)
  const [pharmacyLayout, setPharmacyLayout] = useState(null)
  const [layoutToAssign, setLayoutToAssign] = useState(null)
  const [groupLayout, setGroupLayout] = useState(null)
  const [imageList, setImageList] = useState([])
  const defaultLayoutForAll = useRef()

  useEffect(() => {
    if (previewView === false) return
    Promise.all([getLayouts(), getImagesList()])
  }, [previewView])

  const getImagesList = async () => {
    const { success, data } = await getBannerList({
      page: 1,
      per_page: 100
    })
    if (success) {
      setImageList(data.filter((image) => image.active))
    }
  }

  const getLayouts = async () => {
    const { success, data } = await getBannerLayouts()
    if (success) {
      setLayouts(data)
      const defaultLayout = data.find((layout) => layout.type === 'default')
      onChangeLayout(defaultLayout)
    }
  }

  /**
   * This function is used to remove a tab from the list of tabs
   * and set the index of the tab to show
   *
   * @param {number} id the id of the tab to remove
   * @param {number} index the index of the tab to remove
   * @returns {void}
   */
  const handleTabClick = (id, index) => {
    const newTabs = pageTabs.filter((tab) => tab.id !== id)
    setPageTabs(newTabs)
    if (newTabs.length === 0) {
      setTabIndex(0)
    } else {
      setTabIndex(index - 1)
    }
  }

  /**
   * This function is used to add a new tab to the list of tabs
   * and set the index of the tab to show
   * This tab is used to create a new banner
   * @returns {void}
   */
  const handleNewBanner = () => {
    const newTab = { id: -1, title: t('Nuevo Banner') }
    // is newTab already in pageTabs?
    const found = pageTabs.find((tab) => tab.id === newTab.id)
    if (found) {
      // if so, set the index to that tab
      setTabIndex(pageTabs.indexOf(found) + 1)
      return
    }
    // otherwise, add the newTab to pageTabs
    setPageTabs([...pageTabs, newTab])
    // and set the index to the newTab
    setTabIndex(pageTabs.length ? pageTabs.length + 1 : 1)
  }

  const onChangeLayout = async (layout) => {
    setSelectedLayout(layout)
    setLayoutToAssign(null)
    const { success, data } = await getPharmacyLayout(layout.id)
    if (success) {
      setPharmacyLayout(data)
      if (defaultLayoutForAll.current === undefined) {
        defaultLayoutForAll.current = data
      }

      if (layout.type !== bannersTypes.default) {
        setLayoutToAssign(() =>
          bannersAssign.find((o) => o.value === layout.use_inherit)
        )
        if (layout.use_inherit === bannersTypes.group) {
          getLayoutsByGroup(layout)
        }
      } else {
        setLayoutToAssign(null)
      }
    }
  }

  const onUpdateLayout = async (layout) => {
    setLayoutToAssign(layout)
    const { data, success } = await updateLayout(
      selectedLayout.id,
      layout.value
    )
    if (success) {
      setLayouts((layouts) => {
        const newLayouts = [...layouts]
        const index = newLayouts.findIndex((o) => o.id === selectedLayout.id)
        newLayouts[index] = data
        return newLayouts
      })
    }
  }

  const getLayoutsByGroup = async (layout) => {
    const { success, data } = await getPharmacyLayout(layout.inherits.parent)
    if (success) {
      setGroupLayout(data)
    }
  }

  const layoutOptions = useMemo(() => {
    return bannersAssign.filter((o) => o.value !== selectedLayout?.type)
  }, [selectedLayout])

  const bannerList = useMemo(() => {
    if (pharmacyLayout === null) return []
    switch (layoutToAssign?.value) {
      case bannersTypes.default:
        return defaultLayoutForAll.current || []
      case bannersTypes.group:
        // check if layoutToAssign is group, check if groupLayout is null or default, then return if groupLayout is not null groupLayout else return defaultLayoutForAll
        if (layoutToAssign.value === bannersTypes.group) {
          const layoutFromGroup = layouts.find(
            (o) => o.id === selectedLayout.inherits.parent
          )
          if (layoutFromGroup?.use_inherit === bannersTypes.default) {
            return defaultLayoutForAll.current || []
          }
          return groupLayout || []
        }
        return groupLayout
      default:
        return pharmacyLayout
    }
  }, [pharmacyLayout, layoutToAssign, groupLayout, selectedLayout])

  const blockLayout = useMemo(() => {
    if (selectedLayout?.type === bannersTypes?.default) return false
    if (selectedLayout === null || layoutToAssign === null) return true

    if (
      selectedLayout.type === bannersTypes.cooperative &&
      layoutToAssign.value === bannersTypes.default
    ) {
      return true
    }
    if (
      selectedLayout.type === bannersTypes.cooperative &&
      layoutToAssign.value === bannersTypes.group
    ) {
      return true
    } else {
      return layoutToAssign?.value === bannersTypes.default
    }
  }, [selectedLayout, layoutToAssign?.value])

  return (
    <Page.Container>
      <Page.Header
        title={t('Listado de Banners')}
        className='tw-flex tw-items-center'
      ></Page.Header>
      <Page.Body>
        <div className='tw-flex tw-flex-col tw-h-full tw-w-full'>
          <div className='tw-flex tw-justify-end tw--mb-5 tw-z-10 tw-gap-4'>
            {previewView ? (
              <button
                className='tw-flex tw-items-center tw-justify-center tw-bg-white tw-p-2 tw-border tw-border-gray-300 tw-text-gray-500 tw-text-sm hover:tw-text-gray-700 hover:tw-border-gray-500'
                onClick={() => setPreviewView(false)}
              >
                {t('Configuración de imágenes')}
                <img
                  src={iconList}
                  className='tw-w-4 tw-h-4 tw-ml-2'
                  title={t('Vista Aggrid')}
                />
              </button>
            ) : (
              <button
                className='tw-flex tw-items-center tw-justify-center tw-bg-white tw-p-2 tw-border tw-border-gray-300 tw-text-gray-500 tw-text-sm hover:tw-text-gray-700 hover:tw-border-gray-500'
                onClick={() => setPreviewView(true)}
              >
                {t('Diseño visual')}
                <img
                  src={iconDrag}
                  className='tw-w-4 tw-h-4 tw-ml-2'
                  title={t('Vista Aggrid')}
                />
              </button>
            )}
          </div>

          {!previewView ? (
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
              defaultFocus
            >
              <TabList>
                <Tab key={0}>
                  <div
                    className={`tw-flex tw-gap-6 tw-justify-between tw-align-center ${
                      tabIndex !== 0 && 'tw-opacity-30'
                    }`}
                  >
                    {t('Banners')}
                  </div>
                </Tab>
                {pageTabs.map((tab, index) => {
                  index++
                  return (
                    <Tab key={index}>
                      <div
                        key={`${index}_tab`}
                        className={`tw-flex tw-gap-6 tw-justify-between ${
                          tabIndex !== index && 'tw-opacity-30'
                        }`}
                        onMouseDown={(e) => {
                          e.stopPropagation()
                          if (e.button !== 1) return
                          handleTabClick(tab.id, index)
                        }}
                      >
                        {tab.title}
                        {pageTabs.length > 0 && (
                          <div
                            className='tw-rounded-full tw-px-1 tw-text-center tw-bg-black tw-text-white tw-text-xs tw-self-center tw-cursor-pointer tw-font-bold tw-z-10'
                            onClick={(e) => {
                              e.stopPropagation()
                              handleTabClick(tab.id, index)
                            }}
                          >
                            X
                          </div>
                        )}
                      </div>
                    </Tab>
                  )
                })}
              </TabList>
              <TabPanel key={0}>
                <div className='tw-h-full'>
                  <BannerAggridList
                    setPageTabs={setPageTabs}
                    pageTabs={pageTabs}
                    setTabIndex={setTabIndex}
                    handleNewBanner={handleNewBanner}
                    selectedLayout={selectedLayout}
                  />
                </div>
              </TabPanel>
              {pageTabs.map((tab, index) => {
                index++
                return (
                  <TabPanel key={index} forceRender>
                    <div className='tw-h-full tw-border tw-border-1 tw-border-gray-300'>
                      <BannerEdit tab={tab} handleTabClick={handleTabClick} />
                    </div>
                  </TabPanel>
                )
              })}
            </Tabs>
          ) : (
            <>
              <div className='tw-flex tw-flex-row tw-h-full tw-w-full tw-gap-4'>
                <div className='tw-w-1/4'>
                  <label>{t('Seleccionar layout:')}</label>
                  <Select
                    options={layouts}
                    isLoading={!layouts.length}
                    loadingMessage={() => t('Cargando...')}
                    getOptionLabel={(option) => {
                      const name = option.name
                      switch (option.type) {
                        case bannersTypes.default:
                          return t(name)
                        case bannersTypes.group:
                          return name + ` (${t('Grupo')})`
                        case bannersTypes.cooperative:
                          return name + ' (Unnefar)'
                        default:
                          return t(name)
                      }
                    }}
                    getOptionValue={(option) => option.organization_id}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        color: state.isSelected ? 'white' : 'black',
                        backgroundColor: state.isSelected
                          ? '#3182ce'
                          : state.isFocused
                          ? '#e2e8f0'
                          : 'white'
                      })
                    }}
                    value={selectedLayout}
                    className='basic-multi-select tw-w-full tw-text-black tw-z-[20]'
                    placeholder={t('Carge un layout')}
                    noOptionsMessage={() => t('No hay layouts')}
                    onChange={onChangeLayout}
                    tabIndex={1}
                  />
                </div>
                {selectedLayout &&
                  selectedLayout.type !== bannersTypes.default && (
                    <div className='tw-w-1/4'>
                      {layoutToAssign ? (
                        <>
                          <label>{t('Usar layout')}:</label>

                          <Select
                            options={layoutOptions}
                            getOptionLabel={(option) => {
                              if (option.value === bannersTypes.group) {
                                return t(option.label) + ' (Unnefar)'
                              }
                              return t(option.label)
                            }}
                            value={layoutToAssign}
                            id='layoutToAssign'
                            className='basic-multi-select tw-w-full tw-text-black tw-z-[20]'
                            placeholder={t('Elige una opción')}
                            noOptionsMessage={() => t('No hay layouts')}
                            onChange={onUpdateLayout}
                            tabIndex={2}
                          />
                        </>
                      ) : null}
                    </div>
                  )}
              </div>
              <div
                className={`tw-h-full tw-relative ${
                  blockLayout
                    ? 'tw-opacity-60 tw-cursor-not-allowed tw-pointer-events-none'
                    : ''
                }`}
              >
                <PreviewView
                  bannerList={bannerList}
                  imageList={imageList}
                  layoutToAssign={layoutToAssign}
                  layoutId={selectedLayout?.id}
                  defaultLayoutForAll={defaultLayoutForAll}
                />
              </div>
            </>
          )}
        </div>
      </Page.Body>
    </Page.Container>
  )
}

export default BannerScreen
