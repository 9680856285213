import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import Paragraphs from '../../../../components/Paragraphs'
import Button from '../../../../components/Button'
import useUsersManagement from '../../../../infrastructure/hooks/useUsersManagement'
import Select from 'react-select'
import useFlashMessage from '../../../../infrastructure/hooks/useFlashMessage'

const ChangeRolesModal = ({
  onRequestClose,
  assingRole,
  getUserRolesList,
  currentUserId,
  userData,
  setUserData
}) => {
  const [roles, setRoles] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { getRoles } = useUsersManagement()
  const { showMessage } = useFlashMessage()

  /**
   * Executes an asynchronous effect function when the component mounts.
   * @function useEffect
   * @param {Function} effect - The effect function to execute.
   * @param {Array} dependencies - An array of dependencies that triggers the effect when changed.
   * @returns {void}
   */
  useEffect(async () => {
    setLoading(true)
    const dataFecth = async () => {
      const { data, success } = await getRoles()
      if (success && data) {
        setRoles(data)
      }

      const { data: userRoles, success: successUserRoles } =
        await getUserRolesList(currentUserId)
      if (successUserRoles && userRoles) {
        const parsedRoles = []
        data.forEach((role) => {
          if (userRoles.items?.find((r) => r === role.name)) {
            parsedRoles.push({
              id: role.id,
              name: role.name
            })
          }
        })
        setSelectedRoles(parsedRoles)
      }
      setLoading(false)
    }

    dataFecth()
  }, [])

  /**
   * On submit form handler
   * This function will assing roles to user and remove roles from user
   * @async
   * @param {event} e
   * @returns void
   */
  const onSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)

    const rolesIds = selectedRoles.map((role) => role.id)
    await assingRole(rolesIds, currentUserId)

    setTimeout(() => {
      let userAdminData = userData.userAdminData
      userAdminData = userAdminData.map((obj) => {
        if (obj.label === 'Roles') {
          return {
            ...obj,
            value: selectedRoles.map((role) => role.name).join(', ')
          }
        }
        return obj
      })

      setUserData({
        ...userData,
        userAdminData
      })

      showMessage({
        type: 'success',
        message: 'Roles actualizados correctamente'
      })

      onRequestClose()
      setLoading(false)
    }, 500)
  }

  return (
    <Modal
      onRequestClose={onRequestClose}
      title={t('Roles de usuario')}
      onAccept={onSubmit}
      hideAcceptButton
      size={'md'}
    >
      <div className={'tw-p-3 tw-h-56'}>
        <div className={'tw-flex tw-gap-2'}>
          <Paragraphs size={'lg'}>
            {t('Vas a cambiar los roles del usuario')}:
          </Paragraphs>
        </div>

        <form
          id={'id'}
          onSubmit={(e) => onSubmit(e)}
          className='tw-w-full tw-flex tw-items-center tw-gap-4 tw-justify-start tw-pt-5'
        >
          <div className={'tw-flex tw-w-1/2 tw-h-full tw-pt-5'}>
            <Select
              options={roles}
              value={selectedRoles}
              isLoading={loading}
              isMulti
              className='basic-multi-select tw-w-full tw-h-full'
              classNamePrefix='role'
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={t('Selecciona los roles')}
              noOptionsMessage={() => t('No hay roles')}
              onChange={(e) => {
                document
                  .getElementsByClassName('role__control')[0]
                  .removeAttribute('style')
                setSelectedRoles(e)
              }}
            />
          </div>
          <div className={'tw-h-full tw-pt-5 tw-self-start'}>
            <Button
              size='full'
              label={t('Guardar')}
              uppercase
              type='submit'
              disabled={loading}
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ChangeRolesModal

ChangeRolesModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  assingRole: PropTypes.func.isRequired,
  removeRoleUser: PropTypes.func.isRequired,
  getUserRolesList: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired
}
