import React from 'react'
import PropTypes from 'prop-types'
import SidebarItem from '../SidebarItem'
/**
 * @ehernandez
 * @description Sidebar component.
 * @param {*} props
 * @returns
 */
const Sidebar = ({ items, onClickItem }) => {
  return (
    <div className='tw-fixed tw-h-screen tw-w-[210px] tw-bg-[#363a41] tw-left-0 tw-top-0 tw-mt-[40px]'>
      {items.map((item) => (
        <SidebarItem
          key={item.path}
          {...item}
          onClickItem={() => onClickItem(item)}
        />
      ))}
    </div>
  )
}

Sidebar.propTypes = {
  items: PropTypes.array,
  onClickItem: PropTypes.func
}

Sidebar.defaultProps = {
  items: []
}

export default Sidebar
