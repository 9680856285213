import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../Paragraphs'
import Button from '../../Button/Button'
import { useTranslation } from 'react-i18next'
export const PAGE_HEADER_SIZE = 60

/**
 * @author ehernandez
 * @return {React.Component}
 */
const Header = ({ className = '', children, title, onAdd }) => {
  const { t } = useTranslation()
  return (
    <div
      className={`tw-bg-white ${className}`}
      style={{ height: PAGE_HEADER_SIZE }}
    >
      <div className='header-container tw-p-4 '>
        <Paragraphs weight='bold' size='2xl'>
          {title}
        </Paragraphs>
      </div>
      <div className='header-container__btn__functions'>
        {onAdd && <Button onClick={onAdd} label={t('Añadir')} />}

        {children}
      </div>

      <hr />
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  title: PropTypes.string,
  onAdd: PropTypes.func
}

export default Header
