import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100%{
    opacity: 0.3;
  }
`

export const FadeLogo = styled.div`
  animation: 1s ${fadeIn} ease-out infinite;
`
