import React from 'react'
import PropTypes from 'prop-types'
import { useDroppable } from '@dnd-kit/core'

function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
    resizeObserverConfig: { attributes: true }
  })

  const style = {
    width: '100%',
    height: '100%',
    opacity: isOver ? 0.5 : 1,
    position: 'relative',
    zIndex: isOver ? 0 : 10
  }

  const layerStyle = {
    backgroundColor: !props.canDrop
      ? 'rgb(255 0 0 / 17%)'
      : 'rgb(130 241 2 / 17%)',
    width: '100%',
    height: '100%',
    position: 'absolute'
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      className='tw-transition-all tw-duration-300'
    >
      {isOver && <div style={layerStyle}></div>}
      {props.children}
    </div>
  )
}

Droppable.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  canDrop: PropTypes.bool
}

export { Droppable }
