import React, { useContext, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { GlobalStyle } from './styles'
import useUser from '../../hooks/useUser'
import SessionExpiredBlock from '../../../components/SessionExpiredBlock/SessionExpiredBlock'
import { colorsVade } from '../../styles/colorsVade'
const colors = require('../../styles/colors.js')

/**
 *
 * @returns {React.Component}
 */
const ThemeContext = React.createContext()

/**
 * It checks if the user is on fedefarmaclients.com or vadefarmaclients.com and sets the theme accordingly
 */
const ThemeProvider = ({ children }) => {
  const { userSessionExpired } = useUser()
  const [loading] = useState(false)
  const [amIOnFedefarmaClients] = useState(
    window.location.hostname.includes('fedefarmaclients')
  )

  const [theme, setTheme] = useState({})

  useLayoutEffect(() => {
    if (amIOnFedefarmaClients) {
      setTheme({
        ...colors,
        logo: require('../../assets/images/logos/logoFedeFarmaWhiteFont.png'),
        logoDark: require('../../assets/images/logos/logoFedeFarmaBlackFont.png'),
        amIOnFedefarmaClients: amIOnFedefarmaClients
      })
      return
    }
    setTheme({
      ...colorsVade,
      logo: require('../../assets/images/logos/logoVadeFarmaWhiteFont.png'),
      logoDark: require('../../assets/images/logos/logoVadeFarmaBlackFont.png'),
      amIOnFedefarmaClients: amIOnFedefarmaClients
    })
  }, [amIOnFedefarmaClients])

  return (
    <ThemeContext.Provider value={{ theme }}>
      {userSessionExpired && <SessionExpiredBlock />}
      <GlobalStyle theme={theme} />
      {!loading && children}
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node
}

/**
 * It returns the value of the ThemeContext
 */
const useTheme = () => useContext(ThemeContext)
export { ThemeContext, ThemeProvider, useTheme }
