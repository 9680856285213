export const defaultColDef = {
  sortable: true,
  resizable: true,
  floatingFilter: true,
  tooltipShowDelay: 10
}
export const columnDefs = [
  {
    headerName: 'Key',
    width: 200,
    field: 'key',
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Castellano',
    id: 'es',
    field: 'es_value',
    filter: 'agTextColumnFilter',
    editable: true,
    width: 400
  },
  {
    id: 'ca',
    headerName: 'Catalán',
    field: 'ca_value',
    filter: 'agTextColumnFilter',
    editable: true,
    width: 400
  },
  {
    id: 'ga',
    headerName: 'Gallego',
    field: 'ga_value',
    filter: 'agTextColumnFilter',
    editable: true,
    width: 400
  },
  {
    id: 'eu',
    headerName: 'Euskera',
    field: 'eu_value',
    filter: 'agTextColumnFilter',
    editable: true,
    width: 400
  }
]
