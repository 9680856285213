/**
 *
 * @param {String} disabled is daisabled
 * @param {String} readOnly is only read
 * @param {String} flex is in row width label
 * @returns {Object} background color
 */
export const useInput = (disabled, readOnly, flex) => {
  /**
 *
 * @param {Object} properites properties for add input
 * @param {Object} element type propertie
 * @returns {String} classes
 */
  const setGenericClass = (
    { uppercase, transparent, textAlign, mode, weight, cursor },
    element
  ) => {
    let classes = ''
    if (uppercase) {
      classes += 'tw-uppercase '
    }
    if (weight) {
      classes += `tw-font-${weight} `
    }
    if (transparent) {
      classes += `tw-${element}-transparent `
    }

    if (textAlign) {
      classes += `tw-text-${textAlign} `
    }

    if (mode !== 'normal') {
      classes += ` tw-text-${mode} `
    }

    if (mode === 'clickable') {
      classes += ' tw-cursor-pointer '
    }

    if (cursor) {
      classes += ` tw-cursor-${cursor} `
    }
    return classes
  }

  /**
 *
 * @param {Object} customClassName classesnames for add
 * @returns {String} classes
 */
  const classSelection = (customClassName) => {
    if (customClassName) {
      return customClassName
    }

    if (disabled) {
      return 'tw-flex tw-border-gray-100 tw-border tw-bg-transparent'
    }

    return 'tw-bg-transparent tw-border tw-border-secondary tw-border-opacity-50'
  }

  /**
 *@author ehernandez
 * @returns {String} classes
 */
  const modeFlex = () => {
    switch (flex) {
      case 'row':
        return 'tw-flex tw-row tw-items-center tw-w-full'
      case 'between':
        return 'tw-flex tw-row tw-items-center tw-justify-between tw-w-full'
    }
  }

  return { setGenericClass, classSelection, modeFlex }
}
