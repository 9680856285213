import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Paragraphs from '../Paragraphs'
import { FlashContent } from './styles'

let flashTimer = null
const FlashMessage = () => {
  const { t } = useTranslation()
  const [event, setEvent] = useState(null)
  const [message, setMessage] = useState(null)
  const ref = useRef(null)

  useEffect(() => {
    ref.current.addEventListener(
      'flashmessage',
      function (e) {
        setEvent(e.detail)
      },
      false
    )
  }, [])

  useEffect(() => {
    if (event) {
      setMessage(event)
    }
  }, [event])

  useEffect(() => {
    if (flashTimer) {
      clearTimeout(flashTimer)
    }
    if (message) {
      flashTimer = setTimeout(() => {
        setMessage(null)
      }, 5000)
    }
  }, [message])

  return (
    <div ref={ref} id='flash-message' className='tw-z-10'>
      <div className='tw-bg-alert' />
      {message && (
        <FlashContent
          className={`flash-message-content tw-bg-${message.type} tw-text-white tw-mt-3 tw-p-4`}
        >
          <Paragraphs size='xxs'>{t(message.type)}</Paragraphs>

          <Paragraphs className='flash-message-message'>
            {t(message.message)}
          </Paragraphs>
        </FlashContent>
      )}
    </div>
  )
}

export default FlashMessage
