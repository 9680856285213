import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import Paragraphs from '../../../../components/Paragraphs'
import Button from '../../../../components/Button'
import useUsersManagement from '../../../../infrastructure/hooks/useUsersManagement'
import Select from 'react-select'
import useFlashMessage from '../../../../infrastructure/hooks/useFlashMessage'

const ChangeCooperativeModel = ({
  onRequestClose,
  currentUserId,
  userData,
  setUserData,
  updateUser,
  isSuperAdmin
}) => {
  const [cooperatives, setCooperatives] = useState([])
  const [selectedCooperative, setSelectedCooperative] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { getCooperatives } = useUsersManagement()
  const { showMessage } = useFlashMessage()

  /**
   * Executes an asynchronous effect function when the component mounts.
   * @function useEffect
   * @param {Function} effect - The effect function to execute.
   * @param {Array} dependencies - An array of dependencies that triggers the effect when changed.
   * @returns {void}
   */
  useEffect(async () => {
    setLoading(true)

    /**
     * Fetches data for cooperatives and updates the component's state.
     * @function dataFetch
     * @returns {Promise<void>}
     */
    const dataFecth = async () => {
      const { data, success } = await getCooperatives()
      if (success && data) {
        const current = userData.userAdminData.find(
          (item) => item.label === 'Cooperativa BO'
        ).value
        setSelectedCooperative(data.find((c) => c.name === current))
        setCooperatives(data)
      }
      setLoading(false)
    }

    dataFecth()
  }, [])

  /**
    On submit form handler
    This function updates the selected cooperative for the user
    @async
    @param {event} e
    @returns void
  */
  const onSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)
    const newUserData = {
      id: currentUserId,
      admin: isSuperAdmin,
      bo_cooperative_id: selectedCooperative.id
    }
    const { success } = await updateUser(newUserData)
    if (!success) {
      showMessage({
        type: 'alert',
        message: t('La cooperativa no se ha podido actualizar')
      })
      setLoading(false)
      return
    }

    let userAdminData = userData.userAdminData
    userAdminData = userAdminData.map((obj) => {
      if (obj.label === 'Cooperativa BO') {
        return {
          ...obj,
          value: selectedCooperative.name
        }
      }
      return obj
    })

    setUserData({
      ...userData,
      userAdminData
    })

    showMessage({
      type: 'success',
      message: t('Cooperativa actualizada correctamente')
    })

    onRequestClose()
    setLoading(false)
  }

  return (
    <Modal
      onRequestClose={onRequestClose}
      title={t('Cooperativa de usuario')}
      onAccept={onSubmit}
      hideAcceptButton
      size={'md'}
    >
      <div className={'tw-p-3 tw-h-56'}>
        <div className={'tw-flex tw-gap-2'}>
          <Paragraphs size={'lg'}>
            {t('Vas a cambiar la cooperativa del usuario')}:
          </Paragraphs>
        </div>

        <form
          id={'id'}
          onSubmit={(e) => onSubmit(e)}
          className='tw-w-full tw-flex tw-items-center tw-gap-4 tw-justify-start tw-pt-5'
        >
          <div className={'tw-flex tw-w-1/2 tw-h-full tw-pt-5'}>
            <Select
              options={cooperatives}
              value={selectedCooperative}
              className='basic-multi-select tw-w-full tw-h-full'
              classNamePrefix='cooperative'
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={t('Selecciona una cooperativa')}
              noOptionsMessage={() => t('No hay cooperativas')}
              onChange={(e) => {
                setSelectedCooperative(e)
              }}
            />
          </div>
          <div className={'tw-h-full tw-pt-5 tw-self-start'}>
            <Button
              size='full'
              label={t('Guardar')}
              uppercase
              type='submit'
              disabled={loading}
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ChangeCooperativeModel

ChangeCooperativeModel.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired
}
