import React from 'react'
import PropTypes from 'prop-types'

/**
 * @author ehernandez
 * @returns {React.Component}
 */
const Body = ({ className = '', children, onScroll }) => {
  return (
    <div className={`${className} tw-flex-1 tw-p-4`} onScroll={onScroll}>
      {children}
    </div>
  )
}

Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onScroll: PropTypes.func
}

export default Body
