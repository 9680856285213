import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import downloadXLSX from '../../services/downloadXLSX/downloadXLSX'
import useMutation from '../useMutation'
import { reportSales, unnefarDetailFields } from './constants/constants'

export const useReports = () => {
  const { t } = useTranslation()
  const [fetchStaticsBasics] = useMutation('statistics/basics')
  const [fetchStaticsFilterBasics] = useMutation('statistics/filter-basics')
  const [fetchUnnefarExcel] = useMutation('statistics/unnefar-excel')
  const [getSalesReport] = useMutation('statistics/sales-report-excel')
  const [fetchUnnefar] = useMutation('statistics/unnefars')
  const [fetchPowerBi] = useMutation('reports/unnefar')
  const [loading, setLoading] = useState(false)

  /**
   * It takes a string and an object, and returns the value of the string as a path in the object
   * @param path - The path to the property you want to get.
   * @param obj - The object to traverse
   * @returns The value of the property at the end of the path.
   */
  const resolve = (path, obj) => {
    if (!path) return null
    return path.split('.').reduce(function (prev, curr) {
      return prev ? prev[curr] : null
    }, obj || self)
  }

  const getStaticsBasics = async () => {
    setLoading(true)
    const { success, data, error } = await fetchStaticsBasics({
      method: 'GET'
    })
    if (!success) {
      setLoading(false)
      return { success: false }
    }

    setLoading(false)
    return {
      success,
      data: data.vadefarma,
      error
    }
  }

  const getStaticsFilterBasics = async (params, filter, orderBy) => {
    setLoading(true)
    const { success, data, error } = await fetchStaticsFilterBasics({
      method: 'POST',
      params,
      data: {
        ...(filter && filter),
        ...orderBy
      }
    })
    if (!success) {
      setLoading(false)
      return { success: false, data: [] }
    }

    const {
      data: { data: responseData, ...pagination }
    } = data
    const reportSalesData = responseData.map((unnefar) => {
      const unnefarData = {}
      reportSales.forEach((column) => {
        unnefarData[column.field] = resolve(column.databaseField, unnefar)
      })
      return unnefarData
    })

    setLoading(false)
    return {
      success,
      data: reportSalesData,
      pagination,
      error
    }
  }

  const getUnnefarExcel = async () => {
    setLoading(true)
    const { success, data, error } = await downloadXLSX({
      setDownloading: setLoading,
      mutationFunction: fetchUnnefarExcel,
      filename: 'unnefar-excel'
    })
    if (!success) {
      setLoading(false)
      return { success: false, data: data?.data }
    }

    setLoading(false)
    return {
      success,
      data,
      error
    }
  }

  const getUnnefar = async (params, filter, order) => {
    setLoading(true)
    const { success, data, error } = await fetchUnnefar({
      method: 'POST',
      params,
      data: {
        ...(filter && filter),
        ...(order && order)
      }
    })
    if (!success) {
      setLoading(false)
      return { success: false, data }
    }

    const unnefars = data.items.map((unnefar) => {
      const unnefarData = {}
      unnefarDetailFields.forEach((column) => {
        unnefarData[column.field] = resolve(column.databaseField, unnefar)
      })
      return unnefarData
    })

    setLoading(false)
    return {
      success,
      data: unnefars,
      pagination: data.pagination,
      error
    }
  }

  /**
   * Download sales report in excel
   */
  const downloadSalesReport = async (filters) => {
    setLoading(true)
    const { success, data } = await getSalesReport({
      method: 'POST',
      data: {
        ...filters
      },
      responseType: 'blob'
    })

    if (!success) {
      setLoading(false)
      return { success: false }
    }

    if (success) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = t('Datos de ventas')
      link.click()

      return { success: true }
    }
  }

  // Get power bi report * Acofarma
  const getPowerBi = async () => {
    setLoading(true)
    const { success, data, error } = await fetchPowerBi({
      method: 'GET'
    })
    if (!success) {
      setLoading(false)
      return { success: false }
    }

    setLoading(false)
    return {
      success,
      data,
      error
    }
  }

  return {
    downloadSalesReport,
    getPowerBi,
    getStaticsBasics,
    getStaticsFilterBasics,
    getUnnefar,
    getUnnefarExcel,
    loading
  }
}
