import PropTypes from 'prop-types'
import React from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Avatar from '../../../components/Avatar'
import Header from '../../../components/Header'
import Loader from '../../../components/Loader'
import Sidebar from '../../../components/Sidebar'
import PowerBiScreen from '../../../modules/PowerBi/pages/PowerBiScreen'
import { useMenu } from '../../hooks/useMenu/useMenu'

/**
 *  @author ehernandez
 * @description Layout component.
 * @returns {React.component}
 */
const Layout = ({ children }) => {
  const navigate = useNavigate()
  const { menu } = useMenu()
  const location = useLocation()

  React.useEffect(() => {
    if (menu && menu.length > 0) {
      // if (!menu.find((item) => item.path === location.pathname)) {
      //   navigate(menu[0].path)
      // }
    }
  }, [menu])

  if (!menu) {
    return <Loader mode={'modal'} />
  }

  return (
    <div className='layout'>
      <Header>
        <Avatar />
      </Header>
      <Sidebar items={menu} onClickItem={(item) => navigate(item.path)} />
      <div className='layout__content tw-pl-[210px] tw-pt-[40px]'>
        {children}
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}

export default Layout
