import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../../../components/Paragraphs'
import { t } from 'i18next'
import 'moment/locale/es'
import useGetUsersInfo from '../../../../infrastructure/hooks/useUsersManagement'
import useUser from '../../../../infrastructure/hooks/useUser'
import { useProvinces } from '../../../../infrastructure/hooks/useProvinces/useProvinces'
import Select from './components/Select'
import { useLanguage } from '../../../../infrastructure/context/LangContext/LangContext'
import { useDispatch, useSelector } from 'react-redux'
import { actionSetEditUserField } from '../../../../infrastructure/redux/actions/userTab'

/**
 * UserEditField component.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.label - The label.
 * @param {string|number} props.value - The initial value.
 * @param {string} props.type - The input type.
 * @param {string} props.databaseField - The database field.
 * @param {boolean} props.optionsEnabled - Indicates if options are enabled.
 * @param {boolean} props.isPharmacy - Indicates if it is a pharmacy.
 * @param {Array} props.roles - The roles array.
 * @param {boolean} props.isSuperAdmin - Indicates if the user is a super admin.
 * @returns {JSX.Element|null} The UserEditField component JSX.Element or null if user permissions are not met.
 */
const UserEditField = (props) => {
  const {
    label,
    value: initialValue,
    type,
    databaseField,
    optionsEnabled,
    isPharmacy,
    roles,
    isSuperAdmin
  } = props

  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(initialValue)
  const [originalValue, setOriginalValue] = useState(initialValue)
  const [options, setOptions] = useState([])
  const { user: loggedInUser } = useUser()
  const { getClients } = useGetUsersInfo()
  const { getProvinces } = useProvinces()
  const { languages } = useLanguage()
  const dispatch = useDispatch()
  const { usersTabs, tabIndex } = useSelector((state) => state.userTabReducer)

  /**
   * Handles the save operation for a user field.
   * @async
   * @function handleSave
   * @returns {Promise<void>}
   */
  const handleSave = async () => {
    if (value === null) {
      return
    }

    let field
    switch (label) {
      case 'Tipo de cliente':
        field = 'client_type_id'
        break
      case 'Provincia':
        field = 'province_id'
        break
      case 'Provincia Colegiado':
        field = 'province_college_id'
        break
      case 'Idioma':
        field = 'language_id'
        break
      default:
        field = databaseField.includes('.')
          ? databaseField.split('.')[1]
          : databaseField
        break
    }

    let { changesUserField: updatedUserFieldChanges } = usersTabs[tabIndex]
    const existingFieldIndex = updatedUserFieldChanges.findIndex(
      (obj) => obj.field === field
    )
    const item = options.find((item) => item.name === value)

    if (existingFieldIndex === -1 && value !== originalValue) {
      /**
       * New value to be added.
       * @type {object}
       * @property {string} field - The field.
       * @property {*} value - The new value.
       * @property {*} originalValue - The original value.
       * @property {*} antValue - The previous value.
       * @property {*} item - The item.
       * @property {boolean} isPharmacy - Indicates if it is a pharmacy.
       * @property {string} label - The label.
       */
      const newValue = {
        field,
        value,
        originalValue,
        antValue: originalValue,
        item,
        isPharmacy,
        label,
        databaseField
      }
      updatedUserFieldChanges.push(newValue)
    } else {
      updatedUserFieldChanges = updatedUserFieldChanges.map((obj) => {
        if (obj.field === field && obj.antValue !== value) {
          return {
            ...obj,
            item: item,
            value,
            antValue: obj.value
          }
        }
        return obj
      })
    }

    dispatch(actionSetEditUserField(updatedUserFieldChanges))
  }

  /**
   * Handles the change event of the input/select field.
   * @param {string|boolean} value - The new value.
   * @returns {void}
   */
  const handleChange = (value) => {
    setValue(value)
  }

  /**
   * Checks if the logged-in user has the required permissions.
   * @returns {boolean} True if user permissions are met, false otherwise.
   */
  const checkUserLoggedPermissions = () => {
    return (
      roles &&
      loggedInUser &&
      loggedInUser.roles &&
      roles.some((role) => loggedInUser.roles.includes(role))
    )
  }

  /**
   * Executes an asynchronous effect function when the component mounts.
   * @function useEffect
   * @param {Function} effect - The effect function to execute.
   * @param {Array} dependencies - An array of dependencies that triggers the effect when changed.
   * @returns {void}
   */
  useEffect(async () => {
    setLoading(true)

    const dataFecthClients = async () => {
      const { data, success } = await getClients()
      if (success && data) {
        setOptions(data)
      }
      setLoading(false)
    }

    const dataFecthProvince = async () => {
      const { data, success } = await getProvinces()
      if (success && data) {
        setOptions(data)
        setValue(data.find((item) => item.id === value)?.id)
      }
      setLoading(false)
    }

    const dataFecthLang = async () => {
      setOptions(languages)
      setLoading(false)
    }

    switch (label) {
      case 'Tipo de cliente':
        dataFecthClients()
        break
      case /^Provincia/.test(label) && label:
        dataFecthProvince()
        break
      case 'Idioma':
        dataFecthLang()
        break
      default:
        setLoading(false)
        break
    }
  }, [])

  if (checkUserLoggedPermissions()) {
    return (
      <div className='tw-flex tw-items-center tw-w-full tw-relative'>
        <div className='tw-flex-1 tw-flex tw-gap-2'>
          <Paragraphs size='sm'>{t(label)}:</Paragraphs>
          <Paragraphs size='sm'>
            {optionsEnabled ? (
              <Select
                options={options}
                value={value}
                setValue={setValue}
                handleSave={handleSave}
              />
            ) : (
              <input
                type={type || 'text'}
                className={
                  type === 'checkbox'
                    ? 'tw-mt-1'
                    : 'tw-appearance-none tw-w-auto tw-bg-white tw-border tw-border-gray-300 tw-px-2 tw-rounded tw-shadow focus:tw-outline-none focus:tw-shadow-outline'
                }
                {...(type === 'checkbox' ? { checked: value } : { value })}
                onChange={(e) => {
                  if (type === 'checkbox') {
                    return handleChange(e.target.checked)
                  }
                  handleChange(e.target.value)
                }}
                onBlur={handleSave}
              />
            )}
          </Paragraphs>
        </div>
      </div>
    )
  } else {
    return null
  }
}

UserEditField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  databaseField: PropTypes.string,
  isPharmacy: PropTypes.bool,
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  optionsEnabled: PropTypes.number,
  roles: PropTypes.array,
  isSuperAdmin: PropTypes.bool,
  type: PropTypes.string
}

export default UserEditField
