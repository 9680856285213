import React from 'react'
import PropTypes from 'prop-types'
import { useDraggable } from '@dnd-kit/core'
import { createPortal } from 'react-dom'

function Draggable(props) {
  const { attributes, listeners, setNodeRef, transform, over, ...rest } =
    useDraggable({
      id: props.id,
      data: props.data
    })

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: over?.id ? 100 : 2
      }
    : undefined

  return (
    <button
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      className='tw-h-full'
    >
      {props.children}
    </button>
  )
}

Draggable.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  data: PropTypes.object
}

export { Draggable }
