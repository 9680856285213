import styled from 'styled-components'

const maxWidth = {
  sm: '320px',
  md: '768px',
  lg: '1024px'
}

export const ModalContainer = styled.div`
  max-width: ${(props) => maxWidth[props.size]};
  overflow: auto;
  position: fixed;
`
