/* eslint-disable */
import { createGlobalStyle } from 'styled-components'

function hexToRgbA(hex, type = 'bg') {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = `0x${c.join('')}`
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ',',
    )}, var(--tw-${type}-opacity))`
  }
  return hex
}

const GlobalStyle = createGlobalStyle`
.tw-bg-primary{
    background: ${({ theme }) => hexToRgbA(theme.primary)};
}
.hover\\:tw-bg-primary:hover{
  background: ${({ theme }) => hexToRgbA(theme.primary)};
}
.checked\\:tw-bg-primary:checked{
  background: ${({ theme }) => hexToRgbA(theme.primary)};
  border-color: ${({ theme }) => hexToRgbA(theme.primary, 'border')};
}
.tw-border-primary{
  border-color: ${({ theme }) => hexToRgbA(theme.primary, 'border')};
}

.hover\\:tw-border-primary:hover{
  border-color: ${({ theme }) => hexToRgbA(theme.primary)};
}
.tw-text-primary{
  color: ${({ theme }) => hexToRgbA(theme.primary, 'text')};
}
.tw-bg-secondary{
    background: ${({ theme }) => hexToRgbA(theme.secondary)};
}
.tw-bg-secondary-20{
  background: ${({ theme }) => hexToRgbA(theme.secondary20)};
}
.tw-border-secondary{
  border-color: ${({ theme }) => hexToRgbA(theme.secondary, 'border')};
}
.tw-text-secondary{
  color: ${({ theme }) => theme.secondary};
}
.hover\\:tw-text-secondary:hover{
  color: ${({ theme }) => theme.secondary};
}
.tw-border-tertiary{
  border-color: ${({ theme }) => theme.tertiary};
}
.tw-bg-tertiary{
  background: ${({ theme }) => theme.tertiary};
}
.tw-text-tertiary{
  color: ${({ theme }) => theme.tertiary};
}
.tw-border-quaternary{
  border-color: ${({ theme }) => theme.quaternary};
}
.tw-bg-quaternary{
  background: ${({ theme }) => theme.quaternary};
}
.tw-text-quaternary{
  color: ${({ theme }) => theme.quaternary};
}
`


export { GlobalStyle }