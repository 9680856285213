import { useLanguage } from '../../context/LangContext/LangContext'
import { getFormattedTranslations } from '../../utils/helperFunctions'
import useMutation from '../useMutation'
import { useState } from 'react'

export const useTranslationsCRUD = () => {
  const [fetchTranslations] = useMutation('translations')
  const [getTranslation] = useMutation('translations/by-key')
  const [loading, setLoading] = useState(false)
  const { languages, language: currentLang } = useLanguage()

  /**
   * Gets all translations from API and sets them in state
   * @async
   * @returns void
   */
  const getTranslations = async (params, filters, orderBy) => {
    setLoading(true)
    const { data, success } = await fetchTranslations({
      method: 'POST',
      addToURL: '/list',
      params,
      data: { ...(filters && filters), ...(orderBy && { ...orderBy }) }
    })
    if (!success) {
      setLoading(false)
      return { success: false, data }
    }

    const arrayFromKeys = getFormattedTranslations(data.items)
    const { pagination } = data

    setLoading(false)
    return { success, data: arrayFromKeys, pagination: pagination }
  }

  /**
   *
   * @param {object} translationObj
   *
   */
  const updateTranslation = async (translationId, translationValue) => {
    const { data, success } = await fetchTranslations({
      method: 'put',
      data: translationValue,
      addToURL: `/${translationId}`
    })
    if (!success) {
      return { success: false, data }
    }

    return { success, data: data?.data?.items }
  }

  const getTranslationKey = async (translationKey) => {
    const { data, success } = await getTranslation({
      method: 'POST',
      data: { key: translationKey }
    })
    if (!success) {
      return { success: false, data }
    }
    return { success, data }
  }

  /**
   * Translates the column definitions based on the provided language.
   * @function translate
   * @param {Array} columns - The array of column definitions.
   * @param {string} lang - The language code for translation.
   * @returns {Array} - The translated column definitions.
   */
  const translate = (columns) => {
    if (languages.length === 0) {
      return columns // No translations available, return original columns
    }

    const language = languages.find((item) => item.code === currentLang)
    if (!language || !language.translation) {
      return columns // Invalid language or missing translation, return original columns
    }

    const { translation } = language

    return columns.map((item) => {
      const translationItem = translation.find((t) => t.key === item.headerName)
      if (!translationItem) {
        return item // No translation found, return original column item
      }

      const { value: t } = translationItem
      return { ...item, headerName: t } // Return translated column item
    })
  }

  return {
    loading,
    updateTranslation,
    getTranslationKey,
    translate,
    getTranslations
  }
}
