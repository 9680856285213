import React, { useEffect } from 'react'
import Paragraphs from '../Paragraphs'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import {
  actionSetUserSessionExpired,
  actionUserLogout
} from '../../infrastructure/redux/actions/user'
import { useDispatch } from 'react-redux'

const SessionExpiredBlock = (props) => {
  const { t } = useTranslation()
  const [seconds, setSeconds] = React.useState(30)
  const dispatch = useDispatch()

  const redirectToLoginScreen = () => {
    dispatch(actionUserLogout())
    dispatch(actionSetUserSessionExpired(false))
    localStorage.removeItem('persist:root-areaprivada-backoffice')
    window.location.href = '/login'
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1)
    }, 1000)
    if (seconds === 0) {
      redirectToLoginScreen()
      setSeconds(30)
      clearInterval(interval)
      return
    }
    return () => clearInterval(interval)
  }, [seconds])

  return (
    <div
      className={
        'session-expired-block tw-fixed tw-top-0 tw-left-0 tw-flex tw-h-screen tw-w-screen tw-items-center tw-justify-center  tw-bg-black tw-bg-opacity-60'
      }
      style={{ zIndex: 999999 }}
    >
      <div
        className={
          'rounded tw-flex tw-h-[379px] tw-w-[588px] tw-items-center tw-justify-center tw-bg-quinary tw-p-16 tw-text-center tw-shadow-2xl'
        }
      >
        <div className={'tw-flex tw-flex-col tw-gap-8'}>
          <Paragraphs size={'2xl'} weight={'bold'}>
            {t('Tu sesión ha expirado.')}
          </Paragraphs>
          <Paragraphs size={'base'} className={'tw-text-left'}>
            {t(
              'En {{seconds}} segundos serás redirigido a la página de inicio de sesión.',
              { seconds }
            )}
          </Paragraphs>
          <Paragraphs size={'base'}>
            {t('Si no quieres esperar puedes pulsar el siguiente botón:')}
          </Paragraphs>
          <div className={'tw-flex tw-justify-center'}>
            <Button
              label={t('Iniciar sesión')}
              onClick={redirectToLoginScreen}
              className={'tw-mx-auto'}
              mode={'secondary'}
              uppercase
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionExpiredBlock
