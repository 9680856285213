import { useEffect, useState } from 'react'
import useMutation from '../useMutation'

export const useMenu = () => {
  const [getMenu] = useMutation('menu')
  const [menu, setMenu] = useState([])

  const fetchMenu = async () => {
    const response = await getMenu({
      method: 'GET'
    })
    const {
      data: {
        data: { menu }
      }
    } = response
    setMenu([...menu.filter((item) => item.active)])
  }

  useEffect(() => {
    fetchMenu()
  }, [])

  return {
    menu,
    fetchMenu
  }
}
