import { models } from 'powerbi-client'
import { EmbedType, PowerBIEmbed } from 'powerbi-client-react'
import React, { useEffect, useState } from 'react'
import useReports from '../../../infrastructure/hooks/useReports'

const ReportPowerBi = () => {
  const { getPowerBi } = useReports()
  const [report, setReport] = useState()
  const [reportConfig, setReportConfig] = useState({
    type: EmbedType.Report,
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
    id: '9299ee6e-afc8-4383-bf59-e344b4d8a1d3',
    pageName: 'ReportSectionbcc10cc6a21d23638017'
  })

  useEffect(() => {
    // fetch token and embed url
    const fetchData = async () => {
      const { success, data } = await getPowerBi()
      if (success) {
        setReportConfig({
          ...reportConfig,
          embedUrl: data.embed_url,
          accessToken: data.embed_token
        })
      }
    }
    fetchData()
  }, [])

  const eventHandlersMap = new Map([
    [
      'loaded',
      async function () {
        console.info('Report has loaded')
      }
    ],
    ['pageChanged', async function ({ detail }) {}],
    [
      'rendered',
      async function (e) {
        // rendered
      }
    ],
    ['error', function (event) {}]
  ])

  return (
    <PowerBIEmbed
      embedConfig={reportConfig}
      cssClassName={'report-style-class tw-w-full tw-h-[95dvh]'}
      getEmbeddedComponent={(embedObject) => {
        window.report = embedObject
        setReport(embedObject)
      }}
      eventHandlersMap={eventHandlersMap}
    />
  )
}

export { ReportPowerBi }

ReportPowerBi.propTypes = {}
