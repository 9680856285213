import { useDispatch, useSelector } from 'react-redux'
import {
  actionSetUserSessionExpired,
  actionSetUserSuccess
} from '../../redux/actions/user'
import { useMutation } from '../useMutation/useMutation'
import { useLanguage } from '../../context/LangContext/LangContext'
/**
 * get info user
 * @author   ehernandez
 * @param    {Boolean}   updateStorage   update redux storage
 * @return  {Object}
 */
export const useUser = () => {
  const { onChangeLanguage } = useLanguage()
  const { token, user, userSessionExpired } = useSelector(
    (state) => state.userReducer
  )
  const [fetchInfoUser] = useMutation('user/info')
  const [fetchUpdateUser] = useMutation('user')
  const [getUserRoles] = useMutation('user')
  const [fetchCreatePharmacy] = useMutation('sign-up-pharmacy')

  const idLogged = !!user?.id
  // isApproved ---> indica si el usuario tiene farmacia aprovada desde bbdd
  const isApproved = !!user?.pharmacy?.approved

  const dispatch = useDispatch()

  /**
   *  get info user
   * @author   ehernandez
   */
  const getInfoUser = async () => {
    const { success, data } = await fetchInfoUser({
      method: 'get'
    })
    if (!success) {
      return {
        success: false
      }
    }

    const { data: roles, success: ok } = await getUserRoles({
      method: 'get',
      addToURL: `/${user.id}/roles`
    })

    if (success && ok) {
      dispatch(
        actionSetUserSuccess({
          ...data?.data?.item,
          roles: roles?.data?.items
        })
      )
      dispatch(actionSetUserSessionExpired(false))
      onChangeLanguage(data?.data?.item?.language?.code)

      return {
        success,
        data: data?.data?.item
      }
    }
  }

  /**
   *  create pharmacy
   * @author   ehernandez
   * @param {Object} values data to create pharmacy
   */
  const cretatePharmacy = async (values) => {
    const { success } = await fetchCreatePharmacy({
      method: 'post',
      data: values
    })
    if (success) {
      getInfoUser()
    }
  }

  /**
   *  update user
   * @author   ehernandez
   * @param {Object} values data to update user
   */
  const updateUser = async (values) => {
    const { success, data } = await fetchUpdateUser({
      method: 'put',
      data: {
        ...values
      }
    })
    if (success) {
      dispatch(actionSetUserSuccess(data?.data?.item))
      return {
        success,
        data: data?.data?.item
      }
    }

    return {
      success: false
    }
  }

  return {
    token,
    user,
    userSessionExpired,
    getInfoUser,
    idLogged,
    cretatePharmacy,
    isApproved,
    updateUser
  }
}
