import { t } from 'i18next'
import moment from 'moment'

const filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const slicedDate = moment(cellValue).format('YYYY-MM-DD')
    const dateAsString = new Date(slicedDate).toISOString()
    if (dateAsString == null) return -1
    const dateParts = dateAsString?.split('T')[0].split('-')
    const cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2])
    )
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1
    }
  },
  browserDatePicker: true
}

export const columnDefs = [
  {
    headerName: 'ID',
    width: 110,
    field: 'id',
    databaseField: 'id',
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'Creación del usuario',
    databaseField: 'created_at',
    field: 'created_at',
    filter: 'agDateColumnFilter',
    sort: 'desc',
    cellRenderer: (data) => {
      if (!data.value) return ''
      return moment(data.value).format('DD-MM-YYYY, HH:mm:ss')
    }
  },
  {
    headerName: 'Email de acceso',
    field: 'email',
    headerTooltip: 'Correo electrónico del usuario',
    databaseField: 'email',
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Nombre',
    field: 'name',
    databaseField: 'name',
    filter: 'agTextColumnFilter',
    width: 150
  },
  {
    headerName: 'Apellidos',
    databaseField: 'surnames',
    field: 'surnames',
    filter: 'agTextColumnFilter',
    width: 150
  },
  {
    headerName: 'Nif del usuario',
    databaseField: 'nif',
    width: 150,
    field: 'nif',
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Tipo de cliente',
    field: 'client_type__name',
    width: 150,
    databaseField: 'client_type.name',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: ['Fedefarma', 'Vadefarma'],
      defaultToNothingSelected: true
    }
  },
  {
    headerName: 'Email verificado',
    field: 'email_verified_at',
    databaseField: 'email_verified_at',
    filter: 'agDateColumnFilter',
    cellRenderer: (data) => {
      if (!data.value) return ''
      return moment(data.value).format('DD-MM-YYYY, HH:mm:ss')
    },
    hide: true
  },
  {
    headerName: 'Código de farmacia',
    field: 'pharmacy__username',
    width: 150,
    databaseField: 'pharmacy.username',
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Farmacia',
    field: 'pharmacy__name',
    headerTooltip: 'Nombre de la farmacia',
    databaseField: 'pharmacy.name',
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Email de contacto',
    field: 'pharmacy__metadata__email_contact',
    headerTooltip: 'Correo electrónico de contacto',
    databaseField: 'pharmacy.metadata.email_contact',
    filter: 'agTextColumnFilter',
    hide: true
  },
  {
    headerName: 'Email de pedidos',
    field: 'pharmacy__metadata__email_orders',
    headerTooltip: 'Correo electrónico de pedidos',
    databaseField: 'pharmacy.metadata.email_order',
    filter: 'agTextColumnFilter',
    hide: true
  },
  {
    headerName: 'Email de facturación',
    field: 'pharmacy__metadata__email_billing',
    headerTooltip: 'Correo electrónico de facturación',
    databaseField: 'pharmacy.metadata.email_billing',
    filter: 'agTextColumnFilter',
    hide: true
  },
  {
    headerName: 'Grupo',
    field: 'pharmacy__cooperative__name',
    databaseField: 'pharmacy.cooperative.name',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [],
      defaultToNothingSelected: true
    }
  },
  {
    headerName: 'Cooperativa',
    field: 'pharmacy__unnefars__nombreCooperativa',
    databaseField: 'pharmacy.unnefars.nombreCooperativa',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [],
      defaultToNothingSelected: true
    }
  },
  {
    headerName: 'Farmacia CIF/NIF',
    field: 'pharmacy__cif',
    databaseField: 'pharmacy.cif',
    filter: 'agTextColumnFilter',
    width: 150
  },
  {
    headerName: 'Estado Farmacia',
    field: 'pharmacy__status',
    width: 130,
    databaseField: 'pharmacy.status',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: ['approved', 'not_approved', 'pending'],
      defaultToNothingSelected: true
    }
  },
  {
    headerName: 'Bloqueo Farmacia',
    field: 'pharmacy__blocked',
    databaseField: 'pharmacy.blocked',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: ['Bloqueada', 'Activa'],
      defaultToNothingSelected: true
    },
    cellRenderer: (data) => {
      if (data.value) return t('Bloqueada')
      return t('Activa')
    },
    hide: true
  },
  {
    headerName: 'Rol',
    field: 'role__role_name',
    databaseField: 'role.name',
    hide: true,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Idioma',
    field: 'language__name',
    databaseField: 'language.name',
    hide: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      newRowsAction: 'keep',
      values: ['Catalá', 'Español', 'Euskera', 'Galego'],
      defaultToNothingSelected: true,
      suppressSorting: true,
      suppressMiniFilter: true
    }
  },
  {
    headerName: 'Número de colegiado',
    field: 'college_number',
    databaseField: 'college_number',
    hide: true,
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'Teléfono móvil',
    field: 'mobile',
    databaseField: 'mobile',
    hide: true,
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'Teléfono fijo',
    field: 'phone',
    databaseField: 'phone',
    hide: true,
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'Fax',
    field: 'fax',
    databaseField: 'fax',
    hide: true,
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'Id de la farmacia',
    field: 'pharmacy__id',
    databaseField: 'pharmacy.id',
    hide: true,
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'Actualización farmacia',
    field: 'pharmacy__updated_at',
    databaseField: 'pharmacy.status_updated_at',
    hide: true,
    filter: 'agDateColumnFilter',
    cellRenderer: (data) => {
      if (!data.value) return ''
      return moment(data.value).format('DD-MM-YYYY, HH:mm:ss')
    },
    filterParams: filterParams
  },
  {
    headerName: 'Dirección',
    field: 'pharmacy__address',
    databaseField: 'pharmacy.address',
    hide: true,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Provincia',
    field: 'pharmacy__province',
    databaseField: 'pharmacy.province',
    hide: true,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Código postal',
    field: 'pharmacy__postalcode',
    databaseField: 'pharmacy.cp',
    hide: true,
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'Ciudad',
    field: 'pharmacy__city',
    databaseField: 'pharmacy.city',
    hide: true,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Id SEPA',
    field: 'pharmacy__sepa__id',
    databaseField: 'pharmacy.sepa.id',
    hide: true,
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'SWIFT',
    field: 'pharmacy__sepa__swift',
    databaseField: 'pharmacy.sepa.swift',
    hide: true,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'IBAN',
    field: 'pharmacy__sepa__iban',
    databaseField: 'pharmacy.sepa.iban',
    hide: true,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Dirección SEPA',
    field: 'pharmacy__sepa_address',
    databaseField: 'pharmacy.sepa.address',
    hide: true,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Ciudad SEPA',
    field: 'pharmacy__sepa__city',
    databaseField: 'pharmacy.sepa.city',
    hide: true,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Código postal SEPA',
    field: 'pharmacy__sepa__postalcode',
    databaseField: 'pharmacy.sepa.postalcode',
    hide: true,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Provincia SEPA',
    field: 'pharmacy__sepa__province',
    databaseField: 'pharmacy.sepa.province',
    hide: true,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'País SEPA',
    field: 'pharmacy__sepa__country',
    databaseField: 'pharmacy.sepa.country',
    hide: true,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Actualización SEPA',
    field: 'pharmacy__sepa__updated_at',
    databaseField: 'pharmacy.sepa.updated_at',
    hide: true,
    filter: 'agDateColumnFilter',
    cellRenderer: (data) => {
      if (!data.value) return ''
      return moment(data.value).format('DD-MM-YYYY, HH:mm:ss')
    },
    filterParams: filterParams
  },
  {
    headerName: 'Estado Usuario',
    field: 'user_status',
    filter: 'agSetColumnFilter',
    width: 190,
    hide: true,
    filterParams: {
      values: ['Activo', 'Inactivo'],
      defaultToNothingSelected: true,
      suppressSorting: true,
      suppressMiniFilter: true
    },
    cellRenderer: (params) => {
      return params.data.deleted_at ? 'Inactivo' : 'Activo'
    }
  },
  {
    headerName: 'Fecha de baja',
    field: 'deleted_at',
    databaseField: 'deleted_at',
    filter: 'agDateColumnFilter',
    width: 190,
    hide: true,
    cellRenderer: (data) => {
      if (!data.value) return ''
      return moment(data.value).format('DD-MM-YYYY, HH:mm:ss').toString()
    }
  }
]

export const columnDefsLogs = [
  {
    headerName: 'Fecha/Hora',
    field: 'updated_at',
    filter: 'agDateColumnFilter',
    width: 190,
    cellRenderer: (data) => {
      if (!data.value) return ''
      return moment(data.value).format('DD-MM-YYYY, HH:mm:ss')
    },
    filterParams: filterParams
  },
  {
    headerName: 'Acción',
    field: 'action',
    filter: 'agSetColumnFilter',
    width: 265
  },
  {
    headerName: 'Entorno',
    field: 'origin',
    filter: 'agSetColumnFilter',
    width: 200,
    cellRenderer: (data) => {
      const env = location.href.includes('backoffice.vadefarma.com')
        ? 'production'
        : 'develop'

      const origin = data.value
      if (env === 'production') {
        switch (true) {
          case origin === 'https://www.vadefarma.com':
            return 'Vadefarma'
          case origin === 'https://www.fedefarmaclients.com':
            return 'Fedefarmaclients'
          case origin === 'https://backoffice.vadefarma.com':
            return 'Backoffice'
          default:
            return 'Development'
        }
      }

      switch (true) {
        case origin === 'https://front-vadefarma-dev.enpreproduccion.com':
          return 'Vadefarma'
        case origin ===
          'https://front-fedefarmaclients-dev.enpreproduccion.com':
          return 'Fedefarmaclients'
        case origin ===
          'https://backoffice-areaprivada-dev.enpreproduccion.com':
          return 'Backoffice'
        default:
          return 'Development'
      }
    }
  },
  {
    headerName: 'Ip de origen',
    width: 150,
    field: 'visitor_ip',
    filter: 'agSetColumnFilter'
  },
  {
    headerName: 'Descripción',
    field: 'description',
    flex: 1,
    filter: 'agTextColumnFilter'
  }
]

export const defaultColDef = {
  sortable: true,
  resizable: true,
  // flex: 1,
  // filter: 'agTextColumnFilter',
  floatingFilter: true,
  tooltipShowDelay: 10,
  enableRowGroup: true
}

export const userDetailFields = {
  userGeneralData: [
    {
      label: 'Rol',
      databaseField: 'role.name',
      field: 'role'
    },
    {
      label: 'Id de usuario',
      databaseField: 'id',
      field: 'id'
    }
  ],
  userPersonalData: [
    {
      label: 'Id de usuario',
      databaseField: 'id',
      field: 'id'
    },
    {
      label: 'Creación del usuario',
      databaseField: 'created_at',
      field: 'user_created_at'
    },
    {
      label: 'Tipo de cliente',
      field: 'client_type',
      databaseField: 'client_type.name',
      roles: ['Support', 'Admin', 'SuperAdmin'],
      edit: true,
      optionsEnabled: true
    },
    {
      label: 'Nombre',
      databaseField: 'name',
      field: 'name',
      roles: ['Support', 'Admin', 'SuperAdmin'],
      edit: true
    },
    {
      label: 'Apellidos',
      databaseField: 'surnames',
      field: 'surnames',
      roles: ['Support', 'Admin', 'SuperAdmin'],
      edit: true
    },
    {
      label: 'NIF',
      databaseField: 'nif',
      field: 'nif',
      roles: ['Support', 'Admin', 'SuperAdmin'],
      edit: false
    },
    {
      label: 'Colegiado',
      databaseField: 'college_number',
      field: 'college_number',
      roles: ['Support', 'Admin', 'SuperAdmin'],
      edit: false
    },
    {
      label: 'Provincia Colegiado',
      databaseField: 'college_province',
      field: 'college_province',
      roles: ['Support', 'Admin', 'SuperAdmin'],
      edit: false,
      optionsEnabled: true
    },
    {
      label: 'Email de acceso',
      databaseField: 'email',
      field: 'email'
    },
    {
      label: 'Cooperativa',
      databaseField: 'pharmacy.cooperative.name',
      field: 'cooperative'
    },
    {
      label: 'Email verificado',
      databaseField: 'email_verified_at',
      field: 'email_verified_at',
      hide: true
    }
  ],
  userContact: [
    {
      label: 'Email contacto',
      databaseField: 'pharmacy.metadata.email_contact',
      field: 'email'
    },
    {
      label: 'Email pedidos',
      databaseField: 'pharmacy.metadata.email_order',
      field: 'email'
    },
    {
      label: 'Email facturación',
      databaseField: 'pharmacy.metadata.email_billing',
      field: 'email'
    },
    {
      label: 'Móvil',
      databaseField: 'mobile',
      field: 'mobile',
      roles: ['Support', 'Admin', 'SuperAdmin'],
      edit: true
    },
    {
      label: 'Teléfono',
      databaseField: 'phone',
      field: 'phone',
      roles: ['Support', 'Admin', 'SuperAdmin'],
      edit: true
    },
    {
      label: 'Fax',
      databaseField: 'fax',
      field: 'fax',
      roles: ['Support', 'Admin', 'SuperAdmin'],
      edit: true
    },
    {
      label: 'Idioma',
      databaseField: 'language.name',
      field: 'language',
      roles: ['Support', 'Admin', 'SuperAdmin'],
      edit: true,
      optionsEnabled: true
    }
  ],
  userPharmacyData: [
    {
      label: 'Estado',
      databaseField: 'pharmacy.status',
      field: 'status'
    },
    {
      label: 'Código de farmacia',
      field: 'username',
      databaseField: 'pharmacy.username'
    },
    {
      label: 'Ultimo estado',
      databaseField: 'pharmacy.status_updated_at',
      field: 'status_updated_at'
    },
    {
      label: 'Nombre',
      databaseField: 'pharmacy.name',
      field: 'name'
    },
    {
      label: 'CIF/NIF',
      databaseField: 'pharmacy.cif',
      field: 'cif'
    },
    {
      label: 'Dirección',
      databaseField: 'pharmacy.address',
      field: 'name',
      edit: true,
      roles: ['Support', 'Admin', 'SuperAdmin']
    },
    {
      label: 'Ciudad',
      databaseField: 'pharmacy.city',
      field: 'city',
      edit: true,
      roles: ['Support', 'Admin', 'SuperAdmin']
    },
    {
      label: 'Provincia',
      databaseField: 'pharmacy.province_id.name',
      field: 'province',
      roles: ['Support', 'Admin', 'SuperAdmin'],
      edit: true,
      optionsEnabled: true
    },
    {
      label: 'Código postal',
      databaseField: 'pharmacy.cp',
      field: 'cp',
      roles: ['Support', 'Admin', 'SuperAdmin'],
      edit: true
    }
  ],
  userPharmacySepaData: [
    {
      label: 'Estado',
      databaseField: 'pharmacy.sepa.status',
      field: 'status'
    },
    {
      label: 'Ultimo estado',
      databaseField: 'pharmacy.sepa.status_last_updated',
      field: 'status_last_updated'
    },
    {
      label: 'SWIFT',
      databaseField: 'pharmacy.sepa.swift',
      field: 'swift'
    },
    {
      label: 'IBAN',
      databaseField: 'pharmacy.sepa.iban',
      field: 'iban'
    },
    {
      label: 'Titular',
      databaseField: 'pharmacy.sepa.name',
      field: 'name'
    }
  ],
  userUnnefarData: [
    {
      label: 'Código de farmacia',
      databaseField: 'codigoFarmaciaCooperativa',
      field: 'codigoFarmaciaCooperativa'
    },
    {
      label: 'Tipo',
      databaseField: 'esPrincipal',
      field: 'esPrincipal'
    },
    {
      label: 'Cooperativa',
      databaseField: 'codigoCooperativa',
      field: 'codigoCooperativa'
    },
    {
      label: 'Almacén',
      databaseField: 'codigoAlmacen',
      field: 'codigoAlmacen'
    },
    {
      label: 'Reparto',
      databaseField: 'reparto',
      field: 'reparto'
    },
    {
      label: 'Ruta',
      databaseField: 'ruta',
      field: 'ruta'
    }
  ]
}
