export const bannerColumns = [
  {
    colId: 'image',
    headerName: 'Imágen',
    field: 'image',
    sortable: true,
    cellRenderer: 'imageCellRenderer'
  },
  {
    colId: 'title',
    field: 'title',
    filter: 'agTextColumnFilter',
    headerName: 'Título',
    sortable: true,
    floatingFilter: true
  },
  {
    colId: 'description',
    field: 'description',
    filter: 'agTextColumnFilter',
    headerName: 'Descripción',
    sortable: true,
    floatingFilter: true
  },
  {
    colId: 'location',
    field: 'location',
    filter: 'agSetColumnFilter',
    headerName: 'Ubicación',
    sortable: true,
    valueGetter: 'data.location.location',
    floatingFilter: true,
    filterParams: {
      values: ['Home']
    }
  },
  {
    colId: 'position',
    field: 'position',
    filter: 'agTextColumnFilter',
    headerName: 'Posición',
    sortable: true,
    editable: false,
    floatingFilter: true
  },
  {
    colId: 'type',
    field: 'type',
    filter: 'agSetColumnFilter',
    headerName: 'Tipo',
    sortable: true,
    floatingFilter: true,
    filterParams: {
      values: ['Banner', 'Slide']
    }
  },
  {
    colId: 'active',
    field: 'active',
    filter: 'agSetColumnFilter',
    headerName: 'Activo',
    sortable: true,
    valueGetter: 'data.active ? "Activo" : "Inactivo"',
    cellRenderer: 'activeSlideAggrid',
    editable: true,
    floatingFilter: true,
    filterParams: {
      values: ['Activo', 'Inactivo']
    }
  }
]
