import {
  ADD_USER_TAB,
  CLEAR_EDIT_USER_FIELD,
  CLOSE_TAB,
  SET_EDITING_USER_FIELD,
  SET_EDIT_USER_FIELD,
  SET_NAME,
  SET_TAB_INDEX,
  SET_USER_DATA
} from '../constants/userTab'

const initialState = {
  usersTabs: [
    {
      id: 0,
      name: 'Listado de usuarios',
      userId: null,
      changesUserField: [],
      isEditing: false,
      userData: {}
    }
  ],
  tabIndex: 0
}
/**

Reducer function to handle user tabs state.
@param {Object} state - The current state.
@param {Object} action - The action being dispatched.
@param {string} action.type - The type of action being dispatched.
@param {Object} [action.user] - The user object being added to a tab.
@param {number} [action.index] - The index of the tab being closed or set.
*/
const userTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_TAB: {
      const user = action.user
      const { usersTabs } = state

      const existingTabIndex = usersTabs.findIndex(
        (tab) => tab.userId === user.id
      )

      if (existingTabIndex !== -1) {
        return {
          ...state,
          tabIndex: existingTabIndex
        }
      }

      const newTab = {
        id: usersTabs.length,
        userId: user.id,
        name: `${user.name} ${user.surnames}`,
        changesUserField: [],
        isEditing: false
      }

      return {
        ...state,
        usersTabs: [...usersTabs, newTab],
        tabIndex: usersTabs.length
      }
    }

    case CLOSE_TAB: {
      const userId = action.userId
      const { usersTabs, tabIndex: activeTabIndex } = state
      const tabIndex = usersTabs.findIndex((tab) => tab?.userId === userId)
      const updatedTabs = usersTabs.filter((tab) => tab.userId !== userId)

      return {
        ...state,
        tabIndex: activeTabIndex > tabIndex ? activeTabIndex - 1 : 0,
        usersTabs: updatedTabs
      }
    }

    case SET_TAB_INDEX: {
      const { index } = action

      return {
        ...state,
        tabIndex: index
      }
    }

    case SET_EDIT_USER_FIELD: {
      const changesUserField = action.changesUserField
      const { tabIndex, usersTabs } = state

      const updatedTab = {
        ...usersTabs[tabIndex],
        changesUserField
      }

      const updatedTabs = [...usersTabs]
      updatedTabs[tabIndex] = updatedTab

      return {
        ...state,
        usersTabs: updatedTabs
      }
    }

    case SET_EDITING_USER_FIELD: {
      const isEditing = action.isEditing
      const { usersTabs } = state

      const userId = action.userId
      const tabIndex = usersTabs.findIndex((tab) => tab?.userId === userId)

      const updatedTab = {
        ...usersTabs[tabIndex],
        isEditing
      }

      const updatedTabs = [...usersTabs]
      updatedTabs[tabIndex] = updatedTab

      return {
        ...state,
        usersTabs: updatedTabs
      }
    }

    case SET_USER_DATA: {
      const userData = action.userData
      const { usersTabs } = state

      const userId = action.userId
      const tabIndex = usersTabs.findIndex((tab) => tab?.userId === userId)

      const updatedTab = {
        ...usersTabs[tabIndex],
        userData
      }

      const updatedTabs = [...usersTabs]
      updatedTabs[tabIndex] = updatedTab

      return {
        ...state,
        usersTabs: updatedTabs
      }
    }

    case SET_NAME: {
      const name = action.name
      const { usersTabs } = state

      const userId = action.userId
      const tabIndex = usersTabs.findIndex((tab) => tab?.userId === userId)

      const updatedTab = {
        ...usersTabs[tabIndex],
        name
      }

      const updatedTabs = [...usersTabs]
      updatedTabs[tabIndex] = updatedTab

      return {
        ...state,
        usersTabs: updatedTabs
      }
    }

    default:
      return state
  }
}

export default userTabReducer
