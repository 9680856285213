import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../Logo/Logo'
import { FadeLogo } from './styles'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line require-jsdoc
const FadeContentLogo = ({ width, withSpinner }) => {
  const { t } = useTranslation()
  return (
    <FadeLogo className='tw-flex tw-justify-center tw-items-center'>
      {withSpinner && (
        <div className='tw-flex tw-justify-center tw-items-center tw-self-center tw-pr-2'>
          <div
            className='tw-spinner-border tw-animate-spin tw-inline-block tw-w-4 tw-h-4 tw-border-4 tw-rounded-full'
            role='status'
          >
            <span className='tw-visually-hidden'>{t('Cargando...')}</span>
          </div>
        </div>
      )}
      <Logo width={width} />
    </FadeLogo>
  )
}

FadeContentLogo.propTypes = {
  width: PropTypes.number,
  withSpinner: PropTypes.bool
}

// eslint-disable-next-line require-jsdoc
const Loader = ({ mode, transparent, width }) => {
  const bg = transparent ? 'transparent' : 'tw-bg-white tw-bg-opacity-20'
  if (mode === 'page') {
    return (
      <div
        className={`loader tw-fixed tw-w-screen tw-h-screen tw-flex tw-top-0 tw-left-0 tw-items-center tw-justify-center ${bg}  tw-z-[9999] `}
        style={{ zIndex: 9999 }}
      >
        <FadeContentLogo width={width || 180} />
      </div>
    )
  }
  if (mode === 'modal') {
    return (
      <div
        className={`loader tw-absolute tw-w-full tw-h-full tw-flex tw-top-0 tw-left-0 tw-items-center tw-justify-center tw-bg-white tw-bg-opacity-50 tw-z-[999] tw-w-full tw-h-full ${bg}`}
      >
        <FadeContentLogo width={width || 100} />
      </div>
    )
  }

  return (
    <div className='loader tw-flex'>
      <FadeContentLogo width={width || 100} withSpinner={mode === 'spinner'} />
    </div>
  )
}

Loader.propTypes = {
  mode: PropTypes.oneOf(['modal', 'default', 'page']),
  transparent: PropTypes.bool,
  width: PropTypes.number
}

export default Loader
