import Header from './Header'
import Container from './Container'
import Body from './Body'
const Page = {
  Header,
  Container,
  Body
}

export default Page
