import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Page from '../../../../components/Page'
import useTranslationsCRUD from '../../../../infrastructure/hooks/useTranslationsCRUD'
import Button from '../../../../components/Button'
import Loader from '../../../../components/Loader'
import useFlashMessage from '../../../../infrastructure/hooks/useFlashMessage'

const TranslationEdit = () => {
  const { showMessage } = useFlashMessage()
  const navigate = useNavigate()
  const { updateTranslation, getTranslationKey } = useTranslationsCRUD()
  const [translation, setTranslation] = useState({
    key: '',
    data: []
  })
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const { t } = useTranslation()

  /**
   * UseEffect to update the translation data
   * Fetches the translation data based on the location state and sets the initial state.
   * @returns void
   */
  useEffect(() => {
    const fetchTranslationData = (data) => {
      setLoading(true)
      data && data.key && getTranslation(data.key)
      setTranslation({
        ...translation,
        key: data.key
      })
    }

    if (location.state && location.state.data) {
      fetchTranslationData(location.state.data)
    }
  }, [location])

  /**
   * Gets translation by key
   * @param {string} key
   * @returns
   */
  const getTranslation = async (key) => {
    const { success, data } = await getTranslationKey(key)
    if (!success) {
      return
    }
    setTranslation({
      key: key,
      data
    })
    setLoading(false)
  }

  /**
   * Gets value by code from translation
   * @param {string} code
   * @returns string
   */
  const getValue = (code) => {
    const value = translation?.data?.find((item) => item.code === code)?.value
    return value
  }

  /**
   * Sets value by code from translation
   * @param {string} code
   * @param {string} value
   * @returns void
   */
  const handleTranslationChange = (code, value) => {
    const data = translation.data.map((item) => {
      if (item.code === code) {
        return {
          ...item,
          value
        }
      }
      return item
    })
    setTranslation({
      ...translation,
      data
    })
  }

  /**
   * Updates translations
   * @async
   * @returns void
   */
  const updateTranlations = async () => {
    setLoading(true)
    translation.data.forEach(async (item) => {
      await updateTranslation(item.id, {
        value: item.value
      })
    })

    showMessage({
      type: 'success',
      message: t('Traducciones actualizadas correctamente')
    })

    setTimeout(() => {
      setLoading(false)
      navigate(-1, {
        state: { update: true }
      })
    }, 1000)
  }

  return (
    <Page.Container>
      <Page.Header title={`${t('Editar traducción')}`}></Page.Header>
      <Page.Body>
        {loading && <Loader mode='modal' />}
        <div className='tw-grid tw-grid-cols-4 tw-grid-rows-2 tw-gap-4'>
          {/* CAT */}
          <div className='tw-col-span-2'>
            <div className='tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-lg tw-p-2'>
              <h3 className='tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900'>
                {t('Catalán')}
              </h3>
              <div className='tw-mt-1'>
                <textarea
                  rows='4'
                  name='cat_value'
                  id='cat_value'
                  value={getValue('ca')}
                  onChange={(e) =>
                    handleTranslationChange('ca', e.target.value)
                  }
                  className='tw-p-1 focus:tw-outline-none focus:tw-border-blue-500 tw-border-b-2 tw-border-gray-300 tw-w-full tw-text-gray-700 tw-bg-transparent tw-rounded'
                />
              </div>
            </div>
          </div>
          {/* ES */}
          <div className='tw-col-span-2'>
            <div className='tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-lg tw-p-2'>
              <h3 className='tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900'>
                {t('Castellano')}
              </h3>
              <div className='tw-mt-1'>
                <textarea
                  rows='4'
                  type='text'
                  name='es_value'
                  id='es_value'
                  value={getValue('es')}
                  onChange={(e) =>
                    handleTranslationChange('es', e.target.value)
                  }
                  className='tw-p-1 focus:tw-outline-none focus:tw-border-blue-500 tw-border-b-2 tw-border-gray-300 tw-w-full tw-text-gray-700 tw-bg-transparent tw-rounded'
                />
              </div>
            </div>
          </div>
          {/* GA */}
          <div className='tw-col-span-2'>
            <div className='tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-lg tw-p-2'>
              <h3 className='tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900'>
                {t('Gallego')}
              </h3>
              <div className='tw-mt-1'>
                <textarea
                  rows='4'
                  type='text'
                  name='ga_value'
                  id='ga_value'
                  value={getValue('ga')}
                  onChange={(e) =>
                    handleTranslationChange('ga', e.target.value)
                  }
                  className='tw-p-1 focus:tw-outline-none focus:tw-border-blue-500 tw-border-b-2 tw-border-gray-300 tw-w-full tw-text-gray-700 tw-bg-transparent tw-rounded'
                />
              </div>
            </div>
          </div>
          {/* EU */}
          <div className='tw-col-span-2'>
            <div className='tw-bg-white tw-shadow tw-overflow-hidden sm:tw-rounded-lg tw-p-2'>
              <h3 className='tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900'>
                {t('Euskera')}
              </h3>
              <div className='tw-mt-1'>
                <textarea
                  rows='4'
                  type='text'
                  name='eu_value'
                  id='eu_value'
                  value={getValue('eu')}
                  onChange={(e) =>
                    handleTranslationChange('eu', e.target.value)
                  }
                  className='tw-p-1 focus:tw-outline-none focus:tw-border-blue-500 tw-border-b-2 tw-border-gray-300 tw-w-full tw-text-gray-700 tw-bg-transparent tw-rounded'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='tw-flex tw-justify-end'>
          <div className='tw-flex tw-mx-8 tw-mt-4'>
            <Button
              mode={'primary'}
              uppercase
              label={t('Atrás')}
              onClick={() => navigate('/translations')}
              disabled={loading}
            />
          </div>
          {/* save button */}
          <div
            className='tw-flex tw-justify-end tw-mt-4'
            style={
              loading
                ? { pointerEvents: 'none', opacity: 0.5, cursor: 'not-allowed' }
                : {}
            }
          >
            <Button
              mode={'primary'}
              uppercase
              label={t('Guardar')}
              onClick={() => updateTranlations()}
              disabled={loading}
            />
          </div>
        </div>
      </Page.Body>
    </Page.Container>
  )
}

export default TranslationEdit
