import React from 'react'
import Page from '../../../../components/Page'
import { useTranslation } from 'react-i18next'
import TranslationsAggrid from '../../components/TranslationsAggrid'

const TranslationsScreen = () => {
  const { t } = useTranslation()

  return (
    <Page.Container>
      <Page.Header title={t('Traducciones')}></Page.Header>
      <Page.Body>
        <TranslationsAggrid />
      </Page.Body>
    </Page.Container>
  )
}

export default TranslationsScreen
