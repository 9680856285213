import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

export const ImageCellRenderer = forwardRef((props, ref) => {
  const { value } = props
  return React.createElement('img', {
    ref: ref,
    src: value,
    alt: 'banner',
    style: {
      margin: 'auto',
      maxHeight: '100%'
    }
  })
})

ImageCellRenderer.propTypes = {
  value: PropTypes.any
}

ImageCellRenderer.displayName = 'ImageCellRenderer'
