import { useEffect, useState } from 'react'
import useMutation from '../useMutation'

export const useProvinces = () => {
  const [fetchProvinces] = useMutation('provinces')
  const [fetchPharmacyProvince] = useMutation('pharmacies')

  const getProvinces = async () => {
    const { success, data, error } = await fetchProvinces({
      method: 'GET'
    })
    return { success, data: data.data?.items || [], error }
  }

  const setPharmacyProvince = async (pharmacyData) => {
    const { success, data, error } = await fetchPharmacyProvince({
      method: 'PUT',
      data: pharmacyData,
      addToURL: `/${pharmacyData?.id}`
    })
    return { success, data, error }
  }

  return {
    getProvinces
  }
}
