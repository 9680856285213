import { DndContext } from '@dnd-kit/core'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Button'
import { Carousel } from '../../../../components/Carousel/Carousel'
import Loader from '../../../../components/Loader'
import Paragraphs from '../../../../components/Paragraphs'
import { Draggable } from '../../../../infrastructure/context/DragAndDropContext/Draggable'
import { Droppable } from '../../../../infrastructure/context/DragAndDropContext/Droppable'
import useBanners from '../../../../infrastructure/hooks/useBanners'
import useFlashMessage from '../../../../infrastructure/hooks/useFlashMessage'
import { bannersTypes } from '../../constants/constants'
import './styles.css'

const PreviewView = ({
  bannerList,
  defaultLayoutForAll,
  imageList,
  layoutId,
  layoutToAssign
}) => {
  const { t } = useTranslation()
  const { showMessage } = useFlashMessage()
  const { reorderBanners, loading } = useBanners()
  const [saving, setSaving] = useState(false)
  const [canDrop, setCanDrop] = useState(false)
  const [dragging, setDragging] = useState(null)
  const [droppedSlides, setDroppedSlides] = useState([])
  const [initialSlides, setInitialSlides] = useState([])
  const [isOverList, setIsOverList] = useState(false)
  const containerRef = useRef(null)
  const saved = useRef(false)

  useEffect(() => {
    if (
      layoutToAssign !== null &&
      layoutToAssign.value !== bannersTypes.default &&
      (bannerList === null || bannerList?.length === 0)
    ) {
      setDroppedSlides([])
      setInitialSlides([])
      return
    }

    if (bannerList === null || bannerList?.length === 0) {
      return
    }

    // destructure banner key in bannerlist
    const formatedList = bannerList.map((item) => {
      item = {
        ...item,
        ...item.banner
      }
      delete item.banner
      return item
    })

    const banners = formatedList.filter(
      (item) =>
        (item.type === 'banner' || item.type === 'banner 2') &&
        item.position &&
        item.active
    )
    const bannerXl = formatedList.filter(
      (item) => item.type === 'banner XL' && item.position && item.active
    )[0]
    const slides = formatedList.filter(
      (item) => item.type === 'slide' && item.position && item.active
    )

    const droppableData = []
    if (banners) {
      banners.forEach((banner) => {
        droppableData.push({
          draggableData: banner,
          droppableData: 'banner' + banner.position
        })
      })
    }

    if (bannerXl) {
      droppableData.push({
        draggableData: bannerXl,
        droppableData: 'banner3'
      })
    }

    if (slides) {
      slides.forEach((slide) => {
        droppableData.push({
          draggableData: slide,
          droppableData: 'slide'
        })
      })
    }

    setDroppedSlides(droppableData)
    setInitialSlides(droppableData)
    // clean up
    return () => {
      setDroppedSlides([])
      setInitialSlides([])
    }
  }, [bannerList])

  const onDragStart = (event) => {
    let draggindId = event.active.id
    // add z index to the dragged element
    if (typeof draggindId === 'string' && draggindId.match(/dropped_/)) {
      draggindId = Number(draggindId.replace('dropped_', ''))
      setIsOverList(true)
    }
    setDragging(draggindId)
  }

  const onDragOver = (event) => {
    // identify the droppable element and the draggable element (the one being dragged)
    // slides can be dropped in the slide area
    // banners can be dropped in the banner areas
    let activeId = event.active?.id
    if (typeof activeId === 'string' && activeId.match(/dropped_/)) {
      activeId = Number(activeId.replace('dropped_', ''))
    }

    const draggbleImage = imageList.find((image) => image.id === activeId)

    if (event.over?.id === 'slide' && draggbleImage?.type === 'slide') {
      return setCanDrop(true)
    } else if (
      Boolean(event.over?.id.match(/banner[1-2]/)) &&
      ['banner', 'banner 2'].indexOf(draggbleImage?.type) > -1
    ) {
      return setCanDrop(true)
    } else if (
      Boolean(event.over?.id === 'banner3') &&
      draggbleImage?.type === 'banner XL'
    ) {
      return setCanDrop(true)
    }

    setCanDrop(false)
    setDragging(false)
  }

  const onDragEnd = (event) => {
    const { active, over } = event
    setDragging(false)
    setIsOverList(false)
    saved.current = false

    let activeId = event.active?.id
    if (typeof activeId === 'string' && activeId.match(/dropped_/)) {
      activeId = Number(activeId.replace('dropped_', ''))
    }

    if (over && canDrop) {
      // get the draggable item's data
      const draggableData = imageList.find((image) => image.id === activeId)

      // get the droppable zone's data
      const droppableData = over.id

      // is draggableData already in droppedSlides?
      const alreadyDropped = droppedSlides.find(
        (slide) =>
          slide.draggableData.id === activeId &&
          slide.droppableData !== droppableData
      )
      // add the draggable item's data to the droppable zone's data
      const newDroppedSlides = [
        ...droppedSlides,
        { draggableData, droppableData }
      ]
      // if it is, remove it from the array
      if (alreadyDropped) {
        newDroppedSlides.splice(newDroppedSlides.indexOf(alreadyDropped), 1)
      }

      // check if there is already a banner in the droppable zone
      const alreadyDroppedBanner = droppedSlides.find(
        (slide) => slide.droppableData === droppableData
      )
      // if there is, remove it from the array
      if (alreadyDroppedBanner) {
        // slide can have multilple images

        if (droppableData === 'slide') {
          // if the coming slide is not already in the array, add it
          if (
            !newDroppedSlides.find(
              (slide) => slide.draggableData.id === activeId
            )
          ) {
            newDroppedSlides.push({ draggableData, droppableData })
          } else {
            const index = newDroppedSlides.findIndex(
              (slide) => slide.draggableData.id === activeId
            )
            newDroppedSlides.splice(index, 1)
          }
        } else {
          newDroppedSlides.splice(
            newDroppedSlides.indexOf(alreadyDroppedBanner),
            1
          )
        }
      }

      // change posistion of the banners
      if (droppableData.match(/banner[1-2]/)) {
        newDroppedSlides.forEach((slide) => {
          if (slide.droppableData === 'banner1') {
            slide.draggableData.position = 1
          } else if (slide.droppableData === 'banner2') {
            slide.draggableData.position = 2
          }
        })
      }

      setCanDrop(false)
      setDragging(false)
      setIsOverList(false)
      return setDroppedSlides(newDroppedSlides)
    }

    // remove the draggable item's data from the droppable zone's data
    if (active && !over) {
      const newDroppedSlides = droppedSlides.filter(
        (slide) => slide.draggableData.id !== activeId
      )
      // update the state
      setCanDrop(false)
      setDragging(false)
      setIsOverList(false)
      return setDroppedSlides(newDroppedSlides)
    }

    setCanDrop(false)
    setDragging(false)
    setIsOverList(false)
  }

  const droppedImage = (type) => {
    if (droppedSlides.length === 0) return null
    // check if type contains number and return the banner with that number in position

    if (type.includes('banner')) {
      const banner = droppedSlides.find(
        (slide) => slide.droppableData === type
      )?.draggableData

      if (!banner) return null

      return (
        <Draggable id={'dropped_' + banner.id} data={banner} key={banner.image}>
          <img
            src={banner.image}
            alt={banner.name}
            style={{
              backgroundColor: canDrop
                ? 'rgb(130 241 2 / 17%)'
                : !canDrop
                ? 'rgb(255 0 0 / 17%)'
                : 'transparent',
              opacity: canDrop && '0.5',
              height: '100%',
              zIndex: dragging === banner.id ? '10' : '1'
            }}
          />
        </Draggable>
      )
    }
    // SLIDES
    return droppedSlides
      .filter((slide) => slide.droppableData === type)
      .map((slide) => (
        <Draggable
          id={'dropped_' + slide.draggableData.id}
          data={slide.draggableData}
          key={slide.image}
        >
          <img
            src={slide.draggableData.image}
            alt={slide.draggableData.name}
            style={{
              backgroundColor: canDrop
                ? 'rgb(130 241 2 / 17%)'
                : !canDrop
                ? 'rgb(255 0 0 / 17%)'
                : 'transparent',
              width: dragging === slide.draggableData.id ? '100px' : 'auto',
              height: dragging === slide.draggableData.id ? '100px' : 'auto',
              opacity: (canDrop || dragging) && '0.5'
            }}
          />
        </Draggable>
      ))
  }

  const checkIfDropped = (id) => {
    if (!droppedSlides.length) return false
    return !!droppedSlides.find((slide) => slide.draggableData?.id === id)
  }
  const onSave = async () => {
    setSaving(true)

    // arrange the images by position and type: types [banner, banner XL, slide]
    const banners = droppedSlides
      .filter((image) => image.draggableData.type.includes('banner'))
      .map((image, index) => {
        if (image.draggableData.type === 'banner XL') {
          return {
            id: image.draggableData.id,
            type: image.draggableData.type,
            position: 1
          }
        } else {
          return {
            id: image.draggableData.id,
            type: image.draggableData.type,
            position: image.draggableData.position
          }
        }
      })

    const slides = droppedSlides
      .filter((image) => image.draggableData.type === 'slide')
      .map((image, index) => {
        return {
          id: image.draggableData.id,
          type: image.draggableData.type,
          position: index + 1
        }
      })

    const bannerArray = banners
      .map((banner) => {
        return {
          banner_id: banner.id,
          position: banner.position
        }
      })
      .concat(
        slides.map((slide) => {
          return {
            banner_id: slide.id,
            position: slide.position
          }
        })
      )

    const { success, data } = await reorderBanners(layoutId, bannerArray)

    if (success) {
      showMessage({
        message: t('Se ha guardado correctamente'),
        type: 'success'
      })
      defaultLayoutForAll.current = data
      saved.current = true
    } else {
      showMessage({
        message: t('No se ha podido guardar'),
        type: 'alert'
      })
    }
    setSaving(false)
  }

  const isThereChanges = () => {
    if (droppedSlides.length === 0 || initialSlides.length === 0) return false
    return droppedSlides.some((slide, index) => {
      return (
        slide?.draggableData?.id !== initialSlides[index]?.draggableData?.id &&
        !saved.current
      )
    })
  }

  return (
    <div className='tw-relative'>
      {loading && <Loader mode='modal' transparent={true} />}
      <div className='tw-flex tw-flex-col tw-bg-white tw-mt-10 tw-h-full tw-relative tw-gap-2 tw-overflow-hidden tw-relative'>
        <DndContext
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDragEnd={onDragEnd}
        >
          <div className='tw-relative'>
            {/* search header vadefarma */}
            <div className='tw-flex tw-flex-col'>
              <div className='tw-relative tw-w-full tw-mb-4'>
                <div className='text-input-search tw-w-full tw-flex tw-gap-4'>
                  <div className='tw-flex tw-flex-1 tw-bg-white tw-border tw-border-gray-200 tw-items-center tw-py-2 tw-px-4'>
                    <span
                      className='icon-ic_common_search tw-text-primary'
                      style={{ fontSize: 24 }}
                    />
                    <input
                      type='text'
                      className='tw-w-full tw-text-md tw-outline-none tw-self-center tw-pl-2 tw-text-gray-400 tw-bg-transparent'
                      placeholder={t(
                        'Busca entre más de 90.000 referencias de más de 700 laboratorios'
                      )}
                      disabled
                    />
                  </div>
                  <div className='tw-flex tw-items-center tw-justify-center tw-text-white'>
                    <button
                      className='tw-relative tw-w-full tw-h-full tw-bg-primary tw-flex tw-items-center tw-justify-center tw-text-white tw-px-4 tw-font-semibold tw-text-sm tw-uppercase'
                      style={{
                        backgroundColor: 'rgba(103,135,147,1)'
                      }}
                      disabled
                    >
                      {t('Lo más buscado')}
                    </button>
                  </div>
                  <div className='tw-relative tw-flex tw-items-center tw-justify-center tw-text-white'>
                    <button
                      className='tw-w-full tw-h-full tw-bg-black tw-flex tw-items-center tw-justify-center tw-text-white tw-px-4 tw-font-semibold tw-text-sm tw-uppercase'
                      disabled
                    >
                      <span
                        style={{ fontSize: 18 }}
                        className='icon-ic_fav_on tw-text-white'
                      />
                    </button>
                    <div className='tw-items-cente tw-absolute tw-top-[-3px] tw-left-[-3px] tw-flex tw-h-[15px] tw-w-[15px] tw-justify-center tw-rounded-full tw-border-2 tw-border-primary tw-border-gray-300 tw-bg-white'>
                      <Paragraphs size='xxxs' weight='bold'>
                        10
                      </Paragraphs>
                    </div>
                  </div>
                  <div className='tw-relative tw-flex tw-items-center tw-justify-center tw-text-white'>
                    <button
                      className='tw-w-full tw-h-full tw-bg-black tw-flex tw-items-center tw-justify-center tw-text-white tw-px-4 tw-font-semibold tw-text-sm tw-uppercase'
                      disabled
                    >
                      <span
                        className='icon-ic_cesta_aadir'
                        style={{ fontSize: 20 }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* SLIDES */}
            <div
              className={`tw-relative tw-h-full ${
                droppedSlides.length === 0 ? 'tw-bg-quaternary' : ''
              }`}
              ref={containerRef}
            >
              <div className='tw-relative tw-h-auto tw-w-full tw-min-h-[300px]'>
                <Droppable id='slide' canDrop={canDrop}>
                  {droppedSlides.filter(
                    (data) => data.droppableData === 'slide'
                  ).length === 0 ? (
                    <div
                      className='tw-flex tw-align-center tw-justify-center tw-items-center tw-h-full tw-min-h-[300px] tw-text-gray-400 tw-uppercase tw-text-[50px]'
                      key='slide 1275x286'
                    >
                      Slide (1275x286px)
                    </div>
                  ) : (
                    <Carousel>{droppedImage('slide')}</Carousel>
                  )}
                </Droppable>
              </div>
            </div>
            <div className='tw-w-full tw-block tw-bg-black tw-h-[30px]' />
            <div className='tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-full tw-gap-2 tw-h-[42px]'>
              <span className='tw-font-bold tw-w-1/3 tw-text-md tw-text-gray-600 tw-bg-quaternary tw-h-full tw-flex tw-justify-center tw-items-center'>
                {t('Seguimiento de pedidos')}
              </span>
              <span className='tw-font-bold tw-w-1/3 tw-text-md tw-text-gray-600 tw-bg-quaternary tw-h-full tw-flex tw-justify-center tw-items-center'>
                {t('Albaranes y devoluciones')}
              </span>
              <span className='tw-font-bold tw-w-1/3 tw-text-md tw-text-gray-600 tw-bg-quaternary tw-h-full tw-flex tw-justify-center tw-items-center'>
                {t('Facturas')}
              </span>
            </div>
            {/* BANNERS */}
            <div className='tw-bg-quaternary tw-min-h-[200px] tw-flex tw-gap-4'>
              <div className='droppable tw-relative tw-min-w-[200px] tw-w-1/5 tw-m-4 tw-bg-white'>
                <Droppable id='banner1' canDrop={canDrop}>
                  {droppedImage('banner1') || (
                    <div className='tw-flex tw-align-center tw-justify-center tw-items-center tw-h-full tw-min-h-[200px] tw-text-gray-400 tw-uppercase tw-text-lg'>
                      Banner 1 (261x228px)
                    </div>
                  )}
                </Droppable>
              </div>
              <div className='droppable tw-relative tw-min-w-[200px] tw-w-1/5 tw-my-4 tw-mr-4 tw--ml-2 tw-bg-white'>
                <Droppable id='banner2' canDrop={canDrop}>
                  {droppedImage('banner2') || (
                    <div
                      className='tw-flex tw-align-center tw-justify-center tw-items-center tw-h-full tw-min-h-[200px] tw-text-gray-400 tw-uppercase tw-text-lg hover:bg-transparent'
                      style={{
                        zIndex: 0
                      }}
                    >
                      Banner 2 (261x228px)
                    </div>
                  )}
                </Droppable>
              </div>
              <div className='droppable tw-relative tw-min-w-[200px] tw-w-3/5 tw-my-4 tw-mr-4 tw--ml-2 tw-bg-white'>
                <Droppable id='banner3' canDrop={canDrop}>
                  {droppedImage('banner3') || (
                    <div className='tw-flex tw-align-center tw-justify-center tw-items-center tw-h-full tw-min-h-[200px] tw-text-gray-400 tw-uppercase tw-text-lg'>
                      Banner 3 (716x205px)
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          </div>

          {/* image list */}

          <div className='tw-h-full tw-min-h-[200px] tw-p-4 tw-border tw-border-gray-200 tw-rounded-lg tw-shadow-md tw-flex-1 tw-w-full'>
            <div
              className='tw-flex tw-flex-row tw-justify-start tw-gap-4 tw-items-center tw-mt-10 tw-w-full tw-flex-wrap'
              style={{
                backgroundColor: isOverList
                  ? 'rgb(130 241 2 / 17%)'
                  : 'transparent',
                zIndex: dragging ? 10 : 0,
                minHeight: '200px',
                opacity: dragging ? 0.5 : 1
              }}
              onMouseEnter={() => dragging && setIsOverList(true)}
            >
              {imageList.map((image, index) => (
                <Draggable
                  key={`draggable-${index}-${image.id}`}
                  id={image.id}
                  data={image}
                >
                  <img
                    className='draggable hover:tw-shadow-lg tw-cursor-pointer tw-transition tw-duration-300 tw-ease-in-out'
                    src={image.image}
                    alt='banner'
                    style={{
                      minWidth: '180px',
                      maxWidth: '180px',
                      display: checkIfDropped(image.id) ? 'none' : 'inherit',
                      opacity: dragging ? 0.5 : 1,
                      zIndex: dragging ? 2 : 10,
                      width: '100%'
                    }}
                  />
                </Draggable>
              ))}
            </div>
          </div>
        </DndContext>
        {/* save button */}
        <div
          className='tw-flex tw-justify-end tw-mt-4 tw-items-center'
          style={
            loading
              ? { pointerEvents: 'none', opacity: 0.5, cursor: 'not-allowed' }
              : {}
          }
        >
          {isThereChanges() && (
            <Paragraphs
              size='lg'
              weight='bold'
              className='tw-mr-4 tw-bg-warning tw-opacity-75 tw-p-1.5 tw-w-full tw-text-center tw-items-center'
            >
              {t('Guardar para aplicar cambios')}.
            </Paragraphs>
          )}
          <Button
            mode='primary'
            uppercase
            label={t('Guardar')}
            onClick={() => onSave()}
            disabled={loading || saving}
          />
        </div>
      </div>
    </div>
  )
}

export default PreviewView

PreviewView.propTypes = {
  bannerList: PropTypes.array.isRequired,
  defaultLayoutForAll: PropTypes.any,
  imageList: PropTypes.array.isRequired,
  layoutId: PropTypes.number.isRequired,
  layoutToAssign: PropTypes.object.isRequired
}
