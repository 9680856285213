import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '../../infrastructure/context/ThemeContext/ThemeContext'
/**
 *
 * @param {node} children - children of the component
 * @returns
 */
const Header = ({ children }) => {
  const { theme } = useTheme()
  return (
    <header className='tw-fixed tw-top-0 tw-left-0 tw-h-[40px] tw-w-full tw-flex tw-justify-between tw-bg-quaternary tw-z-50'>
      <div className='tw-p-2 tw-h-full tw-w-full'>
        <img src={theme.logoDark} alt='logo' className={'tw-max-h-full'} />
      </div>
      <div className='tw-p-2 tw-h-full tw-w-full tw-flex tw-justify-end'>
        {children}
      </div>
    </header>
  )
}

Header.propTypes = {
  children: PropTypes.node
}
export default Header
