export const BASE = process.env.REACT_APP_BASE_API
export const URL_BASE = BASE

export const URL_SUPPLANT_VADEFARMA =
  process.env.REACT_APP_URL_SUPPLANT_VADEFARMA
export const URL_SUPPLANT_VADEFARMA_DEV =
  process.env.REACT_APP_URL_SUPPLANT_VADEFARMA_DEV

export const URL_SUPPLANT_FEDEFARMACLIENTS =
  process.env.REACT_APP_URL_SUPPLANT_FEDEFARMACLIENTS

export const URL_SUPPLANT_FEDEFARMACLIENTS_DEV =
  process.env.REACT_APP_URL_SUPPLANT_FEDEFARMACLIENTS_DEV

export const URL_COMPLETE_PROFILE = process.env.REACT_APP_URL_COMPLETE_PROFILE

export const URL_COMPLETE_PROFILE_FEDEFARMACLIENTS =
  process.env.REACT_APP_URL_COMPLETE_PROFILE_FEDEFARMACLIENTS

export const URL_COMPLETE_PROFILE_FEDEFARMACLIENTS_DEV =
  process.env.REACT_APP_URL_COMPLETE_PROFILE_FEDEFARMACLIENTS_DEV

export const URL_COMPLETE_PROFILE_DEV =
  process.env.REACT_APP_URL_COMPLETE_PROFILE_DEV
