import { useState } from 'react'
import useMutation from '../useMutation'
import { bannerColumns } from './constans/contants'
import useFlashMessage from '../useFlashMessage'
/**
 * Hook to manage banners api calls and list
 * @returns {Object} List:
 * - getBannerList
 * - getBanner
 * - createBanner
 * - updateBanner
 * - deleteBanner
 * - loading
 * - setLoading
 * - getBannerLocations
 * - getProducts
 */

export const useBanners = () => {
  const [loading, setLoading] = useState(false)
  const [fetchBannerApi] = useMutation('banners')
  const [fetchBannerLocationsApi] = useMutation('locations')
  const [fetchProductsApi] = useMutation('products/list')
  const [fetchLayout] = useMutation('banner-layouts')

  const { showMessage } = useFlashMessage()

  /**
   * This function will return the list of banners
   * @param {*} params - params to filter the banners
   * @returns {Object} List of banners, meta and success
   * @async
   */
  const getBannerList = async (params) => {
    setLoading(true)
    const { success, data, error } = await fetchBannerApi({
      method: 'post',
      data: params,
      addToURL: '/list'
    })

    if (success) {
      setLoading(false)
      return {
        success,
        data: data.items,
        pagination: data.pagination
      }
    }

    setLoading(false)
    return {
      success,
      data: [],
      error
    }
  }

  /**
   * This function will return the banner by id
   * @param {number} id - id of the banner to get
   * @returns {Object} Banner and success
   * @async
   */
  const getBanner = async (id) => {
    setLoading(true)
    const { success, data, error } = await fetchBannerApi({
      method: 'get',
      addToURL: `/${id}`
    })

    if (success) {
      setLoading(false)
      return {
        success,
        data: data.item
      }
    }

    setLoading(false)
    return {
      success,
      data: [],
      error
    }
  }

  /**
   * This function will create a banner
   * @param {Object} values - values to create banner
   * @returns {Object} Banner and success
   * @async
   */
  const createBanner = async (values) => {
    setLoading(true)
    const { success, data, error } = await fetchBannerApi({
      method: 'post',
      data: values
    })

    if (success) {
      setLoading(false)
      return {
        success,
        data: data.item
      }
    }

    setLoading(false)
    return {
      success,
      data: [],
      error
    }
  }

  /**
   * This function will update a banner
   * @param {Object} values - values to update banner
   * @returns {Object} Banner and success
   * @async
   */
  const updateBanner = async (values) => {
    setLoading(true)
    const { data, error } = await fetchBannerApi({
      method: 'put',
      data: values,
      addToURL: `/${values.id}`
    })

    if (data) {
      setLoading(false)
      return {
        success: true,
        data: data.item
      }
    }

    setLoading(false)
    return {
      success: false,
      data: [],
      error
    }
  }

  /**
   * This function will delete a banner
   * @param {nunber} id - id of the banner to delete
   * @returns {Object} success
   * @async
   */
  const deleteBanner = async (id) => {
    setLoading(true)
    const { success, data, error } = await fetchBannerApi({
      method: 'delete',
      addToURL: `/${id}`
    })

    if (success) {
      setLoading(false)
      showMessage({ message: 'Banner eliminado', type: 'success' })
      return {
        success
      }
    }

    setLoading(false)
    return {
      success,
      error
    }
  }

  /**
   * This function will return the list of banner locations
   * @returns {Object} List of banner locations and success
   * @async
   */
  const getBannerLocations = async () => {
    setLoading(true)
    const { success, data, error } = await fetchBannerLocationsApi({
      method: 'get'
    })

    if (success) {
      setLoading(false)
      return {
        success,
        data: data.data.items
      }
    }

    setLoading(false)
    return {
      success,
      data: [],
      error
    }
  }

  /**
   * This function will return the list of searched products
   * @param {string} search - search term
   * @param {*} page - page number
   * @returns {Object} List of products, pagination and success
   * @async
   */
  const getProducts = async (search, page) => {
    const { success, data, error } = await fetchProductsApi({
      method: 'get',
      addToURL: `?${search ? `search=${search}&` : ''}page=${page}&per_page=50`
    })

    if (success) {
      return {
        success,
        data: data.items,
        pagination: data.pagination
      }
    }

    return {
      success,
      data: [],
      error
    }
  }

  /**
   * This function will reorder the banners
   * @param {Object} values - values to reorder banners {id, type, position}
   * @async
   * @returns {Object} success
   */
  const reorderBanners = async (layoutId, positions) => {
    setLoading(true)
    const { success, data, error } = await fetchLayout({
      method: 'post',
      data: {
        positions
      },
      addToURL: `/${layoutId}/positions/massive`
    })

    if (success) {
      setLoading(false)
      return {
        success,
        data
      }
    }

    setLoading(false)
    return {
      success,
      error
    }
  }

  const getBannerLayouts = async () => {
    setLoading(true)
    const { success, data, error } = await fetchLayout({
      method: 'get'
    })

    if (success) {
      setLoading(false)
      return {
        success,
        data
      }
    }

    setLoading(false)
  }

  const getPharmacyLayout = async (organizationId) => {
    setLoading(true)
    const { success, data, error } = await fetchLayout({
      method: 'get',
      addToURL: `/${organizationId}/positions`
    })

    if (success) {
      setLoading(false)
      return {
        success,
        data
      }
    }
  }

  const updateLayout = async (layoutId, value) => {
    setLoading(true)
    const { success, data, error } = await fetchLayout({
      method: 'put',
      data: {
        use_inherit: value
      },
      addToURL: `/${layoutId}`
    })

    if (success) {
      setLoading(false)
      return {
        success,
        data
      }
    }

    setLoading(false)
    return {
      success,
      error
    }
  }

  return {
    getBannerList,
    getBanner,
    createBanner,
    updateBanner,
    updateLayout,
    deleteBanner,
    reorderBanners,
    getBannerLocations,
    getBannerLayouts,
    getPharmacyLayout,
    loading,
    setLoading,
    bannerColumns,
    getProducts
  }
}
