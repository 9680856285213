import styledComponents from 'styled-components'

export const AvatarContainer = styledComponents.div`
    &:hover .avatar__menu {
        display: block;
    }

    & .avatar__menu{
        display: none;
    }
`
