import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { AsyncPaginate } from 'react-select-async-paginate'
import Paragraphs from '../../../../components/Paragraphs'
import { bannerInputs, typeOptions } from '../../constants/constants'
import Button from '../../../../components/Button'
import useBanners from '../../../../infrastructure/hooks/useBanners'
import useFlashMessage from '../../../../infrastructure/hooks/useFlashMessage'
import '../styles.css'

const BannerEdit = (props) => {
  const { tab, handleTabClick } = props
  const { t } = useTranslation()
  const formRef = React.createRef()
  const {
    loading,
    setLoading,
    getProducts,
    updateBanner,
    createBanner,
    deleteBanner
  } = useBanners()
  const { showMessage } = useFlashMessage()
  const [selectedProducts, setSelectedProducts] = useState([])
  const [type, setType] = useState(tab.type ? tab.type : 'banner')
  const [loadingFileInput, setLoadingFileInput] = useState(false)
  const [loadingProducts, setLoadingProducts] = useState(false)
  const {
    handleSubmit,
    reset,
    setFocus,
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      type: 'banner',
      image: '',
      location_id: 3,
      products: [],
      active: false
    }
  })

  const [image, setImage] = useState(tab.image ? tab.image : null)

  /**
   * Handle the submit of the form
   * @param {object} data - data to submit
   * @returns {void}
   * @async
   */
  const onSubmit = async (data) => {
    setLoading(true)
    if (tab.id !== -1) {
      if (!data.image.includes('data:image')) {
        delete data.image
      }
      const { success, error } = await updateBanner({
        ...data,
        products: selectedProducts.map((p) => Number(p.value))
      })
      if (success) {
        showMessage({
          message: t('Se ha actualizado correctamente'),
          type: 'success'
        })
        closeModal()
      } else {
        const { errors } = error
        handleErrors(errors)
      }
    } else {
      const { success, error } = await createBanner(data)
      if (success) {
        showMessage({
          message: t('Se ha creado correctamente'),
          type: 'success'
        })
        closeModal()
      } else {
        const { errors } = error
        handleErrors(errors)
      }
    }
    setLoading(false)
  }

  /**
   * Close the modal
   */
  const closeModal = () => {
    setTimeout(() => {
      handleTabClick(tab.id, 1)
    }, 1300)
  }

  /**
   * Handle the coming errors from the api,
   * it sets the errors in the form
   * @param {object} errors - errors to set
   * @returns {void}
   */
  const handleErrors = (errors) => {
    Object.keys(errors).forEach((key, i) => {
      const error = errors[key]
      setError(
        key,
        {
          type: 'manual',
          message: error[0]
        },
        {
          shouldFocus: i === 0
        }
      )
    })
    if (getValues('products') && getValues('products').length === 0) {
      setError('products', {
        type: 'manual',
        message: t('Debe seleccionar al menos un producto')
      })
    }
    showMessage({
      message: t('Ha ocurrido un error'),
      type: 'alert'
    })
  }

  /**
   * Set the focus on the first input
   */
  useEffect(() => {
    setFocus('title')
  }, [setFocus])

  /**
   * If the tab changes, reset the form and set the values
   */
  useEffect(() => {
    setLoading(true)
    setLoadingProducts(true)
    reset()
    if (tab && tab.id !== -1 && Object.keys(tab).length > 0) {
      setValue('title', tab.title)
      setValue('description', tab.description)
      setValue('type', tab.type)
      setValue('image', tab.image)
      setValue('location', tab.location.location)
      setValue('location_id', 3)
      setValue('products', tab.products)
      setValue('id', tab.id)
      setValue('active', tab.active)
      getSelectedProducts(tab.products)
      setImage(tab.image)
    }
    setLoading(false)
  }, [tab])

  const getSelectedProducts = async (products) => {
    if (products.length === 0) {
      setLoadingProducts(false)
      return setSelectedProducts([])
    }

    const res = await getProducts(products.join(','), 1)

    if (res.data.length > 0) {
      const loadedProducts = res.data.map((p) => ({
        cn: p.cn,
        value: p.cn,
        label: `${p.cn} - ${p.name}`,
        id: p.id
      }))
      setSelectedProducts(loadedProducts)
    } else {
      setSelectedProducts([])
    }
    setLoadingProducts(false)
  }

  /**
   * Convert an image to base64
   * @param {object} e - file event
   * @returns {void}
   */
  const handleUpload = (e) => {
    if (!e.target.files[0]) return
    setLoadingFileInput(true)
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      setValue('image', reader.result)
      setImage(reader.result)
    }
    reader.onerror = () => {
      console.error('Error')
    }

    setLoadingFileInput(false)
  }

  /**
   * Handle the change of the location select and the search
   * @param {string} search - search term
   * @param {array} loadedOptions  - loaded options
   * @param {object} param2 - additional params
   * @returns {object} - options
   * @async
   * @function
   * @name loadOptions
   * @example
   * const res = await loadOptions('search', [], { page: 1 })
   * console.log(res)
   * // {
   * //   options: [],
   * //   hasMore: false
   * // }
   *
   */
  const loadOptions = async (search, loadedOptions, { page }) => {
    setLoadingProducts(true)
    const res = await getProducts(search, page)
    const options = res.data
      .map((products) => ({
        value: products.id,
        label: `${products.cn} - ${products.name}`,
        cn: products.cn
      }))
      .filter((product) => {
        return !selectedProducts.some(
          (selected) => selected.label === product.label
        )
      })
    if (res.success) {
      setLoadingProducts(false)
      return {
        options,
        hasMore: res.pagination.has_more,
        additional: {
          page: page + 1
        }
      }
    }

    setLoadingProducts(false)
    return {
      options: [],
      hasMore: false
    }
  }

  return (
    <div className='banner-edit tw-p-3 tw-w-full'>
      <form onSubmit={handleSubmit(onSubmit)} className='tw-p-4' ref={formRef}>
        <div className='tw-flex tw-flex-row tw-gap-6 flex-wrap tw-justify-start'>
          {bannerInputs.map((input, index) => (
            <div
              className='tw-flex tw-flex-col tw-gap-3'
              key={index + input.name}
            >
              <Paragraphs size='sm' weight='bold'>
                {t(input.label)}
              </Paragraphs>
              <input
                className='tw-input tw-border-2 tw-border-gray-200 tw-py-2 tw-px-3 tw-text-gray-700 tw-leading-tight tw-focus:outline-none tw-focus:shadow-outline'
                style={{ width: input.width }}
                {...register(input.name, {
                  required: true,
                  onChange: (e) => {
                    clearErrors(input.name)
                    setValue(input.name, e.target.value)
                  }
                })}
                {...input}
              />
              {errors[input.name] && (
                <span className='tw-text-red-500 tw-text-xs tw-italic'>
                  {t(errors[input.name].message)}
                </span>
              )}
            </div>
          ))}

          <div className='tw-flex tw-flex-col tw-gap-3'>
            <Paragraphs size='sm' weight='bold'>
              {t('Tipo')}
            </Paragraphs>
            <Select
              options={typeOptions}
              defaultValue={{
                value: tab?.type || getValues('type'),
                label: tab?.type || getValues('type')
              }}
              placeholder={t('Tipo')}
              {...register('type', { required: true })}
              styles={{
                control: (base) => ({
                  ...base,
                  minWidth: '150px',
                  borderRadius: '0'
                })
              }}
              tabIndex={4}
              onChange={(e) => {
                setValue('type', e.value)
                setType(e.value)
              }}
            />
          </div>
          <div className='tw-flex tw-flex-col tw-gap-3'>
            <Paragraphs size='sm' weight='bold'>
              {t('Activo')}
            </Paragraphs>

            <label
              htmlFor='toogleA'
              className='tw-relative tw-cursor-pointer tw-flex tw-h-full tw-mt-2'
              onClick={() => {
                setValue('active', document.getElementById('toogleA').checked)
              }}
              tabIndex={6}
            >
              <div className='tw-relative'>
                <input
                  id='toogleA'
                  type='checkbox'
                  className='sr-only'
                  defaultChecked={getValues('active')}
                />
                <div className='tw-w-10 tw-h-4 tw-bg-gray-400 tw-rounded-full tw-shadow-inner'></div>
                <div className='dot tw-absolute tw-w-6 tw-h-6 tw-bg-white tw-rounded-full tw-shadow tw--left-1 tw--top-1 tw-transition'></div>
              </div>
            </label>
          </div>
        </div>

        <div className='tw-flex tw-flex-row tw-gap-10 flex-wrap tw-mt-8 tw-justify-between'>
          <div className='tw-flex tw-flex-col tw-gap-3'>
            <Paragraphs size='sm' weight='bold'>
              {t('Productos')}
            </Paragraphs>
            <AsyncPaginate
              loadOptions={loadOptions}
              isLoading={loadingProducts}
              placeholder={t('Buscar productos')}
              {...register('products', { required: true })}
              styles={{
                control: (base) => ({
                  ...base,
                  minWidth: '400px',
                  maxWidth: '600px',
                  borderRadius: '0'
                })
              }}
              value={selectedProducts}
              additional={{
                page: 1
              }}
              isMulti
              debounceTimeout={300}
              noOptionsMessage={() => t('No se encontraron productos')}
              loadingMessage={() => t('Cargando...')}
              tabIndex={7}
              onChange={(e) => {
                setValue(
                  'products',
                  e.map((product) => product.value)
                )
                setSelectedProducts(e)
              }}
              backspaceRemovesValue={true}
              isClearable={true}
            />
            {errors.products && (
              <span className='tw-text-red-500 tw-text-xs tw-italic'>
                {t(errors.products.message)}
              </span>
            )}
          </div>
          <div className='tw-flex tw-flex-col tw-gap-3 tw-w-1/5 tw-h-16'>
            <Paragraphs size='sm' weight='bold' className='tw-invisible'>
              invisible
            </Paragraphs>
            <Button
              loading={loadingFileInput}
              size='full'
              label={
                getValues('image') && getValues('image').length > 0
                  ? t('Cambiar imagen')
                  : t('Subir imagen')
              }
              uppercase
              onClick={() => {
                document.getElementsByName('image')[0].click()
              }}
              tabIndex={8}
              disabled={loadingFileInput}
              iconRight={
                loadingFileInput && (
                  <div className='tw-ml-4 tw-flex tw-items-center tw-justify-center tw-w-4 tw-h-4'>
                    <svg
                      className='tw-animate-spin tw-h-4 tw-w-4 tw-text-white'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='tw-opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='4'
                      ></circle>
                      <path
                        className='tw-opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z'
                      ></path>
                    </svg>
                  </div>
                )
              }
            />
            <input
              type='file'
              hidden
              name='image'
              {...register('image', { required: true })}
              accept='image/jpeg, image/jpg, image/png'
              onChange={(e) => {
                handleUpload(e)
              }}
            />
            {errors.image && (
              <span className='tw-text-red-500 tw-text-xs tw-italic'>
                {t('La imagen es requerida')}
              </span>
            )}
          </div>
        </div>

        <div className='tw-flex tw-flex-row tw-gap-10 flex-wrap tw-mt-8'>
          {/* image preview */}
          <div className='tw-flex tw-flex-col tw-gap-3 tw-w-full'>
            <Paragraphs size='sm' weight='bold'>
              {t('Vista previa')}
            </Paragraphs>

            <div
              className='tw-border-2 tw-border-gray-200 tw-flex tw-items-center tw-justify-center tw-relative'
              style={{
                maxWidth: '100%',
                height: '400px'
              }}
            >
              {!image && getValues('image') && !getValues('image').length ? (
                <div className='tw-absolute tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full tw-bg-gray-200 tw-opacity-50'>
                  <svg
                    className='tw-h-12 tw-w-12 tw-text-gray-400'
                    fill='currentColor'
                    viewBox='0 0 20 20'
                  >
                    <path
                      fillRule='evenodd'
                      d='M10 12a2 2 0 100-4 2 2 0 000 4z'
                      clipRule='evenodd'
                    ></path>
                    <path
                      fillRule='evenodd'
                      d='M2 10a8 8 0 1116 0 8 8 0 01-16 0zm8-6a6 6 0 100 12 6 6 0 000-12z'
                      clipRule='evenodd'
                    ></path>
                  </svg>
                </div>
              ) : (
                image && (
                  <img
                    src={image}
                    alt='preview'
                    style={{ zIndex: -2, maxHeight: '100%', maxWidth: '100%' }}
                  />
                )
              )}
            </div>
          </div>
        </div>

        <div className='tw-flex tw-flex-row tw-gap-10 flex-wrap tw-mt-8 tw-justify-end relative'>
          {tab.id !== -1 ? (
            <div className='tw-absolute tw-bottom-0 tw-left-0 tw-w-1/5'>
              <Button
                disabled={loading}
                label={t('Borrar')}
                styles={{
                  backgroundColor: '#F0F0F0',
                  color: 'red'
                }}
                uppercase
                loading={loading}
                size='full'
                onClick={(e) => {
                  e.preventDefault()
                  deleteBanner(tab.id)
                  handleTabClick(tab.id, 0)
                }}
              />
            </div>
          ) : null}
          <div className='tw-flex tw-flex-col tw-gap-3 tw-w-1/5'>
            <Button
              label={t('Cancelar')}
              uppercase
              styles={{
                backgroundColor: '#F0F0F0',
                color: '#000000'
              }}
              size='full'
              onClick={(e) => {
                e.preventDefault()
                reset()
                handleTabClick(tab.id, 0)
              }}
            />
          </div>
          <div className='tw-flex tw-flex-col tw-gap-3 tw-w-1/5'>
            <Button
              disabled={loading}
              label={t('Guardar')}
              uppercase
              type='submit'
              loading={loading}
              size='full'
              onClick={(e) => {
                e.preventDefault()
                onSubmit(getValues())
              }}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default BannerEdit

BannerEdit.propTypes = {
  tab: PropTypes.object,
  handleTabClick: PropTypes.func
}
