module.exports = {
  alert: '#D8004D',
  primary: '#222221', // c
  primaryhover: '#3e3e3e',
  secondary: '#9d8399', // a 9d8399
  tertiary: '#f6f6f6',
  tertiaryhover: '#e2e2e2', /// b
  quaternary: '#e7d8e1', // d
  quinary: '#8BCDCE',
  secondary20: '#D2BEDD1A',
  success: '#247841',
  warning: '#FFB500',
  white: '#FFFFFF',
  green: '#3DBF42',
  info: '#00B0FF'
}
