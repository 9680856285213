import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useUsersManagement from '../../../../infrastructure/hooks/useUsersManagement'
import { AgGridReact } from 'ag-grid-react'
import Modal from '../../../../components/Modal'
import LogDetails from '../LogDetails'
import { useTranslation } from 'react-i18next'
import { formatFilters } from '../../../../infrastructure/utils/helperFunctions'

const UserLogsAggrid = ({ userId, perPage, translate }) => {
  const { columnDefsLogs, defaultColDef, getUserLogs, getUserFilterOptions } =
    useUsersManagement()
  const [currentLog, setCurrentLog] = useState(null)
  const [showLogDetails, setShowLogDetails] = useState(false)
  const { t } = useTranslation()

  const gridOptions = {
    overlayNoRowsTemplate: t('Sin datos'),
    columnDefs: translate(columnDefsLogs),
    defaultColDef: defaultColDef,
    rowHeight: 40,
    localeText: {
      loadingOoo: t('Cargando...'),
      dateFormatOoo: 'YYYY-MM-DD'
    }
  }

  /**
   * Sets the gridApi to the state and sets the loading overlay if loading is true.
   * @param {object} params - The params object from ag-grid
   */
  const onGridReady = (params) => {
    params.api.addEventListener('paginationChanged', (event) => {
      // remove all rows with ag-loading class, leave one
      const rows = document.querySelectorAll('.ag-loading')
      if (rows.length > 1) {
        rows.forEach((row, index) => {
          if (index !== 0) {
            row.remove()
          }
        })
      }
    })

    /**
     * Updates the data and sets the server-side data source.
     * @function updateData
     * @param {Array} data - The data to update.
     * @returns {void}
     */
    const updateData = (data) => {
      let idSequence = 1
      data?.forEach(function (item) {
        item.id = idSequence++
      })
      const datasource = createDataSource()
      params.api.setServerSideDatasource(datasource)
    }

    updateData()

    const actionColumn = params.columnApi.getColumn('action')
    const originColumn = params.columnApi.getColumn('origin')
    const ipColumn = params.columnApi.getColumn('visitor_ip')

    getUserFilterOptions().then((response) => {
      const { data } = response
      const { actions, origins } = data
      actionColumn.getColDef().filterParams = {
        values: actions,
        defaultToNothingSelected: true,
        buttons: ['reset']
      }
      originColumn.getColDef().filterParams = {
        values: origins,
        defaultToNothingSelected: true,
        buttons: ['reset']
      }

      ipColumn.getColDef().filterParams = {
        values: data.visitor_ips,
        defaultToNothingSelected: true,
        buttons: ['reset']
      }
    })
  }

  /**
   * This function returns an object that is used to create a datasource for the ag-grid in order to use server-side pagination and sorting.
   * @returns {object} An object with a single method called getRows.
   */
  const createDataSource = () => {
    return {
      /**
       * This method is called by the ag-grid to fetch rows for the current page. It handles filters and sorting, calculates the current page, and calls the getUserLogs function to fetch the data. If there is no data to display, * it displays an overlay.
       * @param {*} params - params: An object containing the following properties:
                              api: An object containing methods for interacting with the ag-grid.
                              request: An object containing information about the current request, including filters and sorting.
                              success: A callback function that is called with an object containing the row data and the total row count.
                              failCallback: A callback function that is called if there is no data to display.
        @returns {void}
       */
      getRows: (params) => {
        params.api.hideOverlay()

        let filters = {}
        let orderBy = null

        if (Object.keys(params.request.filterModel).length > 0) {
          filters = formatFilters(params.request.filterModel)
        }

        if (params.request.sortModel.length > 0) {
          const sortField = params.request.sortModel[0].colId

          orderBy = {
            order_field: sortField,
            order_direction: params.request.sortModel[0].sort
          }
        } else {
          orderBy = {
            order_field: 'created_at',
            order_direction: 'desc'
          }
        }

        let page = 1

        if (params.request.startRow > 0) {
          page = params.request.startRow / perPage + 1
        }

        getUserLogs(
          userId,
          {
            per_page: perPage,
            page: page
          },
          Object.keys(filters).length > 0
            ? {
                ...filters
              }
            : null,
          orderBy
        ).then((res) => {
          if (res.data.length === 0) {
            params.failCallback()
            params.api.showNoRowsOverlay()
          }
          params.success({
            rowData: res.data,
            rowCount: res.pagination.total || 0
          })
        })
      }
    }
  }

  return (
    <div
      id='logsAggrid'
      className='user-logs-aggrid ag-theme-alpine'
      style={{ height: 'calc(100vh - 250px)' }}
    >
      <AgGridReact
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowModelType='serverSide'
        paginationPageSize={perPage}
        cacheBlockSize={perPage}
        pagination={true}
        onRowClicked={(e) => {
          setCurrentLog(e?.data)
          setShowLogDetails(true)
        }}
        serverSideStoreType='partial'
        serverSideSortingAlwaysResets={true}
        blockLoadDebounceMillis={500}
        maxConcurrentDatasourceRequests={2}
      />

      {showLogDetails && (
        <Modal
          title={`Detalles del log ${currentLog?.id} del usuario ${currentLog?.user_id}`}
          onRequestClose={() => setShowLogDetails(false)}
          onAccept={() => setShowLogDetails(false)}
          hideCancelButton
          size='lg'
        >
          <LogDetails log={currentLog || {}} />
        </Modal>
      )}
    </div>
  )
}

UserLogsAggrid.propTypes = {
  userId: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  translate: PropTypes.func.isRequired
}

export default UserLogsAggrid
