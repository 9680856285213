import React from 'react'
import PropTypes from 'prop-types'
import UserField from '../UserField'
import Paragraphs from '../../../../components/Paragraphs'
import UserAdminField from '../UserAdminField'
import UserEditField from '../UserEditField'

const UserInfoCard = ({
  title,
  fields,
  assingRole,
  getUserRolesList,
  currentUserId,
  isEditing,
  isSaving,
  isPharmacy = false,
  updateUser,
  changesFieldUser,
  setChangesFieldUser,
  userData,
  setUserData,
  isSuperAdmin,
  setOpenModalVerifyEmail
}) => {
  return (
    <div className='tw-flex tw-flex-col tw-min-w-[200px] tw-border-1 tw-border-black tw-border tw-mb-4 tw-shadow-xl tw-rounded-xl'>
      <Paragraphs
        size='base'
        weight='bold'
        className='tw-bg-quaternary tw-py-0.5 tw-text-center tw-rounded-t-xl tw-px-4'
      >
        {title}
      </Paragraphs>
      <div className='tw-flex tw-flex-col tw-gap-1 tw-flex-wrap tw-justify-between tw-p-3'>
        {fields?.map((field, index) => {
          return field.admin ? (
            <UserAdminField
              key={index}
              index={index}
              value={field.value}
              label={field.label}
              editable={field.edit}
              roles={field.roles}
              assingRole={assingRole}
              getUserRolesList={getUserRolesList}
              currentUserId={currentUserId}
              userData={userData}
              setUserData={setUserData}
              isSuperAdmin={isSuperAdmin}
              isEditing={isEditing}
            />
          ) : field.edit && isEditing ? (
            <UserEditField
              key={index}
              index={index}
              value={field.value}
              label={field.label}
              type={field.type}
              databaseField={field?.databaseField}
              isSuperAdmin={isSuperAdmin}
              currentUserId={currentUserId}
              isSaving={isSaving}
              isPharmacy={isPharmacy}
              roles={field.roles}
              optionsEnabled={field.optionsEnabled}
              changesFieldUser={changesFieldUser}
              setChangesFieldUser={setChangesFieldUser}
              userData={userData}
              setUserData={setUserData}
            />
          ) : (
            <UserField
              key={index}
              index={index}
              value={field.value}
              label={field.label}
              isPharmacyInfo={title.includes('farmacia')}
              {...field}
              setOpenModalVerifyEmail={setOpenModalVerifyEmail}
              isSuperAdmin={isSuperAdmin}
            />
          )
        })}
      </div>
    </div>
  )
}

UserInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  fields: PropTypes.array,
  assingRole: PropTypes.func,
  getUserRolesList: PropTypes.func,
  currentUserId: PropTypes.number,
  isEditing: PropTypes.bool,
  isSaving: PropTypes.bool,
  isPharmacy: PropTypes.bool,
  changesFieldUser: PropTypes.object,
  setChangesFieldUser: PropTypes.func,
  userData: PropTypes.object,
  setUserData: PropTypes.func,
  updateUser: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
  setOpenModalVerifyEmail: PropTypes.func
}

export default UserInfoCard
