import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { actionAddUserTab } from '../../../../infrastructure/redux/actions/userTab'
import { useTranslationsCRUD } from '../../../../infrastructure/hooks/useTranslationsCRUD/useTranslationsCRUD'
import {
  filterDataByGroupKeys,
  formatFilters
} from '../../../../infrastructure/utils/helperFunctions'
import useGetUsersInfo from '../../../../infrastructure/hooks/useUsersManagement'
import { btnStatus, dbTables } from './contants'
import moment from 'moment'
import Button from '../../../../components/Button'

const UsersAggridList = () => {
  const { t } = useTranslation()
  const [gridApi, setGridApi] = useState(null)
  const [excelParams, setExcelParams] = useState(null)
  const [btnText, setBtnText] = useState(t('exportar'))
  const {
    getAllUsers,
    getUnnefarCooperatives,
    getGroups,
    columnDefs,
    defaultColDef,
    donwloadExcelUsers
  } = useGetUsersInfo()
  const { translate } = useTranslationsCRUD()
  const dispatch = useDispatch()

  const autoGroupColumnDef = {
    headerValueGetter: (params) => params.colDef.headerName,
    cellRendererParams: {
      /**
       * Inner rendering function that formats the cell value.
       * @param {Object} params - Cell parameters.
       * @param {Object} params.node - The cell node.
       * @param {string} params.node.key - The node key.
       * @param {Object} params.node.data - The node data.
       * @param {number} params.node.data.total - The total count of elements in the group.
       * @param {any} params.value - The cell value.
       * @returns {string} The formatted cell value.
       */
      innerRenderer: (params) => {
        const groupCount = params.node.data?.total
        let formattedValue = ''
        if (params.node.group) {
          const isDate = moment(params.value, 'YYYY-MM-DD', true).isValid()
          if (isDate) {
            formattedValue += `${moment(params.value).format('DD-MM-YYYY')}`
          } else {
            formattedValue += `${params.value || 'Sin grupo'}`
          }
        }
        if (groupCount) {
          formattedValue += ` (${groupCount})`
        }
        return formattedValue
      }
    }
  }

  const gridOptions = {
    overlayNoRowsTemplate: t('Sin datos'),
    columnDefs: translate(columnDefs),
    defaultColDef: defaultColDef,
    rowHeight: 40,
    rowGroupPanelShow: 'always',
    localeText: {
      groupContracted: t('Click para expandir'),
      rowGroupColumnsEmptyMessage: t('Arrastre columnas para agrupar'),
      loadingOoo: t('Cargando...'),
      copy: t('Copiar'),
      copyWithHeaders: t('Copiar con encabezados')
    },
    floatingFiltersWidth: 400,
    autoGroupColumnDef: autoGroupColumnDef
  }

  const clearFilters = () => {
    gridApi.setFilterModel(null)
    gridApi.onFilterChanged()
  }

  /**
   * Handles the "onGridReady" and sets pagination.
   * @param {Object} params - The grid parameters.
   * @returns {void}
   */
  const onGridReady = (params) => {
    setGridApi(params.api)
    params.api.addEventListener('paginationChanged', (event) => {
      // remove all rows with ag-loading class, leave one
      const rows = document.querySelectorAll('.ag-loading')
      if (rows.length > 1) {
        rows.forEach((row, index) => {
          if (index !== 0) {
            row.remove()
          }
        })
      }
    })

    /**
     * Updates the data and sets the server-side data source.
     * @param {Array} data - The data to update.
     * @returns {void}
     */
    const updateData = (data) => {
      let idSequence = 1
      data?.forEach(function (item) {
        item.id = idSequence++
      })
      const datasource = createDataSource(data)
      params.api.setServerSideDatasource(datasource)
    }

    updateData()

    // Change the filterParams of the "cooperativa" column
    const cooperativaColumn = params.columnApi.getColumn(
      'pharmacy__unnefars__nombreCooperativa'
    )
    const groupColumn = params.columnApi.getColumn(
      'pharmacy__cooperative__name'
    )

    // Retrieve Unnefar cooperatives and update the filterParams of cooperativaColumn
    getUnnefarCooperatives().then(({ data }) => {
      const newFilterParams = {
        ...cooperativaColumn.getColDef().filterParams,
        values: data.map(({ nombreCooperativa }) => nombreCooperativa),
        defaultToNothingSelected: true,
        buttons: ['reset']
      }
      cooperativaColumn.getColDef().filterParams = newFilterParams
      params.api.refreshCells({
        columns: ['pharmacy__unnefars__nombreCooperativa'],
        force: true
      })
    })

    // Retrieve groups and update the filterParams of groupColumn
    getGroups().then(({ data }) => {
      const newFilterParams = {
        ...groupColumn.getColDef().filterParams,
        values: data,
        defaultToNothingSelected: true,
        buttons: ['reset']
      }
      groupColumn.getColDef().filterParams = newFilterParams
      params.api.refreshCells({
        columns: ['pharmacy__cooperative__name'],
        force: true
      })
    })
  }

  /**
   * This function is used to create the datasource for the ag-grid in order to use server side pagination, sorting, and grouping
   * @param {any} server
   * @returns {object} datasource
   */
  const createDataSource = (server) => {
    return {
      getRows: (params) => {
        params.api.hideOverlay()

        let filters = {}
        let orderBy = null
        let groupBy = null
        // If there are filters, format them
        if (Object.keys(params.request.filterModel).length > 0) {
          if (params.request.filterModel.pharmacy__blocked) {
            let valuesBloqued =
              params.request.filterModel.pharmacy__blocked.values
            valuesBloqued =
              valuesBloqued.length === 1 ? valuesBloqued[0] === 'Bloqueada' : {}

            if (
              valuesBloqued &&
              typeof valuesBloqued === 'object' &&
              !Object.keys(valuesBloqued).length
            ) {
              // Si el valor es un objeto vacío, elimina la entrada pharmacy__blocked del filterModel
              delete params.request.filterModel.pharmacy__blocked
            } else {
              params.request.filterModel.pharmacy__blocked.values =
                valuesBloqued
            }
          }

          filters = formatFilters(params.request.filterModel)
        }

        if (params.request.groupKeys && params.request.groupKeys.length > 0) {
          // Filters for grouping
          filters = {
            ...filters,
            ...filterDataByGroupKeys(
              params.request.groupKeys,
              params.request.rowGroupCols
            )
          }

          // Group by
          if (params.request.rowGroupCols.length) {
            groupBy = onExpandGroup(params)
          }
        } else if (
          params.request.rowGroupCols.length >= 1 &&
          params.request.groupKeys.length === 0
        ) {
          groupBy = params.request.rowGroupCols[0].field
        }

        if (params.request.sortModel.length > 0 && !groupBy) {
          // If there is a sort, format it
          let sortField = params.request.sortModel[0].colId
          const [first, second, third] = sortField.split('__')
          if (first && dbTables[first]) {
            sortField = dbTables[first]
          }

          if (second && dbTables[second]) {
            sortField = `${dbTables[first]}.${dbTables[second]}`
          } else if (second && dbTables[first]) {
            sortField = `${dbTables[first]}.${second}`
          }

          if (third) {
            sortField = `${sortField}.${third}`
          }

          orderBy = {
            order_field: sortField,
            order_direction: params.request.sortModel[0].sort
          }
        } else if (!groupBy) {
          orderBy = {
            order_field: 'name',
            order_direction: 'asc'
          }
        }

        let page = 1
        if (params.request.startRow > 0) {
          page = params.request.startRow / 25 + 1
        }

        setExcelParams(
          Object.keys(filters).length > 0
            ? {
                filter: { ...filters }
              }
            : null,
          { ...orderBy }
        )

        getAllUsers(
          {
            per_page: params.request.endRow - params.request.startRow,
            page: page
          },
          Object.keys(filters).length > 0
            ? {
                filter: { ...filters }
              }
            : null,
          { ...orderBy },
          groupBy && groupBy
        ).then((res) => {
          if (res.data.length === 0) {
            params.failCallback()
            params.api.showNoRowsOverlay()
          }

          // update the rows with new data
          params.success({
            rowData: res.data,
            rowCount: res.pagination.total
          })
        })
      }
    }
  }

  /**
   * This function takes the groupKeys and rowGroupCols from request and returns group by the next column.
   * @param {Ag Grid} params
   * @returns  {string} groupBy
   */
  const onExpandGroup = (params) => {
    const request = params.request
    const parentNode = params.parentNode
    let groupBy = null
    if (parentNode) {
      const level = Number(parentNode.level) + 1
      if (level < request.rowGroupCols.length) {
        groupBy = request.rowGroupCols[level].field
      }
    }
    return groupBy
  }

  /**
   * Auto group column configuration.
   * @type {AutoGroupColumnDef}
   */

  const getContextMenuItems = () => {
    return [
      'copy',
      'copyWithHeaders',
      'separator',
      {
        name: t('Exportar a Excel'),
        action: () => {
          exportDataAsExcel()
        },
        icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>'
      }
    ]
  }

  /**
   * This function is used to export the data as excel.
   * @returns {void}
   * @async
   */

  const exportDataAsExcel = async () => {
    setBtnText('generando')
    // get visible columns
    const columns = gridApi?.columnModel?.columnApi?.getAllDisplayedColumns()
    const columnHeaders = Object.fromEntries(
      columns
        .map(({ userProvidedColDef: item }) => {
          // item.databaseField can't be undefined
          if (item?.databaseField) {
            return [item.databaseField, item.headerName]
          }

          return null
        })
        .filter(Boolean)
    )

    const { success, data } = await donwloadExcelUsers({
      ...excelParams,
      columns: columnHeaders
    })
    if (success) {
      setBtnText('descargando')
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
      })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'usuarios.xlsx'
      link.click()
      setTimeout(() => {
        setBtnText('exportar')
      }, 2000)
    } else {
      setBtnText('exportar')
    }
  }

  return (
    <div className='ag-theme-alpine' style={{ height: 'calc(100vh - 200px)' }}>
      <div className='tw-mb-2 tw-flex tw-gap-4'>
        <Button
          label={t('Limpiar filtros')}
          uppercase
          mode='secondary'
          onClick={clearFilters}
        />
        <Button
          label={btnStatus[btnText]}
          uppercase
          mode='secondary'
          onClick={exportDataAsExcel}
          disabled={btnText !== 'exportar'}
        />
      </div>
      <AgGridReact
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowModelType='serverSide'
        paginationPageSize={25}
        cacheBlockSize={25}
        pagination={true}
        onRowClicked={(e) => {
          const clickedNode = e.node

          if (!clickedNode.group) {
            dispatch(actionAddUserTab(e?.data))
          }
        }}
        serverSideSortingAlwaysResets={true}
        blockLoadDebounceMillis={500}
        maxConcurrentDatasourceRequests={2}
        serverSideStoreType='partial'
        groupDisplayType='multipleColumns'
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

export default UsersAggridList
