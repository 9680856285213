import {
  ADD_USER_TAB,
  CLEAR_EDIT_USER_FIELD,
  CLOSE_TAB,
  SET_EDITING_USER_FIELD,
  SET_EDIT_USER_FIELD,
  SET_NAME,
  SET_TAB_INDEX,
  SET_USER_DATA
} from '../constants/userTab'

/**
 * @author jcruz
 * @param {array} user
 * @returns {object}
 */
export const actionAddUserTab = (user) => ({
  type: ADD_USER_TAB,
  user
})

/**
 * @author jcruz
 * @param {number} index
 * @returns {object}
 */
export const actionCloseTab = (userId) => ({
  type: CLOSE_TAB,
  userId
})

/**
 * @author jcruz
 * @param {number} index
 * @returns {object}
 */
export const actionSetTabIndex = (index) => ({
  type: SET_TAB_INDEX,
  index
})

/**
 * @author jcruz
 * @param {number} index
 * @returns {object}
 */
export const actionSetEditUserField = (changesUserField) => ({
  type: SET_EDIT_USER_FIELD,
  changesUserField
})
/**
 * @author jcruz
 * @param {boolean} edit
 * @returns {object}
 */
export const actionSetEdititingUserField = (userId, isEditing) => ({
  type: SET_EDITING_USER_FIELD,
  userId,
  isEditing
})

/**
 * @author jcruz
 * @param {boolean} edit
 * @returns {object}
 */
export const actionSetUserData = (userId, userData) => ({
  type: SET_USER_DATA,
  userId,
  userData
})

/**
 * @author jcruz
 * @param {string} name
 * @returns {object}
 */
export const actionSetName = (userId, name) => ({
  type: SET_NAME,
  userId,
  name
})
