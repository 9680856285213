import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import Paragraphs from '../../../../components/Paragraphs'
import useFlashMessage from '../../../../infrastructure/hooks/useFlashMessage'
import moment from 'moment'

const VerifyEmailModal = ({
  onRequestClose,
  personalData,
  loading,
  id,
  setUserData,
  updateUser
}) => {
  const { t } = useTranslation()
  const { showMessage } = useFlashMessage()
  const { userPersonalData } = personalData

  /**
   * This function is used to verify the email of the user
   * @returns
   */
  const onSubmit = async () => {
    // call api to send email to verify
    const { success, data, error } = await updateUser({
      admin: true,
      id: id,
      email_verified_at: moment().format('YYYY-MM-DD HH:mm:ss')
    })

    if (success && data) {
      userPersonalData.forEach((data) => {
        if (data.databaseField === 'email') {
          data.verify = true
          data.tooltip = false
        }
      })

      setUserData({
        ...personalData,
        userPersonalData
      })

      showMessage({
        type: 'success',
        message: t('Se ha verificado el email correctamente')
      })
      onRequestClose()
      return
    }

    if (error) {
      showMessage({
        type: 'alert',
        message: t(error?.errors?.email[0] || 'Error al verificar el email')
      })
    }
  }

  return (
    <Modal
      onRequestClose={onRequestClose}
      textAccept={t('Verificar email')}
      title={t('Verificación de email')}
      onAccept={() => {
        onSubmit()
      }}
      size='md'
      loading={loading}
    >
      <div className='tw-p-3'>
        <div className='tw-flex tw-gap-2 tw-justify-start'>
          <Paragraphs size='xl' className='tw-pt-5'>
            {t('Desea verificar este email')}:{' '}
            {
              userPersonalData?.find((data) => data?.databaseField === 'email')
                ?.value
            }
            ?
          </Paragraphs>
        </div>
      </div>
    </Modal>
  )
}

export { VerifyEmailModal }

VerifyEmailModal.propTypes = {
  personalData: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setUserData: PropTypes.func.isRequired
}
