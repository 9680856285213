import useMutation from '../useMutation'

export const usePharmacy = () => {
  const [fetchPharmacy] = useMutation('pharmacies')
  const [fetchMetadata] = useMutation('metadata')
  const updatePharmacy = async (pharmacyData) => {
    const { success, data, error } = await fetchPharmacy({
      method: 'PUT',
      data: pharmacyData,
      addToURL: `/${pharmacyData?.id}`
    })
    return { success, data, error }
  }
  const updateMetadata = async (pharmacyData) => {
    const { success, data, error } = await fetchMetadata({
      method: 'PUT',
      data: pharmacyData,
      addToURL: `/${pharmacyData?.id}`
    })
    return { success, data, error }
  }

  return {
    updatePharmacy,
    updateMetadata
  }
}
