import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AgGridReact } from 'ag-grid-react'
import { useNavigate } from 'react-router-dom'
import useTranslationsCRUD from '../../../../infrastructure/hooks/useTranslationsCRUD'
import useFlashMessage from '../../../../infrastructure/hooks/useFlashMessage'
import { formatFilters } from '../../../../infrastructure/utils/helperFunctions'
import { defaultColDef, columnDefs } from './constants/constants'

const TranslationsAggrid = () => {
  const [gridApi, setGridApi] = useState(null)
  const navigate = useNavigate()
  const { showMessage } = useFlashMessage()
  const { t } = useTranslation()
  const { loading, updateTranslation, getTranslations } = useTranslationsCRUD()

  /**
   * Translate columns
   * @type {array}
   */
  const translateColumnDefs = columnDefs.map((column) => ({
    ...column,
    headerName: t(column.headerName)
  }))

  const onGridReady = (params) => {
    setGridApi(params.api)
    params.api.hideOverlay()
    params.api.setDomLayout('autoHeight')
    params.api.setDomLayout('normal')
    params.api.sizeColumnsToFit()

    const updateData = (data) => {
      let idSequence = 1
      data?.forEach(function (item) {
        item.id = idSequence++
      })
      const datasource = createDataSource(data)
      params.api.setServerSideDatasource(datasource)
    }

    // add eventlistener to cellEditingStopped
    params.api.addEventListener('cellEditingStopped', async (event) => {
      updateTranslations(event)
    })

    getTranslations(params).then((res) => {
      updateData(res.data)
    })
  }

  const createDataSource = (server) => {
    return {
      getRows: async (params) => {
        let filters = null
        let orderBy = null

        if (params.request.filterModel) {
          filters = formatFilters(params.request.filterModel)
        }

        if (Object.keys(filters).length > 0) {
          // renanme key which includes _value to value
          Object.keys(filters).forEach((key) => {
            if (key.includes('_value')) {
              const newKey = key.split('_')[1]
              filters[newKey] = filters[key]
              delete filters[key]
            }
          })
        }

        if (params.request.sortModel.length > 0) {
          const orderField = params.request.sortModel[0].colId.replace(
            /^(es|ca|ga|eu)_/,
            ''
          )

          orderBy = {
            order_field: orderField,
            order_direction: params.request.sortModel[0].sort
          }

          filters = {
            ...filters,
            order_field: orderField,
            order_direction: params.request.sortModel[0].sort
          }
        } else {
          orderBy = {
            order_field: 'key',
            order_direction: 'asc'
          }
        }

        let page = 1
        if (params.request.startRow > 0) {
          page = params.request.startRow / 25 + 1
        }

        const response = await getTranslations(
          {
            per_page: params.request.endRow - params.request.startRow,
            page: page
          },
          Object.keys(filters).length > 0
            ? {
                filters: { ...filters }
              }
            : null,
          orderBy
        )
        if (response.data) {
          params.success({
            rowData: response.data,
            rowCount: response.pagination.total
          })
        }
      }
    }
  }

  /**
   * This function updates the translation value in the database
   * @param {aggrid event object} event
   * @async
   * @returns void
   *
   * @author 3rChuss
   */
  const updateTranslations = async (event) => {
    const { column, data, newValue, oldValue } = event
    const { colId } = column
    // match colId first 2 letters with language code
    const languageCode = colId.substring(0, 2)
    // get language id from data
    const languageId = data[`${languageCode}_id`]

    if (newValue === oldValue) return

    await updateTranslation(languageId, { value: newValue })
    showMessage({
      type: 'success',
      message: t('La traducción ha sido actualizada correctamente')
    })
  }

  /**
   * This function navigates to the edit translation page
   * @param {aggrid event} event
   * @returns void
   *
   * @author 3rChuss
   */
  const onEditTranslation = (event) => {
    const { column, data } = event
    if (column.colId === 'key') {
      navigate(`/translations/${data.id}/edit`, {
        state: { data: data }
      })
    }
  }

  return (
    <div className='ag-theme-alpine' style={{ height: 'calc(100vh - 150px)' }}>
      <AgGridReact
        defaultColDef={defaultColDef}
        columnDefs={translateColumnDefs}
        onGridReady={onGridReady}
        rowModelType='serverSide'
        paginationPageSize={25}
        cacheBlockSize={25}
        pagination={true}
        gridOptions={{ suppressCellSelection: true }}
        serverSideSortingAlwaysResets={true}
        blockLoadDebounceMillis={500}
        maxConcurrentDatasourceRequests={2}
        onCellDoubleClicked={(event) => onEditTranslation(event)}
        serverSideStoreType='partial'
        localeText={{
          loadingOoo: t('Cargando...')
        }}
        rowHeight='40'
      />
    </div>
  )
}

export default TranslationsAggrid
