/**
 *
 * @author ehernandez
 * @param {*} mode
 * @param {*} rounded
 * @param {*} uppercase
 * @returns {React.Component}
 */
export const useBtn = ({ mode, rounded, uppercase }) => {
  /**
   * Ontienen las clases por defecto segun el modo
   * @author   ehernandez --> nombre del creador
   * @param    {boolean} disabled
   * @return   {string}
   */
  const classFromMode = (disabled) => {
    let classes =
      'tw-flex tw-items-center tw-justify-center tw-px-6 tw-py-3 tw-w-full '

    switch (mode) {
      case 'primary':
        classes += `tw-bg-primary tw-opacity-90 ${
          !disabled && 'hover:tw-opacity-100'
        } tw-text-white`
        break

      case 'secondary':
        classes += 'tw-bg-secondary tw-text-white'
        break

      case 'transparent':
        classes += `tw-bg-transparent ${
          !disabled && 'hover:tw-bg-secondary hover:tw-text-white'
        } tw-text-secondary tw-border-2 tw-border-secondary`
        break

      case 'transparent-white-border':
        classes += `tw-bg-transparent ${
          !disabled &&
          'hover:tw-bg-white hover:tw-bg-opacity-10 hover:tw-text-white'
        } tw-text-primary tw-border-2 tw-border-white`
        break

      case 'expansible':
        classes +=
          'tw-bg-transparent tw-text-primary tw-border-l tw-border-t tw-border-r tw-border-primary tw-text-secondary expansible'
        break

      default:
        break
    }

    if (rounded) {
      classes += ' tw-rounded-full'
    }

    if (disabled) {
      classes += ' tw-bg-opacity-50'
    }

    if (uppercase) {
      classes += ' tw-uppercase'
    }

    return classes
  }

  return { classFromMode }
}
