import React from 'react'
import PropTypes from 'prop-types'

const ProductImage = ({ src }) => {
  return (
    <div className='cover__img tw-flex tw-h-[80px] tw-w-[80px] tw-items-center tw-justify-center'>
      <img src={src} />
    </div>
  )
}

ProductImage.propTypes = {
  src: PropTypes.string.isRequired
}

export { ProductImage }
