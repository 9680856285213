import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../../../components/Paragraphs'
import moment from 'moment'
import ProductDetails from '../../../../components/ProducDetail'

const LogDetails = ({ log }) => {
  const switchComponent = ({ data, key }) => {
    switch (key) {
      case 'order':
        return <ProductDetails data={data} />

      default:
        break
    }
  }

  return (
    <div className='tw-p-6'>
      {Object.keys(log)?.map((key) => {
        if (key !== 'sample_info') {
          return (
            <div
              key={key}
              className='tw-flex tw-w-full tw-border-b tw-border-b-2'
            >
              <div className='tw-w-1/5'>
                <Paragraphs size='lg'>{key}:</Paragraphs>
              </div>
              <div className='tw-w-4/5'>
                <Paragraphs
                  size='lg'
                  weight='bold'
                  className='tw-w-full tw-break-words'
                >
                  {key.includes('id')
                    ? log[key] || '-'
                    : moment(log[key]).isValid()
                    ? moment(log[key]).format('DD-MM-YYYY, HH:mm:ss')
                    : log[key]
                    ? log[key]
                    : '-'}
                </Paragraphs>
              </div>
            </div>
          )
        } else {
          return log[key] && switchComponent(log[key])
        }
      })}
    </div>
  )
}

LogDetails.propTypes = {
  log: PropTypes.object.isRequired
}

export default LogDetails
