import {
  SET_TOKEN_SUCCESS,
  SET_USER_SUCCESS,
  SET_USER_FAVORITES,
  USER_TAB_PROFILE,
  SET_USER_SESSION_EXPIRED
} from '../constants/user'

/**
 * @author ehernandez
 * @param {object} user object to be saved
 * @returns {object}
 */
export const actionSetLoginSuccess = (user) => ({
  type: 'USER_LOGIN_SUCCESS',
  user
})

/**
 * @author ehernandez
 * @param {object} user object to be saved
 * @returns {object}
 */
export const actionSetUserSuccess = (user) => ({
  type: SET_USER_SUCCESS,
  user
})

/**
 * @author ehernandez
 * @returns {object}
 */
export const actionUserLogout = () => ({
  type: 'USER_LOGOUT'
})

/**
 * @author ehernandez
 * @param {strig} token token user
 * @param {strig} user id user
 * @param {strig} isAdmin if user backoffice or is user sfera
 * @returns {object}
 */
export const actionSetTokenSuccess = (token, user) => ({
  type: SET_TOKEN_SUCCESS,
  token,
  user
})

/**
 * @author mromero
 * @param {array} favorites
 * @returns {object}
 */
export const actionSetUserFavorites = ({ favorites, totalFavorites }) => ({
  type: SET_USER_FAVORITES,
  favorites,
  totalFavorites
})

export const actionSetUserSessionExpired = (userSessionExpired) => ({
  type: SET_USER_SESSION_EXPIRED,
  userSessionExpired
})
