export const columnDefs = [
  {
    headerName: 'Usuario',
    field: 'username',
    databaseField: 'username',
    filter: 'agTextColumnFilter',
    editable: false,
    width: 150
  },
  {
    headerName: 'Código Almacén',
    field: 'codigoAlmacen',
    databaseField: 'codigoAlmacen',
    filter: 'agTextColumnFilter',
    editable: false,
    width: 150
  },
  {
    headerName: 'Código Cooperativa',
    field: 'codigoCooperativa',
    databaseField: 'codigoCooperativa',
    filter: 'agTextColumnFilter',
    editable: false,
    width: 150
  },
  {
    headerName: 'Código Farmacia Cooperativa',
    field: 'codigoFarmaciaCooperativa',
    databaseField: 'codigoFarmaciaCooperativa',
    filter: 'agTextColumnFilter',
    editable: false,
    width: 200
  },
  {
    headerName: 'Código Farmacia Cooperativa',
    field: 'codigoFarmaciaCooperativa',
    databaseField: 'codigoFarmaciaCooperativa',
    filter: 'agTextColumnFilter',
    editable: false,
    width: 200
  },
  {
    headerName: 'Nombre',
    field: 'name',
    databaseField: 'name',
    filter: 'agTextColumnFilter',
    editable: true,
    width: 150
  },
  {
    headerName: 'Nif',
    field: 'nif',
    databaseField: 'nif',
    filter: 'agTextColumnFilter',
    editable: true,
    width: 150
  },
  {
    headerName: 'Nombre Almacén',
    field: 'nombreAlmacen',
    databaseField: 'nombreAlmacen',
    filter: 'agTextColumnFilter',
    editable: true,
    width: 200
  },
  {
    headerName: 'Nombre Cooperativa',
    field: 'nombreCooperativa',
    databaseField: 'nombreCooperativa',
    editable: true,
    width: 200,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [],
      defaultToNothingSelected: true
    }
  },
  {
    headerName: 'Estado',
    field: 'status',
    databaseField: 'status',
    filter: 'agSetColumnFilter',
    editable: true,
    width: 150,
    filterParams: {
      values: ['approved', 'not_approved', 'pending'],
      defaultToNothingSelected: true
    }
  },
  {
    headerName: 'Reparto',
    field: 'reparto',
    databaseField: 'reparto',
    filter: 'agTextColumnFilter',
    editable: true,
    width: 150
  },
  {
    headerName: 'Ruta',
    field: 'ruta',
    databaseField: 'ruta',
    filter: 'agTextColumnFilter',
    editable: true,
    width: 150
  }
]
const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]
const currentYear = new Date().getFullYear()
const years = Array.from(
  { length: currentYear - 2020 },
  (_, index) => 2021 + index
)

export const columnDefsSales = [
  {
    headerName: 'Año',
    field: 'year',
    width: 150,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: years,
      defaultToNothingSelected: true,
      defaultOption: [currentYear]
    }
  },
  {
    headerName: 'Mes',
    field: 'month', // Reemplaza 'mes' con el nombre del campo correspondiente en tus datos
    width: 150,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      cellRenderer: (params) => {
        return monthNames[params.value - 1] ?? params.value
      },
      comparator: (a, b) => {
        const valA = parseInt(a)
        const valB = parseInt(b)
        if (valA === valB) return 0
        return valA > valB ? 1 : -1
      },
      cellEditor: 'agSelectCellEditor',
      defaultToNothingSelected: true
    },
    cellRenderer: (params) => {
      return monthNames[params.value - 1] ?? params.value
    }
  },
  {
    headerName: 'Tipo de cliente BO',
    field: 'app',
    width: 150,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: ['Fedefarma', 'Vadefarma'],
      defaultToNothingSelected: true
    }
  },
  {
    headerName: 'Grupo',
    field: 'grup',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: ['Fedefarma', 'Unnefar', 'No provincia'],
      defaultToNothingSelected: true
    },
    width: 200
  },
  {
    headerName: 'Cooperativa',
    field: 'cooperative',
    width: 200,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [],
      defaultToNothingSelected: true
    }
  },
  {
    headerName: 'Num Pedidos',
    field: 'orders',
    width: 150
  },
  {
    headerName: 'Unidades Vendidas',
    field: 'quantitat',
    width: 200
  }
]

export const defaultColDef = {
  sortable: true,
  resizable: true,
  floatingFilter: true,
  tooltipShowDelay: 10
}

export const unnefarDetailFields = [
  {
    label: 'Usuario',
    field: 'username',
    databaseField: 'username'
  },
  {
    label: 'Codigo Almacen',
    field: 'codigoAlmacen',
    databaseField: 'codigoAlmacen'
  },
  {
    label: 'Codigo Cooperativa',
    field: 'codigoCooperativa',
    databaseField: 'codigoCooperativa'
  },
  {
    label: 'Codigo Farmacia Cooperativa',
    field: 'codigoFarmaciaCooperativa',
    databaseField: 'codigoFarmaciaCooperativa'
  },
  {
    label: 'Nombre',
    field: 'name',
    databaseField: 'name'
  },
  {
    label: 'Nif',
    field: 'nif',
    databaseField: 'nif'
  },
  {
    label: 'Nombre Almacen',
    field: 'nombreAlmacen',
    databaseField: 'nombreAlmacen'
  },
  {
    label: 'Nombre Cooperativa',
    field: 'nombreCooperativa',
    databaseField: 'nombreCooperativa'
  },
  {
    label: 'Estado',
    field: 'status',
    databaseField: 'status'
  },
  {
    label: 'Reparto',
    field: 'reparto',
    databaseField: 'reparto'
  },
  {
    label: 'Ruta',
    field: 'ruta',
    databaseField: 'ruta'
  }
]

export const reportSales = [
  {
    label: 'Año',
    field: 'year',
    databaseField: 'order_year'
  },
  {
    label: 'Mes',
    field: 'month',
    databaseField: 'order_month'
  },
  {
    label: 'Tipo de cliente BO',
    field: 'app',
    databaseField: 'app'
  },
  {
    label: 'Grupo',
    field: 'grup',
    databaseField: 'grup'
  },
  {
    label: 'Cooperativa',
    field: 'cooperative',
    databaseField: 'cooperative'
  },
  {
    label: 'Num Pedidos',
    field: 'orders', // Reemplaza 'numComandas' con el nombre del campo correspondiente en tus datos
    databaseField: 'orders'
  },
  {
    label: 'Unidades Vendidas',
    field: 'quantitat', // Reemplaza 'unidadesVendidas' con el nombre del campo correspondiente en tus datos
    databaseField: 'quantitat'
  }
]
