import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'

const Select = ({ options, value, setValue, handleSave }) => {
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [filter, setFilter] = useState(value)
  const [showOptions, setShowOptions] = useState(false)
  const [enableFilter, setEnableFilter] = useState(false)

  const handleFilterChange = (event) => {
    const searchTerm = event.target.value
    const filtered = options.filter(({ name }) =>
      name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilter(searchTerm)
    setFilteredOptions(filtered)
    setShowOptions(true)
    setEnableFilter(true)
  }

  const toggleOptions = (show) => {
    setFilter(value)
    setShowOptions(show)
  }

  useEffect(() => {
    const filtered = options?.filter(({ name }) =>
      name.toLowerCase().includes(filter?.toLowerCase())
    )
    setFilteredOptions(enableFilter ? filtered : options)
  }, [options, filter])

  useEffect(() => {
    handleSave()
  }, [value])

  return (
    <div className='tw-relative tw-w-full'>
      <input
        type='text'
        value={filter}
        onChange={handleFilterChange}
        placeholder={t('Buscar')}
        className='tw-appearance-none tw-bg-white tw-border tw-border-gray-300 tw-w-full tw-px-2 tw-rounded tw-shadow tw-gap-2 tw-max-w-[200px] focus:tw-outline-none focus:tw-shadow-outline'
        onFocus={() => toggleOptions(true)}
      />
      <div className='tw-cursor-pointer tw-absolute tw-inset-y-0 tw-top-1 tw-right-0 tw-flex tw-items-center tw-px-2 tw-text-gray-700'>
        <svg
          className='tw-fill-current tw-h-4 tw-w-4'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          onClick={() => toggleOptions(!showOptions)}
        >
          <path fillRule='evenodd' d='M10 12l-6-6h12l-6 6z' />
        </svg>
      </div>
      {showOptions && (
        <div className='tw-absolute tw-left-0 tw-right-0 tw-bg-white tw-border tw-border-gray-300 tw-shadow tw-rounded-b tw-max-h-28 w-full tw-overflow-y-auto tw-z-10'>
          {filteredOptions.map(({ id, name }) => (
            <div
              key={id}
              onClick={() => {
                setValue(name)
                setFilter(name)
                setShowOptions(false)
                setEnableFilter(false)
              }}
              className='tw-px-2 tw-py-2 hover:tw-bg-gray-200 tw-cursor-pointer'
            >
              {name}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

Select.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
}

export default Select
