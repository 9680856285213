import axios from 'axios'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from '../../../../infrastructure/hooks/useMutation/useMutation'
import { actionSetTokenSuccess } from '../../../../infrastructure/redux/actions/user'
import { API, BASE } from '../../../../infrastructure/constants/configurations'

const URL_BASE = process.env.REACT_APP_BASE_API || BASE + API
/**
 * @description Hook to get the login state
 * @author ehernandez
 * @returns {Object}
 */
export const useLogin = () => {
  const [fetchLogin] = useMutation('login')
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  /**
   * @author ehernandez
   * @param {Object} user
   * @returns {Object}
   */
  const onLogin = async (user) => {
    setLoading(true)
    const { data, success, error } = await fetchLogin({
      data: user
    })

    if (success) {
      const response = axios.get(`${URL_BASE}user/${data?.user?.id}/roles`, {
        headers: {
          Authorization: `Bearer ${data?.access_token}`
        }
      })
      const { data: roles } = await response
      if (data) {
        dispatch(
          actionSetTokenSuccess(data?.access_token, {
            ...data?.user,
            roles: roles?.data?.items
          })
        )
      }
    }
    setLoading(false)
    return {
      success,
      data,
      error
    }
  }

  return { onLogin, loading }
}
