import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import PasswordStrengthBar from 'react-password-strength-bar'
import Paragraphs from '../../../../components/Paragraphs'
import { INPUTS } from './constants/constants'
import Modal from '../../../../components/Modal'
import TextInput from '../../../../components/TextInput'
import Button from '../../../../components/Button'
import useUsersManagement from '../../../../infrastructure/hooks/useUsersManagement'
import useFlashMessage from '../../../../infrastructure/hooks/useFlashMessage'

const ChangePasswordModal = ({ personalData, id, onRequesClose, isAdmin }) => {
  const { t } = useTranslation()
  const { updateUser, loading } = useUsersManagement()
  const { showMessage } = useFlashMessage()
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm()
  const password = useRef({})
  password.current = watch('password', '')
  const [passwd, setPassword] = useState('')

  /**
   * Sets the password value.
   * @param {string} value
   */
  const onChangeInput = (value) => {
    setPassword(value)
  }
  /**
   * Handles the form submission by updating the user data.
   * @async
   * @param {Object} e - the form values.
   * @returns {Promise<void>}
   */
  const onSubmit = async (e) => {
    const newUserData = {
      id: id,
      admin: isAdmin,
      ...e
    }

    const { success, error } = await updateUser(newUserData)

    if (success) {
      showMessage({
        type: 'success',
        message: t('La contraseña se ha cambiado correctamente')
      })
      onRequesClose()
      return
    }
    showMessage({
      type: 'alert',
      message: t(error?.errors?.password[0] || 'Error al cambiar la contraseña')
    })
  }

  return (
    <Modal
      onRequestClose={onRequesClose}
      textAccept={t('Cambiar contraseña')}
      title={t('Cambio de contraseña')}
      onAccept={onSubmit}
      hideAcceptButton
      size='lg'
    >
      <div className='tw-p-3 tw-h-96'>
        <div className='tw-flex tw-gap-2'>
          <Paragraphs size='lg'>
            {t('Vas a cambiar la contraseña del usuario')}:
          </Paragraphs>
          <Paragraphs size='lg' weight='bold'>
            {`${
              personalData?.find((data) => data?.label === 'Nombre')?.value +
              ' ' +
              personalData?.find((data) => data?.label === 'Apellidos')?.value
            } (id: ${id})`}
          </Paragraphs>
        </div>

        <form
          id='changePasswordForm'
          onSubmit={handleSubmit(onSubmit)}
          className='tw-w-full tw-flex tw-items-center tw-align-middle tw-gap-4 tw-justify-start tw-pt-5'
        >
          {INPUTS?.map((i) => (
            <div key={i.id} className='tw-flex tw-w-64 tw-items-start tw-h-20'>
              <div>
                <TextInput
                  type='text'
                  width='64'
                  id={i.id}
                  required={i.required}
                  label={t(i.label)}
                  register={register(i.name, {
                    onChange:
                      i.id === 'password' &&
                      ((e) => {
                        onChangeInput(e.target.value)
                      }),
                    required: i.required && {
                      value: true,
                      message: `${t(i.label)} ${t('es requerido')}`
                    },
                    minLength: {
                      value: i.minLength?.value,
                      message: i?.minLength?.message
                    },
                    validate:
                      i.validate === 'password_confirmation'
                        ? (value) =>
                            value === password.current ||
                            t('Las contraseñas no coinciden')
                        : null
                  })}
                  description={t(i.description)}
                  error={t(errors[i.name]?.message)}
                />
                {i.id === 'password' && (
                  <div className='tw-mt-[-68px] tw-mb-[68px]'>
                    <PasswordStrengthBar
                      password={passwd}
                      shortScoreWord=''
                      scoreWords={['']}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
          <div className='tw-h-full tw-pt-5 tw-self-start'>
            <Button
              size='full'
              id='action-change-password'
              label={t('Cambiar la contraseña')}
              uppercase
              type='submit'
              disabled={loading}
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}

ChangePasswordModal.propTypes = {
  personalData: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired,
  onRequesClose: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
}

export default ChangePasswordModal
