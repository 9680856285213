import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

const Carousel = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const carouselRef = useRef(null)

  // autoplay
  useEffect(() => {
    const interval = setInterval(() => {
      if (isTransitioning) return
      setCurrentIndex((currentIndex) => (currentIndex + 1) % children.length)
    }, 5000)

    return () => clearInterval(interval)
  }, [isTransitioning, children.length])

  useEffect(() => {
    if (children.length === 1) setCurrentIndex(0)
    else setIsTransitioning(false)
  }, [children.length])

  const handlePrevClick = () => {
    if (isTransitioning) return
    setIsTransitioning(true)
    setCurrentIndex(currentIndex - 1)
  }

  const handleNextClick = () => {
    if (isTransitioning) return
    setIsTransitioning(true)
    setCurrentIndex(currentIndex + 1)
  }

  const handleTransitionEnd = () => {
    setIsTransitioning(false)
  }

  return (
    <div
      className='tw-relative tw-w-full tw-h-full'
      ref={carouselRef}
      onMouseEnter={() => setIsTransitioning(false)}
      onMouseLeave={() => setIsTransitioning(true)}
    >
      {children.length > 1 && (
        <div className='tw-flex tw-justify-between tw-items-center tw-absolute tw-top-1/2 tw-left-0 tw-right-0 tw-transform tw--translate-y-1/2 tw-z-10'>
          <button
            className='tw-bg-gray-200 tw-rounded-full tw-w-8 tw-h-8 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-duration-300 tw-ease-in-out hover:tw-bg-gray-300'
            onClick={handlePrevClick}
            disabled={currentIndex === 0}
          >
            <svg className='tw-w-4 tw-h-4 tw-fill-current' viewBox='0 0 24 24'>
              <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z' />
            </svg>
          </button>
          <div
            className='tw-text-gray-600 tw-tracking-wide tw-p-3'
            style={{
              fontSize: '5rem',
              lineHeight: '1.5rem',
              opacity: 0.5
            }}
          >
            {currentIndex + 1}
          </div>
          <button
            className='tw-bg-gray-200 tw-rounded-full tw-w-8 tw-h-8 tw-flex tw-justify-center tw-items-center tw-transition-colors tw-duration-300 tw-ease-in-out hover:tw-bg-gray-300'
            onClick={handleNextClick}
            disabled={currentIndex === children.length - 1}
          >
            <svg className='tw-w-4 tw-h-4 tw-fill-current' viewBox='0 0 24 24'>
              <path d='M8.59 16.59L10 18l6-6-6-6-1.41 1.41L13.17 12l-4.58 4.59z' />
            </svg>
          </button>
        </div>
      )}
      <div
        className='tw-flex tw-w-full tw-h-full'
        onTransitionEnd={handleTransitionEnd}
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: 'transform 0.5s ease-in-out'
        }}
      >
        {React.Children.map(children, (child, index) => (
          <div
            className='tw-w-full tw-h-full tw-flex-shrink-0'
            style={{
              width: '100%',
              visibility: index === currentIndex ? 'visible' : 'hidden'
            }}
          >
            {child}
          </div>
        ))}
      </div>
    </div>
  )
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired
}

export { Carousel }
