import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../../../components/Paragraphs'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'

const InformationTooltip = ({ value, isPharmacyInfo, index }) => {
  const [toolTipId] = useState(`tooltip-${Math.random() * 100}`)
  const { t } = useTranslation()
  const farmacyInfoText = () => {
    let text = ''
    switch (value) {
      case 'pending':
        text = t(
          'Farmacia no registrada en Fedefarma. Pendiente de gestionar el alta en el GIFF.'
        )
        break
      case 'approved':
        text = t('Farmacia registrada en Fedefarma, validada y activada.')
        break
      case 'not_approved':
        text = t(
          'Farmacia registrada en Fedefarma pero no activada, falta asignar almacén, ruta y/o riesgo.'
        )
        break
      default:
        break
    }
    return (
      <Paragraphs
        weight={'bold'}
      >{`${value.toUpperCase()}: ${text}`}</Paragraphs>
    )
  }
  const sepaInfoText = () => {
    let text = ''
    switch (value) {
      case 'completed':
        text = t(
          'El usuario completó correctamente el proceso SEPA en Signaturit o se obtuvieron los datos automáticamente desde Fedefarma.'
        )
        break
      case 'ready':
      case 'pending':
        text = t(
          'El usuario inició el proceso de firma de SEPA en el entorno de Signaturit, pero no lo finalizó.'
        )
        break
      default:
        break
    }
    return (
      <Paragraphs
        weight={'bold'}
      >{`${value.toUpperCase()}: ${text}`}</Paragraphs>
    )
  }

  return (
    <div>
      <a data-tip data-for={toolTipId}>
        <Paragraphs
          className='tw-border-1 tw-mb-0.5 tw-shadow-black tw-shadow-sm tw-border-black tw-border tw-rounded-full tw-px-1.5 tw-cursor-pointer tw-self-center'
          weight={'bold'}
          size={'xxs'}
        >
          i
        </Paragraphs>
      </a>
      <ReactTooltip
        className={'tw-w-64'}
        id={toolTipId}
        type={
          value === 'approved' || value === 'completed'
            ? 'success'
            : value === 'not_approved'
            ? 'error'
            : 'warning'
        }
        effect={'solid'}
      >
        <span>{isPharmacyInfo ? farmacyInfoText() : sepaInfoText()}</span>
      </ReactTooltip>
    </div>
  )
}

InformationTooltip.propTypes = {
  value: PropTypes.string.isRequired,
  isPharmacyInfo: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired
}

export default InformationTooltip
