import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import PropTypes from 'prop-types'
import { useTranslationsCRUD } from '../../../../infrastructure/hooks/useTranslationsCRUD/useTranslationsCRUD'
import useBanners from '../../../../infrastructure/hooks/useBanners'
import { useTranslation } from 'react-i18next'
import ImageCellRenderer from '../ImageCellRenderer'
import './styles.css'
import ActiveSlideAggrid from '../ActiveSlideAggrid'
import { formatFilters } from '../../../../infrastructure/utils/helperFunctions'
import Button from '../../../../components/Button'

const BannerAggridList = (props) => {
  const {
    setPageTabs,
    pageTabs,
    setTabIndex,
    handleNewBanner,
    selectedLayout
  } = props
  const { t } = useTranslation()
  const { getBannerList, bannerColumns, updateBanner } = useBanners()
  const { translate } = useTranslationsCRUD()

  /**
   * Grid ready event handler from ag-grid
   * @param {object} params
   * @returns {void}
   */
  const onGridReady = (params) => {
    // autositze columns
    params.api.sizeColumnsToFit()

    params.api.addEventListener('paginationChanged', (event) => {
      // remove all rows with ag-loading class, leave one
      const rows = document.querySelectorAll('.ag-loading')
      if (rows.length > 1) {
        rows.forEach((row, index) => {
          if (index !== 0) {
            row.remove()
          }
        })
      }
    })

    /**
     * This function is used to update the data in the ag-grid
     * it's called when the user changes the page or the sort
     * @param {object} data
     */
    const updateData = (data) => {
      const datasource = createDataSource(data)
      params.api.setServerSideDatasource(datasource)
    }

    updateData(params)
  }

  /**
   * This function is used to create the datasource for the ag-grid in order to use server side pagination and sorting
   * @param {any} server
   * @returns {object} datasource
   */
  const createDataSource = (server) => {
    return {
      getRows: (params) => {
        params.api.hideOverlay()
        let filter = {}
        if (params.request.filterModel) {
          if (params.request.filterModel.active) {
            filter = {
              active:
                params.request.filterModel.active.values.indexOf('Active') > -1
                  ? 0
                  : 1
            }
          } else {
            filter = formatFilters(params.request.filterModel)
          }
        }

        let orderBy = null
        if (params.request.sortModel.length > 0) {
          orderBy = {
            order_field: params.request.sortModel[0].colId,
            order_direction: params.request.sortModel[0].sort
          }
        }
        const page = Math.floor(params.request.startRow / 10) + 1

        getBannerList({
          per_page: params.request.endRow - params.request.startRow,
          page: page,
          ...(orderBy ? { order_by: orderBy } : null),
          ...(Object.keys(filter).length > 0
            ? {
                filter: { ...filter }
              }
            : null)
        }).then((res) => {
          if (res.data.length === 0) {
            params.failCallback()
            params.api.showNoRowsOverlay()
          }
          params.success({
            rowData: res.data,
            rowCount: res.pagination?.total_items || 0
          })
        })
      }
    }
  }

  // grid options
  const gridOptions = {
    overlayNoRowsTemplate: t('Sin datos'),
    columnDefs: translate(bannerColumns),
    defaultColDef: {
      sortable: true,
      resizable: true,
      floatingFilter: true
    },
    rowHeight: 80,
    animateRows: true
  }

  /**
   * Handle double click event on ag-grid
   * it's used to open a new tab with the banner details or to focus on an existing tab
   * @param {object} e - event object
   * @returns {void}
   */
  const handleDoubleClick = (e) => {
    // check if tab already exists
    const tabExists = pageTabs.find((tab) => tab.id === e.data.id)
    if (tabExists) {
      setTabIndex(pageTabs.indexOf(tabExists) + 1)
      return
    }

    setPageTabs([...pageTabs, e.data])
    setTabIndex(pageTabs.length + 1)
  }

  /**
   * Handle on cell clicked event on ag-grid
   * it's used to update the active status of the banner or to start editing the cell
   * @param {object} param - event object
   */
  const handleOnCellClicked = ({ api, data, node, colDef }) => {
    if (colDef.colId === 'active') {
      api.forEachNode((rowNode) => {
        if (rowNode.data.id === data.id) {
          node.setData({
            ...node.data,
            active: !node.data.active
          })
          updateBanner({ id: data.id, active: node.data.active })
        }
      })
    } else if (colDef.editable) {
      api.startEditingCell({
        rowIndex: node.rowIndex,
        colKey: colDef.field
      })
    }
  }

  /**
   * Handle on cell editing stopped event on ag-grid
   * it's used to update the banner data
   * @param {object} e - event object
   * @returns
   */
  const handleOnCellEditingStopped = (e) => {
    if (e.oldValue === e.newValue) {
      return
    }
    updateBanner({
      id: e.data.id,
      [e.colDef.field]: e.data[e.colDef.field]
    })
  }

  return (
    <div
      className='ag-theme-alpine tw-h-full banners-list'
      style={{ height: 'calc(100vh - 200px)' }}
    >
      <div className='tw-flex tw-gap-4 tw-w-full tw-my-2'>
        <Button
          onClick={() => {
            handleNewBanner()
          }}
          uppercase
          mode='secondary'
          label={t('Nuevo Banner')}
        />
      </div>
      <AgGridReact
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowModelType='serverSide'
        paginationPageSize={10}
        cacheBlockSize={10}
        pagination={true}
        onRowDoubleClicked={handleDoubleClick}
        serverSideSortingAlwaysResets={true}
        blockLoadDebounceMillis={500}
        maxConcurrentDatasourceRequests={2}
        onCellClicked={(e) => {
          handleOnCellClicked(e)
        }}
        onCellEditingStopped={(e) => {
          handleOnCellEditingStopped(e)
        }}
        serverSideStoreType='partial'
        suppressContextMenu={true}
        frameworkComponents={{
          imageCellRenderer: ImageCellRenderer,
          activeSlideAggrid: ActiveSlideAggrid
        }}
        localeText={{
          groupContracted: t('Click para expandir'),
          rowGroupColumnsEmptyMessage: t('Arrastre columnas para agrupar'),
          loadingOoo: t('Cargando...')
        }}
      />
    </div>
  )
}

BannerAggridList.propTypes = {
  setPageTabs: PropTypes.func,
  pageTabs: PropTypes.array,
  setTabIndex: PropTypes.func,
  handleNewBanner: PropTypes.func,
  selectedLayout: PropTypes.object
}

export default BannerAggridList
