import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Page from '../../../../components/Page'
import 'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'react-tabs/style/react-tabs.css'
import UnnefarListAggrid from '../../components/UnnefarListAggrid/UnnefarListAggrid'
import SalesDataAggrid from '../../components/SalesDataAggrid'
import './styles.css'

const ReportsScreen = () => {
  const { t } = useTranslation()

  const [tabs] = useState([
    {
      id: 0,
      name: t('Lista de unnefar')
    },
    {
      id: 1,
      name: t('Datos de ventas')
    }
  ])
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Page.Container>
      <Page.Header title={t('Reportes')}></Page.Header>
      <Page.Body>
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
          defaultFocus
        >
          <TabList>
            {tabs.length > 0 &&
              tabs.map((tab, index) => (
                <Tab key={index}>
                  <div
                    className={`tw-flex tw-gap-6 tw-justify-between ${
                      index !== tabIndex && 'tw-opacity-30'
                    }`}
                  >
                    {tab.name}
                  </div>
                </Tab>
              ))}
          </TabList>
          {tabs.length > 0 &&
            tabs.map((tab, index) => (
              <TabPanel forceRender key={index}>
                <div className='tw-h-full'>
                  {index === 1 ? (
                    <SalesDataAggrid t={t} />
                  ) : (
                    <UnnefarListAggrid t={t} />
                  )}
                </div>
              </TabPanel>
            ))}
        </Tabs>
      </Page.Body>
    </Page.Container>
  )
}
export default ReportsScreen
