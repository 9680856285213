import React from 'react'
import PropTypes from 'prop-types'
/**
 * @author ehernandez
 * @returns {React.Component}
 */
const Container = ({ name = '', className = '', children }) => {
  return (
    <div data-name={name} className={`page-container ${name} ${className}`}>
      {children}
    </div>
  )
}

Container.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  active: PropTypes.bool
}

export default Container
