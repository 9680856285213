export const bannerInputs = [
  {
    name: 'title',
    label: 'Título',
    type: 'text',
    placeholder: 'Título del banner',
    required: true,
    defaultValue: '',
    autoFocus: true,
    tabIndex: 1
  },
  {
    name: 'description',
    label: 'Descripción',
    type: 'text',
    placeholder: 'Descripción del banner',
    required: false,
    defaultValue: '',
    autoFocus: false,
    tabIndex: 2,
    width: 400
  }
]

export const typeOptions = [
  {
    value: 'slide',
    label: 'Slide (2:1)'
  },
  {
    value: 'banner',
    label: 'Banner 1 (1:1)'
  },
  {
    value: 'banner 2',
    label: 'Banner 2 (1:1)'
  },
  {
    value: 'banner XL',
    label: 'Banner XL (16:9)'
  }
]

export const bannersAssign = [
  {
    value: 'group',
    label: 'Grupo'
  },
  {
    value: 'none',
    label: 'Usar propio'
  },
  {
    value: 'default',
    label: 'Por defecto'
  }
]

export const bannersTypes = {
  default: 'default',
  group: 'group',
  self: 'none',
  cooperative: 'cooperative'
}
