import { useState } from 'react'
import useMutation from '../useMutation'

/**
 * Hook service for products
 * @returns {object} - { getProductsInfo: function, loading: boolean }
 */
export const useProducts = () => {
  const [loading, setLoading] = useState(false)
  const [getProductsInfoApi] = useMutation('products/query')

  /**
   * Gets product info from the API based on the ids provided
   * @param {string} ids  - comma separated string of product ids @example '123,456,789'
   * @returns {object} - { success: boolean, data: object, error: object }
   */
  const getProductsInfo = async (ids) => {
    setLoading(true)
    try {
      const { data } = await getProductsInfoApi({
        method: 'POST',
        data: {
          filter: {
            query: ids,
            page: 1,
            per_page: 100
          }
        }
      })

      if (data) {
        setLoading(false)
        return {
          success: true,
          data: data.data.products.items
        }
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
      return {
        success: false,
        error
      }
    }
  }

  return {
    getProductsInfo,
    loading
  }
}
