export const INPUTS = {
  login: [
    {
      id: 'email',
      type: 'email',
      label: 'Correo electrónico',
      name: 'email'
    },
    {
      id: 'password',
      type: 'password',
      label: 'Contraseña',
      name: 'password'
    }
  ],
  register: [
    {
      id: 'name',
      type: 'text',
      label: 'Nombre',
      name: 'name',
      required: true
    },
    {
      id: 'surnames',
      type: 'text',
      label: 'Apellidos',
      name: 'surnames',
      required: true
    },
    {
      id: 'email',
      type: 'text',
      label: 'Email',
      name: 'email',
      required: true
    },
    {
      id: 'password',
      type: 'password',
      label: 'Contraseña',
      name: 'password',
      required: true,

      minLength: {
        value: 10,
        message: 'La contraseña debe tener al menos 10 caracteres'
      },
      description:
        'La contraseña debe tener al menos 10 caracteres, una mayúscula, una minúscula, un número y un caracter especial'
    },
    {
      id: 'password_confirmation',
      type: 'password',
      label: 'Repite la contraseña',
      name: 'password_confirmation',
      minLength: {
        value: 10,
        message: 'La contraseña debe tener al menos 10 caracteres'
      },
      validate: 'password_confirmation',
      required: false
    }
  ]
}
