import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Paragraphs from '../Paragraphs'
import { AvatarContainer } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { actionUserLogout } from '../../infrastructure/redux/actions/user'
import { actionAddUserTab } from '../../infrastructure/redux/actions/userTab'
import { useNavigate } from 'react-router-dom'
import usePagesRoles from '../../modules/Auth/hooks/usePagesRoles'

/**
 * @author ehernandez
 * @param {name} name name user
 * @returns {React.Component}
 */
const Avatar = ({ name }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.userReducer)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { hasRequiredRoles } = usePagesRoles()

  const [showBtnProfile, setShowBtnProfile] = useState(false)
  const handleOpenProfile = () => {
    const { pathname } = window.location
    if (pathname !== '/users') {
      navigate('/users')
    }

    dispatch(actionAddUserTab(user))
  }

  useEffect(() => {
    const show = hasRequiredRoles('/users', user.roles)
    setShowBtnProfile(show)
  }, [])

  return (
    <AvatarContainer className='avatar tw-relative'>
      <div className='tw-flex tw-items-center tw-justify-center tw-h-full tw-w-full tw-z-10'>
        <div className='tw-flex tw-items-end tw-justify-center tw-h-full tw-w-full tw-z-10 tw-flex-col'>
          <Paragraphs size='xs' weight='bold' className='tw-p-0'>
            {user?.name} {user?.surnames} - {user?.role?.name}
          </Paragraphs>
          <Paragraphs size='xs' className='tw-p-0'>
            {user?.email}
          </Paragraphs>
        </div>

        <div className='avatar__image tw-cursor-pointer tw-text-gray-400 tw-ml-2 tw-mt-1'>
          <i className='material-icons'>account_circle</i>
        </div>
      </div>
      <div className='avatar__menu tw-h-full tw-w-full tw-mb-0'></div>

      <div className='avatar__menu tw-min-h-[50px] tw-bg-white tw-border-[1px] tw-border-[#b6c7d6] tw-w-[200px] tw-absolute tw-top-[39px] tw-right-0 tw-shadow'>
        {showBtnProfile && (
          <div
            className='avatar__menu__name tw-p-4 tw-cursor-pointer'
            onClick={() => handleOpenProfile()}
          >
            <Paragraphs weight='bold' size='xs'>
              {t('Ver perfil')}
            </Paragraphs>
          </div>
        )}
        <div
          className='avatar__menu__name tw-p-4 tw-cursor-pointer'
          onClick={() => dispatch(actionUserLogout())}
        >
          <Paragraphs weight='bold' className='tw-text-alert ' size='xs'>
            {t('Cerrar sesión')}
          </Paragraphs>
        </div>
      </div>
      <div className='avatar__menu tw-w-[8px] tw-h-[8px] tw-absolute tw-overflow-hidden tw-border-t-[1px] tw-border-l-[1px] tw-border-t-[#b6c7d6] tw-border-l-[#b6c7d6] tw-rotate-45 tw-top-[35px] tw-right-[8px] tw-bg-white after:tw-left-[2px] after:tw-content-[""]'></div>
    </AvatarContainer>
  )
}

Avatar.propTypes = {
  name: PropTypes.string
}

export default Avatar
