import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import Paragraphs from '../../../../components/Paragraphs'
import Button from '../../../../components/Button'
import useUsersManagement from '../../../../infrastructure/hooks/useUsersManagement'
import Select from 'react-select'
import useFlashMessage from '../../../../infrastructure/hooks/useFlashMessage'

const ChangeAppClientTypeModal = ({
  onRequestClose,
  currentUserId,
  userData,
  setUserData,
  updateUser,
  isSuperAdmin
}) => {
  const [clients, setClients] = useState([])
  const [selectedClient, setSelectedClient] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { getClients } = useUsersManagement()
  const { showMessage } = useFlashMessage()

  useEffect(async () => {
    setLoading(true)
    const dataFecth = async () => {
      const { data, success } = await getClients()
      if (success && data) {
        const current = userData.userAdminData.find(
          (item) => item.label === 'Tipo de cliente BO'
        ).value
        setSelectedClient(data.find((c) => c.name === current))
        setClients(data)
      }
      setLoading(false)
    }

    dataFecth()
  }, [])

  /**
   * On submit form handler
   * This function will assing roles to user and remove roles from user
   * @async
   * @param {event} e
   * @returns void
   */
  const onSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)
    const newUserData = {
      id: currentUserId,
      admin: isSuperAdmin,
      client_type_bo_id: selectedClient.id
    }
    const { success, data, error } = await updateUser(newUserData)
    if (!success) {
      showMessage({
        type: 'alert',
        message: t('Error al actualizar tipo de cliente')
      })
      setLoading(false)
      return
    }
    setTimeout(() => {
      let userAdminData = userData.userAdminData
      userAdminData = userAdminData.map((obj) => {
        if (obj.label === 'Tipo de cliente BO') {
          return {
            ...obj,
            value: selectedClient.name
          }
        }
        return obj
      })

      setUserData({
        ...userData,
        userAdminData
      })

      showMessage({
        type: 'success',
        message: t('App actualizado correctamente')
      })

      onRequestClose()
      setLoading(false)
    }, 500)
  }

  return (
    <Modal
      onRequestClose={onRequestClose}
      title={t('Tipo de cliente BO')}
      onAccept={onSubmit}
      hideAcceptButton
      size={'md'}
    >
      <div className={'tw-p-3 tw-h-56'}>
        <div className={'tw-flex tw-gap-2'}>
          <Paragraphs size={'lg'}>
            {t('Vas a cambiar el Tipo de cliente BO del usuario')}:
          </Paragraphs>
        </div>

        <form
          id={'id'}
          onSubmit={(e) => onSubmit(e)}
          className='tw-w-full tw-flex tw-items-center tw-gap-4 tw-justify-start tw-pt-5'
        >
          <div className={'tw-flex tw-w-1/2 tw-h-full tw-pt-5'}>
            <Select
              options={clients}
              isLoading={loading}
              value={selectedClient}
              className='basic-multi-select tw-w-full tw-h-full'
              classNamePrefix='client'
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder={t('Selecciona un app')}
              noOptionsMessage={() => t('No hay apps')}
              onChange={(e) => {
                setSelectedClient(e)
              }}
            />
          </div>
          <div className={'tw-h-full tw-pt-5 tw-self-start'}>
            <Button
              size='full'
              label={t('Guardar')}
              uppercase
              type='submit'
              disabled={loading}
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ChangeAppClientTypeModal

ChangeAppClientTypeModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  userData: PropTypes.object.isRequired,
  setUserData: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired
}
