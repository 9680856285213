import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
/**
 * @ehernandez
 * @description Sidebar component.
 * @param {*} props
 * @returns
 */
const SidebarItem = ({ path, name, parents, onClickItem }) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const location = useLocation()

  return (
    <div className='sidebar-item tw-cursor-pointer'>
      <div
        className='tw-flex tw-items-center tw-p-4 tw-pl-4 tw-text-white hover:tw-bg-[#202226]'
        style={{
          backgroundColor:
            path === location.pathname ||
            (path === '/users' && location.pathname === '/')
              ? '#202226'
              : ''
        }}
        onClick={() => (parents ? setOpen(!open) : onClickItem({ name, path }))}
      >
        <div className='sidebar-item__icon'></div>
        <div className='sidebar-item__name'>
          <Paragraphs size='base'>{t(name)}</Paragraphs>
        </div>
      </div>
      {parents &&
        parents?.map((item) => {
          return <SidebarItem key={item.path} {...item} />
        })}
    </div>
  )
}

SidebarItem.propTypes = {
  icon: PropTypes.string,
  parents: PropTypes.array,
  name: PropTypes.string,
  path: PropTypes.string,
  onClickItem: PropTypes.func
}

export default SidebarItem
