import useMutation from '../useMutation'
import { useState } from 'react'
import {
  columnDefs,
  defaultColDef,
  userDetailFields,
  columnDefsLogs
} from './constants/constants'
import {
  URL_COMPLETE_PROFILE as URL_COMPLETE_PROFILE_PRO,
  URL_COMPLETE_PROFILE_DEV as URL_COMPLETE_PROFILE_DEV_1,
  URL_COMPLETE_PROFILE_FEDEFARMACLIENTS as URL_COMPLETE_PROFILE_FEDEFARMACLIENTS_PRO,
  URL_COMPLETE_PROFILE_FEDEFARMACLIENTS_DEV as URL_COMPLETE_PROFILE_FEDEFARMACLIENTS_DEV_1,
  URL_SUPPLANT_FEDEFARMACLIENTS as URL_SUPPLANT_FEDEFARMACLIENTS_PRO,
  URL_SUPPLANT_VADEFARMA as URL_SUPPLANT_VADEFARMA_PRO,
  URL_SUPPLANT_VADEFARMA_DEV as URL_SUPPLANT_VADEFARMA_DEV_1
} from '../../constants/configurations'

export const useUsersManagement = () => {
  const [getUsers] = useMutation('users')
  const [getUserRoles] = useMutation('user')
  const [deleteUser] = useMutation('users')
  const [restoreUser] = useMutation('users')
  const [userLogs] = useMutation('user-logs')
  const [updateSingleUser] = useMutation('users')
  const [assignUserRole] = useMutation('user')
  const [removeUserRole] = useMutation('user')
  const [getRolesList] = useMutation('roles/all')
  const [supplantUser] = useMutation('supplant')
  const [getCooperativesList] = useMutation('cooperatives')
  const [getClientList] = useMutation('client-types')
  const [unnefarCooperatives] = useMutation('unnefar-cooperatives')
  const [updatePharmacyApi] = useMutation('pharmacy')
  const [getGroupsList] = useMutation('users/groups')
  const [loading, setLoading] = useState(false)

  // env variables
  const URL_SUPPLANT_VADEFARMA =
    process.env.REACT_APP_URL_SUPPLANT_VADEFARMA || URL_SUPPLANT_VADEFARMA_PRO

  const URL_SUPPLANT_FEDEFARMACLIENTS =
    process.env.REACT_APP_URL_SUPPLANT_FEDEFARMACLIENTS ||
    URL_SUPPLANT_FEDEFARMACLIENTS_PRO

  const URL_COMPLETE_PROFILE =
    process.env.REACT_APP_URL_COMPLETE_PROFILE || URL_COMPLETE_PROFILE_PRO

  const URL_COMPLETE_PROFILE_FEDEFARMACLIENTS =
    process.env.REACT_APP_URL_COMPLETE_PROFILE_FEDEFARMACLIENTS ||
    URL_COMPLETE_PROFILE_FEDEFARMACLIENTS_PRO

  const URL_COMPLETE_PROFILE_FEDEFARMACLIENTS_DEV =
    process.env.REACT_APP_URL_COMPLETE_PROFILE_FEDEFARMACLIENTS_DEV ||
    URL_COMPLETE_PROFILE_FEDEFARMACLIENTS_DEV_1

  const URL_COMPLETE_PROFILE_DEV =
    process.env.REACT_APP_URL_COMPLETE_PROFILE_DEV || URL_COMPLETE_PROFILE_DEV_1

  const URL_SUPPLANT_VADEFARMA_DEV =
    process.env.REACT_APP_URL_SUPPLANT_VADEFARMA_DEV ||
    URL_SUPPLANT_VADEFARMA_DEV_1

  const URL_SUPPLANT_FEDEFARMACLIENTS_DEV =
    process.env.REACT_APP_URL_SUPPLANT_FEDEFARMACLIENTS_DEV ||
    URL_COMPLETE_PROFILE_FEDEFARMACLIENTS_DEV_1

  /**
   * It takes a string and an object, and returns the value of the string as a path in the object
   * @param path - The path to the property you want to get.
   * @param obj - The object to traverse
   * @returns The value of the property at the end of the path.
   */
  const resolve = (path, obj) => {
    if (!path) return null
    return path.split('.').reduce(function (prev, curr) {
      return prev ? prev[curr] : null
    }, obj || self)
  }

  /**
   * It fetches all users from the database, maps the response to the columnDefs array, and returns the data
   * @param params - The params to be sent to the API sent as { page: 1, per_page: 10 }
   * @param filter - The filter to be sent to the API sent as { name: 'John Doe', email: 'john.doe@example', pharmacy: { id: 1 }
   * @param orderBy - The orderBy to be sent to the API sent as { order_direction: 'asc' | 'desc', order_field: 'name' | 'email' | 'id'}
   * @returns An object with the data and the success of the request
   * @example
   * const { data, success } = await getAllUsers(params, filter, orderBy)
   * if (!success) return
   * console.log(data)
   * // [
   * //   {
   * //     id: 1,
   * //     name: 'John Doe',
   * //     email: 'john.doe@example',
   * //     pharmacy: {
   * //       id: 1,
   * //       name: 'Farmacia de prueba'
   * //       unnefars: [{
   * //         id: 1,
   * //         nombreCooperativa: 'Unnefar de prueba'
   * //         }
   *          ]
   *        }
   * //   },
   * //   {
   * //     id: 2,
   * //     name: 'Jane Doe',
   * //     email: 'jane.doe@example',
   * //     pharmacy: {
   * //       id: 1,
   * //       name: 'Farmacia de prueba'
   * //       ]}
   * //   }
   * // ]
   */
  const getAllUsers = async (params, filter, orderBy, groupBy) => {
    setLoading(true)
    const { data, success } = await getUsers({
      method: 'POST',
      params,
      data: {
        ...(filter && filter),
        ...orderBy,
        ...(groupBy && {
          group_by: groupBy.replaceAll('__', '.')
        })
      }
    })
    if (!success) {
      setLoading(false)
      return { success: false, data: [] }
    }
    let users
    if (groupBy) {
      groupBy = groupBy.replaceAll('.', '__')
      users = data.items.map((user) => {
        const userData = {}
        const { total, ...single } = user
        const value = Object.values(single)[0]

        if (value !== null) {
          userData[groupBy] = value
          userData.total = total
        } else {
          userData[groupBy] = 'Sin grupo'
          userData.total = total
        }

        return userData
      })
    } else {
      users = data.items.map((user) => {
        const userData = {}
        const userState = 'user_state'
        columnDefs.forEach((column) => {
          if (column.field === 'pharmacy__unnefars__nombreCooperativa') {
            const name =
              user.pharmacy?.unnefars?.length > 0
                ? user.pharmacy?.unnefars[0]?.nombreCooperativa
                : user.pharmacy?.cooperative?.name === 'Fedefarma'
                ? 'Fedefarma'
                : ''
            userData[column.field] = name
          } else {
            userData[column.field] = resolve(column.databaseField, user)
          }
        })
        userData[userState] = user.deleted_at === null ? 'Activo' : 'Inactivo'
        return userData
      })
    }
    setLoading(false)
    return { success, data: users, pagination: data.pagination }
  }

  /**
   * Gets a single user from the database and returns it
   * @param {string} id
   * @returns An object with the data and the success of the request
   */
  const getSingleUser = async (id) => {
    setLoading(true)

    const { data, success, status } = await getUsers({
      method: 'get',
      addToURL: `/${id}`
    })

    if (!success) {
      setLoading(false)
      return { success: false, data, status }
    }

    const { data: roles, success: ok } = await getUserRoles({
      method: 'get',
      addToURL: `/${id}/roles`
    })
    if (!ok) {
      setLoading(false)
      return { success: false, data: roles.data }
    }

    const userData = {
      id: data.id,
      pharmacyId: data.pharmacy?.id,
      pharmacyProvinceId: data.pharmacy?.province_id?.id,
      userPersonalData: [],
      userContact: [],
      userGeneralData: [],
      userPharmacyData: [],
      userPharmacySepaData: [],
      userUnnefarData: [],
      userAdminData: [],
      isAdmin: data.data?.item?.admin === 1 || false
    }

    const pharmacyCreated = resolve('pharmacy.username', data)
    userDetailFields.userPersonalData.forEach((column) => {
      userData.userPersonalData.push({
        label: column.label,
        value: resolve(column.databaseField, data),
        databaseField: column.databaseField,
        roles: column.roles,
        edit: column.databaseField === 'nif' ? pharmacyCreated === null : column.edit | false,
        optionsEnabled: column.optionsEnabled | false,
        admin: column.admin,
        hide: column.hide
      })
    })

    const email = userData.userPersonalData.find(
      (column) => column.databaseField === 'email'
    )
    const emailVertify = userData.userPersonalData.find(
      (column) => column.databaseField === 'email_verified_at'
    )

    if (!emailVertify.value) {
      email.verify = false
      email.tooltip = 'El usuario no ha verificado su correo'
    } else if (emailVertify) {
      email.verify = true
    }

    // replace the email field with the new one
    const index = userData.userPersonalData.findIndex(
      (column) => column.databaseField === 'email'
    )
    userData.userPersonalData.splice(index, 1, email)

    userDetailFields.userContact.forEach((column) => {
      userData.userContact.push({
        label: column.label,
        value: resolve(column.databaseField, data),
        databaseField: column.databaseField,
        edit: column.edit | false,
        optionsEnabled: column.optionsEnabled | false,
        roles: column.roles
      })
    })

    userDetailFields.userPharmacyData.forEach((column) => {
      const val = resolve(column.databaseField, data)
      userData.userPharmacyData.push({
        label: column.label,
        value: val,
        databaseField: column.databaseField,
        edit: column.edit | false,
        optionsEnabled: column.optionsEnabled | false,
        roles: column.roles,
        type: column.type || 'text'
      })
    })

    userDetailFields.userPharmacySepaData.forEach((column) => {
      userData.userPharmacySepaData.push({
        label: column.label,
        value: resolve(column.databaseField, data)
      })
    })

    const sortedUnnefar =
      data.pharmacy?.unnefar?.sort(
        (a, b) =>
          (resolve('esPrincipal', a) === 1 ? -1 : 1) -
          (resolve('esPrincipal', b) === 1 ? -1 : 1)
      ) || []

    sortedUnnefar.forEach((unnefarData) => {
      const unnefarList = []
      const isMain = resolve('esPrincipal', unnefarData)
        ? 'Farmacia'
        : 'Botiquín'
      userDetailFields.userUnnefarData.forEach((column) => {
        let val = resolve(column.databaseField, unnefarData)
        if (column.databaseField === 'esPrincipal') {
          val = isMain
        } else if (column.databaseField === 'codigoCooperativa') {
          val = `${val} - ${resolve('nombreCooperativa', unnefarData)}`
        } else if (column.databaseField === 'codigoAlmacen') {
          val = `${val} - ${resolve('nombreAlmacen', unnefarData)}`
        }
        unnefarList.push({
          label: column.label,
          value: val,
          isMain
        })
      })
      userData.userUnnefarData.push(unnefarList)
    })

    // TODO: DO IT IN SEPT
    if (data?.pharmacy?.cooperative?.id === 3) {
      userData.userPharmacyData.push({
        label: 'Bloqueada',
        databaseField: 'pharmacy.blocked',
        field: 'blocked',
        roles: ['Support', 'Admin', 'SuperAdmin'],
        edit: true,
        type: 'checkbox',
        value: resolve('pharmacy.blocked', data)
      })
    }

    userData.userAdminData = [
      {
        label: 'Roles',
        value: roles?.data.items.join(', '),
        edit: true,
        roles: ['SuperAdmin'],
        admin: true
      },
      {
        label: 'Cargar datos',
        value: !!data.admin,
        edit: true,
        roles: ['SuperAdmin'],
        admin: true
      },
      {
        label: 'Activo',
        value: typeof data.deleted_at !== 'string',
        edit: true,
        roles: ['Admin', 'SuperAdmin', 'Support', 'Marketing'],
        admin: true
      },
      {
        label: 'Cooperativa BO',
        value: data.bo_cooperative?.name,
        edit: true,
        roles: ['SuperAdmin'],
        admin: true
      },
      {
        label: 'Tipo de cliente BO',
        value: data.client_type_bo?.name,
        edit: true,
        roles: ['SuperAdmin'],
        admin: true
      }
    ]

    setLoading(false)
    return { success, data: userData }
  }

  const getSupplantLink = async (id) => {
    setLoading(true)
    console.log({ URL_SUPPLANT_VADEFARMA })
    const { data, success, error } = await supplantUser({
      method: 'get',
      addToURL: `/${id}`
    })

    let link = null
    if (success && data?.data?.access_token) {
      switch (data?.data?.user?.client_type?.id) {
        case 1:
          if (window.location.hostname.includes('enpreproduccion')) {
            link = URL_SUPPLANT_VADEFARMA_DEV + data.data?.access_token
          } else {
            link = URL_SUPPLANT_VADEFARMA + data?.data?.access_token
          }
          break
        case 2:
          if (window.location.hostname.includes('enpreproduccion')) {
            link = URL_SUPPLANT_FEDEFARMACLIENTS_DEV + data.data?.access_token
          } else {
            link = URL_SUPPLANT_FEDEFARMACLIENTS + data?.data?.access_token
          }
          break
        default:
          if (window.location.hostname.includes('enpreproduccion')) {
            link = URL_SUPPLANT_VADEFARMA_DEV + data.data?.access_token
          } else {
            link = URL_SUPPLANT_VADEFARMA + data?.data?.access_token
          }
          break
      }
    }
    setLoading(false)
    return { success, link, accessToken: data?.data?.access_token, error }
  }

  /**
   * This function returns a link to complete the user's profile in the frontend
   * @param {number} id
   * @returns {object} {success, data, error, link}
   */
  const getCompleteProfile = async (id) => {
    setLoading(true)
    const { data, success, error } = await supplantUser({
      method: 'get',
      addToURL: `/${id}`
    })
    if (!success) {
      setLoading(false)
      return { success, data, error }
    }
    let link = URL_COMPLETE_PROFILE

    if (window.location.hostname.includes('localhost')) {
      link = URL_COMPLETE_PROFILE_DEV
    } else if (
      data.data.user.client_type.id === 2 &&
      window.location.hostname.includes('enpreproduccion')
    ) {
      link = URL_COMPLETE_PROFILE_FEDEFARMACLIENTS_DEV
    } else if (
      data.data.user.client_type.id === 2 &&
      !window.location.hostname.includes('enpreproduccion')
    ) {
      link = URL_COMPLETE_PROFILE_FEDEFARMACLIENTS
    } else if (
      data.data.user.client_type.id === 1 &&
      window.location.hostname.includes('enpreproduccion')
    ) {
      link = URL_COMPLETE_PROFILE_DEV
    }

    switch (data?.data?.user?.client_type?.id) {
      case 2:
        link = link.replace('vadefarma', 'fedefarmaclients')
        break
    }

    link += data?.data?.access_token
    setLoading(false)
    return { success, data, error, link }
  }

  /**
   * It updates a user's data in the database
   * @param newUserData - the data you want to update the user with.
   */
  const updateUser = async (newUserData) => {
    // newUserData must include user id and boolean "admin"
    //   'admin' => 'required|boolean',
    //   'name' => optional
    //   'surnames' => optional,
    //   'password' => optional,
    //   'password_confirmation' => optional,

    setLoading(true)
    const { success, data, error } = await updateSingleUser({
      method: 'PUT',
      data: newUserData,
      addToURL: `/${newUserData?.id}`
    })
    setLoading(false)
    getSingleUser(newUserData.id)
    return { success, data, error }
  }

  /**
   * This function assigns a role to a user
   * @async
   * @param {number} roleId
   * @param {number} userId
   * @returns {Promise<{success: boolean, data: any, error: any}>}
   * @example
   * const { success, data, error } = await assignRole(1, 1)
   * if (success) {
   * // do something
   * }
   * else {
   * // do something else
   * }
   */
  const assingRole = async (rolesIds, userId) => {
    const { success, data, error } = await assignUserRole({
      method: 'POST',
      addToURL: `/${userId}/sync-roles`,
      data: {
        roles: rolesIds
      }
    })
    return { success, data, error }
  }

  /**
   * This function gets the list of roles from the database
   * @async
   * @returns {Promise<{success: boolean, data: any, error: any}>}
   */
  const getRoles = async () => {
    setLoading(true)
    const { success, data, error } = await getRolesList({
      method: 'GET'
    })
    setLoading(false)
    return { success, data: data.data?.items || [], error }
  }

  /**
   * This function get the user roles list
   * @param {number} userId
   * @returns {Promise<{success: boolean, data: string[], error: any}>}
   */
  const getUserRolesList = async (userId) => {
    const { data: roles, success: ok } = await getUserRoles({
      method: 'get',
      addToURL: `/${userId}/roles`
    })

    return { success: ok, data: roles.data }
  }

  const userDelete = async (id) => {
    setLoading(true)
    const { success, data, error } = await deleteUser({
      method: 'DELETE',
      addToURL: `/${id}`
    })
    if (success && data) {
      const { data, success } = await getSingleUser(id)
      setLoading(false)
      return { success, data }
    }
    setLoading(false)
    return { success, data, error }
  }

  const userRestore = async (id) => {
    setLoading(true)
    const { success, data, error } = await restoreUser({
      method: 'PUT',
      addToURL: `/${id}/restore`
    })
    if (success && data) {
      const { data, success } = await getSingleUser(id)
      setLoading(false)
      return { success, data }
    }
    setLoading(false)
    return { success, data, error }
  }

  const getCooperatives = async () => {
    setLoading(true)
    const { success, data, error } = await getCooperativesList({
      method: 'GET'
    })
    setLoading(false)
    return { success, data: data?.data?.items || [], error }
  }

  const getClients = async () => {
    setLoading(true)
    const { success, data, error } = await getClientList({
      method: 'GET'
    })
    setLoading(false)
    return { success, data: data?.data?.items || [], error }
  }

  const getUnnefarCooperatives = async () => {
    setLoading(true)
    const { success, data, error } = await unnefarCooperatives({
      method: 'GET'
    })
    setLoading(false)
    return { success, data: data || [], error }
  }

  /**
   * This function updates pharmacy data shuch as emails.
   * @param {object} data
   * @returns  {Promise<{success: boolean, data: any, error: any}>}
   */
  const updatePharmacyData = async (data) => {
    setLoading(true)
    const {
      success,
      data: dataResponse,
      error
    } = await updatePharmacyApi({
      method: 'PUT',
      data: data,
      addToURL: `/${data?.id}`
    })
    setLoading(false)
    return { success, data: dataResponse, error }
  }

  const getGroups = async () => {
    setLoading(true)
    const { success, data, error } = await getGroupsList({
      method: 'GET'
    })
    setLoading(false)
    return { success, data: data || [], error }
  }

  /**
   * This function gets the user logs paginated
   * @param {number} id
   * @param {object} params - pagination params
   * @param {object} filters - filters to apply
   * @param {object} orderBy - order by
   * @returns {Promise<{success: boolean, data: any, error: any}>}
   * @example
   * const { success, data, error } = await getUserLogs(1, { page: 1, per_page: 10 }, filter:{ user_id:1,  action: 'login' }, order_by:{ createdAt: 'DESC' })
   */
  const getUserLogs = async (id, params, filters, orderBy) => {
    const { data, success } = await userLogs({
      method: 'POST',
      params,
      data: {
        filters: {
          user_id: id,
          ...filters
        },
        ...(orderBy && { order_by: { ...orderBy } })
      }
    })

    if (!success) {
      setLoading(false)
      return { success: false, data: [] }
    }
    setLoading(false)
    return {
      success,
      data: data.items,
      pagination: data.pagination
    }
  }

  const getUserFilterOptions = async () => {
    const { data, success } = await userLogs({
      method: 'GET',
      addToURL: '/filter-options'
    })

    if (!success) {
      setLoading(false)
      return { success: false, data: null }
    }
    setLoading(false)
    return {
      success,
      data: data.data
    }
  }

  const donwloadExcelUsers = async (excelParams) => {
    const { data } = await getUsers({
      method: 'POST',
      addToURL: '/export',
      data: {
        ...excelParams
      },
      responseType: 'blob'
    })

    if (!data) {
      setLoading(false)
      return { success: false, data: [] }
    }

    setLoading(false)
    return {
      success: true,
      data
    }
  }

  return {
    getAllUsers,
    getSingleUser,
    updateUser,
    assingRole,
    getSupplantLink,
    getCompleteProfile,
    getRoles,
    getUserRolesList,
    userDelete,
    userRestore,
    getCooperatives,
    getClients,
    getUnnefarCooperatives,
    updatePharmacyData,
    getGroups,
    getUserLogs,
    getUserFilterOptions,
    loading,
    columnDefs,
    defaultColDef,
    columnDefsLogs,
    donwloadExcelUsers
  }
}
