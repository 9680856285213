const usePagesRoles = () => {
  const hasPermissionsRoute = (roles) => {
    const { pathname } = window.location
    if (!hasRequiredRoles(pathname, roles)) {
      return getDefaultPage(pathname, roles)
    }
    return ''
  }

  const hasRequiredRoles = (pathname, roles) => {
    const requiredRoles = getRolesOfPage(pathname)
    return roles.some((role) => requiredRoles.includes(role.toLowerCase()))
  }

  const getRolesOfPage = (pathname) => {
    const adminRoles = ['admin', 'superadmin']
    const rolesMap = {
      '/users': [...adminRoles, 'support'],
      '/translations': [...adminRoles, 'marketing'],
      '/reports': [...adminRoles],
      '/banners': [...adminRoles, 'marketing'],
      '/reports/unnefar': [...adminRoles, 'UnnefarReport']
    }
    return rolesMap[pathname] || adminRoles
  }

  const getDefaultPage = (pathname, roles) => {
    const pathnames = [
      '/users',
      '/translations',
      '/reports',
      '/banners',
      '/reports/unnefar'
    ]

    // find the first page that the user has permission with that path and role
    const defaultPage = pathnames.find((path) => hasRequiredRoles(path, roles))

    if (defaultPage) {
      return defaultPage
    } else {
      // if the user has not permission to the current path, we redirect to the first page that the user has permission
      const path = pathnames.find((path) =>
        getRolesOfPage(path).some((role) => roles.includes(role))
      )
      return path || '/login'
    }
  }

  return { hasPermissionsRoute, hasRequiredRoles }
}

export default usePagesRoles
