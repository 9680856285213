import React from 'react'
import PropTypes from 'prop-types'
import Label from '../Label'
import { BoxFix, Error, Input, InputContent, Textarea } from './styles'
import Paragraphs from '../Paragraphs'
import useInput from './hooks/useInput'

/**
 *  @author ehernandez
 * @param {Object} customClassName classesnames for add
 * @returns {String} classes
 */
const TextInput = ({
  rows,
  error,
  name,
  label,
  placeholder,
  type,
  autoFocus,
  disabled,
  required,
  hideError,
  textAlign,
  color,
  readOnly,
  description,
  uppercase,
  prefix,
  sufix,
  customClassName,
  labelAlign,
  reference,
  mode,
  onChange,
  register,
  cursor,
  flex,
  id,
  value,
  translate,
  weight,
  width,
  truncate,
  iconRight,
  iconRightPlain,
  size,
  customClassInput,
  backgroundColorInput
}) => {
  const { classSelection, setGenericClass, modeFlex } = useInput(
    disabled,
    readOnly,
    flex
  )

  const isDisabled = mode === 'clickable' ? true : disabled
  return (
    <div className='text-input'>
      <div className={`${modeFlex()} tw-pb-3`}>
        <div
          className={` tw-m-0 tw-text-${labelAlign} ${
            flex && flex !== 'column' && 'tw-mr-2 tw-mt-auto tw-mb-auto'
          } ${flex && flex === 'column' && 'tw-pb-2'}`}
        >
          {label && (
            <Label label={label} required={required} truncate={truncate} />
          )}
        </div>
        <div className={`tw-w-${width || 'full'} tw-relative`}>
          <InputContent
            error={error}
            className={`${classSelection(customClassName)} tw-w-full tw-flex`}
            rows={rows}
          >
            {prefix && (
              <BoxFix className='tw-flex tw-items-center tw-justify-center tw-px-2'>
                <Label label={prefix} translate={translate} />
              </BoxFix>
            )}
            {rows === null
              ? (
              <Input
                id={id}
                color={color}
                autoSave='off'
                autoFocus={autoFocus}
                name={name}
                type={type === 'search' ? 'text' : type}
                autoComplete={'off'}
                ref={reference}
                placeholder={placeholder}
                className={`  ${customClassInput} tw-outline-none tw-h-full tw-flex-1 tw-px-2 tw-bg-transparent tw-w-${
                  width || 'full'
                } ${setGenericClass({
                  uppercase,
                  textAlign,
                  mode,
                  weight,
                  cursor
                })}`}
                disabled={isDisabled}
                readOnly={readOnly}
                onChange={onChange}
                {...register}
                value={value}
                size={size}
                style={{ background: backgroundColorInput }}
              />
                )
              : (
              <Textarea
                id={id}
                color={color}
                autoSave='off'
                autoFocus={autoFocus}
                name={name}
                rows={rows}
                autoComplete={'off'}
                ref={reference}
                placeholder={placeholder}
                className={`tw-outline-none tw-h-full tw-w-${
                  width || 'full'
                } tw-flex-1 tw-px-2 tw-bg-transparent ${
                  disabled ? 'tw-text-gray-700' : ''
                } ${setGenericClass({
                  uppercase,
                  textAlign,
                  mode
                })}`}
                disabled={disabled}
                readOnly={readOnly}
                onChange={onChange}
                {...register}
                value={value}
                style={{ background: backgroundColorInput }}
              />
                )}
            {sufix && (
              <BoxFix className='tw-flex tw-items-center tw-justify-center tw-px-2'>
                <Label label={sufix} translate={translate} />
              </BoxFix>
            )}
            {iconRight &&
              (iconRightPlain
                ? (
                    iconRight
                  )
                : (
                <div className='tw-h-full tw-flex tw-items-center'>
                  {iconRight}
                </div>
                  ))}
            {type === 'search' && (
              <div className='tw-h-full tw-flex tw-items-center tw-px-2'>
                <span
                  className='icon-ic_common_search'
                  style={{ fontSize: 20 }}
                />
              </div>
            )}
          </InputContent>
          {!description && error
            ? (
            <Error
              className={`tw-text-red-600 tw-font-Atkinson-bold tw-absolute ${
                hideError && 'tw-hidden'
              }`}
            >
              {error && error}
            </Error>
              )
            : null}
          {description && (
            <Paragraphs italic size='xxs' className='tw-text-gray-700'>
              {description}
            </Paragraphs>
          )}
        </div>
      </div>
    </div>
  )
}

TextInput.propTypes = {
  error: PropTypes.string,
  inputStyles: PropTypes.object,
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'password',
    'hidden',
    'date',
    'time',
    'phone',
    'number',
    'searchLeft',
    'searchRight'
  ]),
  weight: PropTypes.oneOf([
    'thin',
    'extralight',
    'light',
    'normal',
    'medium',
    'semibold',
    'bold',
    'extrabold',
    'black'
  ]),
  size: PropTypes.oneOf(['small', 'large']),
  label: PropTypes.string,
  transparent: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  register: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  disabled: PropTypes.bool,
  value: PropTypes.string,
  hideError: PropTypes.bool,
  textAlign: PropTypes.oneOf(['center', 'left', 'right']),
  labelAlign: PropTypes.oneOf(['center', 'left', 'right']),
  mode: PropTypes.oneOf(['success', 'warning', 'alert', 'normal', 'clickable']),
  row: PropTypes.bool,
  containerStyles: PropTypes.string,
  labelStyles: PropTypes.string,
  classNameInput: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  noMarginBottom: PropTypes.bool,
  uppercase: PropTypes.bool,
  prefix: PropTypes.string,
  sufix: PropTypes.string,
  flex: PropTypes.oneOf(['row', 'column', 'between']),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  customClassName: PropTypes.string,
  reference: PropTypes.func,
  rows: PropTypes.number,
  translate: PropTypes.bool,
  truncate: PropTypes.bool,
  searchIconsize: PropTypes.number,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  iconRightPlain: PropTypes.bool,
  width: PropTypes.string,
  children: PropTypes.node,
  cursor: PropTypes.oneOf(['pointer', 'default']),
  customClassInput: PropTypes.string,
  backgroundColorInput: PropTypes.string
}

TextInput.defaultProps = {
  autoFocus: false,
  uppercase: false,
  label: '',
  placeholder: '',
  type: 'text',
  description: null,
  register: {},
  required: false,
  disabled: false,
  textAlign: 'left',
  labelAlign: 'left',
  hideError: false,
  error: '',
  mode: 'normal',
  onChange: null,
  readOnly: false,
  flex: 'column',
  id: 'textinput',
  name: 'text-input',
  weight: 'normal',
  rows: null,
  translate: true,
  truncate: false,
  searchIconsize: 30,
  colorIcSearch: '#9a9a9a',
  backgroundColorInput: 'white'
}

export default TextInput
