import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import FlashMessage from './components/FlashMessage'
import AuthContext from './infrastructure/context/AuthContext/AuthContext'
import { ThemeProvider } from './infrastructure/context/ThemeContext/ThemeContext'
import { persistor, store } from './infrastructure/redux/store'
import LoginScreen from './modules/Auth/pages/LoginScreen/LoginScreen'
import BannerScreen from './modules/Banners/pages/BannersScreen'
import { ReportPowerBi } from './modules/PowerBi/components/ReportPowerBi'
import ReportsScreen from './modules/Reports/pages/ReportsScreen/ReportsScreen'
import TranslationEdit from './modules/Translations/pages/TranslationEdit'
import TranslationsScreen from './modules/Translations/pages/TranslationsScreen'
import UserScreen from './modules/Users/pages/UserScreen'

const App = () => {
  const [user, setUser] = useState(null)
  useEffect(() => {
    const { user } = store.getState().userReducer
    setUser(user)
  }, [store])

  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider>
            <FlashMessage />

            <Routes>
              <Route path='/login' element={<LoginScreen />} />
              <Route path='/' element={<AuthContext />}>
                <Route index element={<UserScreen />} />
                <Route path='users' element={<UserScreen />} />
                <Route path='/translations'>
                  <Route index element={<TranslationsScreen />} />
                  <Route path=':id/edit' element={<TranslationEdit />} />
                </Route>
                <Route path='/banners' element={<BannerScreen />} />
                <Route path='/reports' element={<ReportsScreen />} />

                <Route path='/reports/:name' element={<ReportPowerBi />} />
              </Route>
              {/* any other go to / */}
              <Route path='*' element={<Navigate to='/' />} />
            </Routes>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  )
}

export default App
